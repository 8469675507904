import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  position: relative;

  .pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0px 56px 0px;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .pagination-custom {
      // height: 100px;
      > ul {
        > li {
          > button {
            color: var(--color-label-4);
            font-size: 14px;
            font-weight: 500;
            border: 1px solid var(--border-color-pagination);
            border-radius: 5px;
            font-family: inherit;
          }
        }
      }
    }
  }
  .tab-wrapper {
    display: flex;
    align-items: center;
    margin: 10px 0;
    gap: 40px;
    padding-left: 16px;
    @media only screen and (max-width: 768px) {
      padding-left: 25px;
    }
    .tab {
      padding: 4px;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.colorTab};
      font-size: 12px;
      white-space: nowrap;
    }
    .active {
      border-radius: 16px;
      color: ${({ theme }) => theme.colors.colorTabActiveDate};
      background-color: ${({ theme }) => theme.colors.actionCloseAll};
    }
  }
  .text-time {
    color: ${({ theme }) => theme.colors.colorTab};
  }
  .input-custom-style {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #8e8e92;
    border-radius: 1000px;
    font-size: 12px !important;

    @media only screen and (max-width: 400px) {
      padding: 5px 9px 5px 16px;
    }
    input {
      font-size: 12px !important;
      color: black;
      font-weight: bold !important;
      width: 71px;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .reset-btn {
    width: 109px;
    border-radius: 8px;
    margin: 0 8px;
    border: none;
    background: #17181a;
    cursor: pointer;
    font-size: 14px;
    font-family: inherit;
    color: white;
    padding: 10px 16px;
    font-weight: 600;
    border-radius: 63px;
    line-height: 20px;
    border: 1px solid ${({ theme }) => theme.colors.neutral};
  }
`;

export const TableStyle = styled.div`
  overflow: auto;
  padding-left: 16px;
  .dex_table {
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    border-spacing: 32px;
    .type-cancel-order {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .column-symbol {
      display: flex;
      align-items: center;
      cursor: pointer;
      .text-leverage {
        background: var(--text-leverage);
        border-radius: 4px;
        width: 39px;
        height: 24px;
        color: var(--color-value-1);
        padding: 4px 8px;
        margin-left: 4px;
        font-size: 12px;
      }
      .column-color {
        width: 1px;
        height: 37px;
        margin-right: 8px;
      }
      .text-position {
        font-size: 12px;
        font-weight: 400;
      }

      .Text-positive-value {
        color: var(--color-primary-1) !important;
      }
      .Text-negative-value {
        color: var(--color-short-price) !important;
      }
      .App-positive-value {
        background: var(--color-primary-1) !important;
      }
      .App-negative-value {
        background: var(--color-short-price) !important;
      }
    }
    .color-col {
      width: 1px;
      height: 14px;
      background-color: var(--color-bg-active-route);
    }
    .text-normal {
      font-size: 12px;
      font-weight: 400;
      color: var(--text-tab-active) !important;
    }
    .text-liq {
      font-size: 12px;
      font-weight: 400;
      color: var(--text-liq) !important;
    }
    .text-coin {
      font-size: 12px;
      font-weight: 400;
      color: var(--color-value-1);
    }
    .text-tp {
      color: var(--color-primary-1) !important;
    }
    .text-sl {
      color: var(--color-short-price) !important;
    }
    .button-reverse {
      border-radius: 63px;
      border: 1px solid #8e8e92;
      background: var(--table-bg);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 14px;
    }
    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--bg-order-form);
      color: #8e8e92;
      font-weight: 400;
      font-style: normal;
    }

    table {
      border-radius: 8px;
      margin-top: 10px;
      text-align: left;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 12px 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 150%;
      text-align: left;
      // border-bottom: 1px solid var(--color-line);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    th {
      border-top: none;
      color: var(--text-over-2);
      font-weight: bold;
    }

    td {
      color: var(--text-over-1);
    }

    td.App-negative-value {
      color: var(--color-error);
    }

    td.App-positive-value {
      color: var(--color-success);
    }

    tr:hover {
      border-radius: 8px;
      background-color: var(--table-bg-hover);
    }

    tr:first-child th:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    tr:first-child th:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    tr:last-child td {
      border-bottom: none;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }

    .td-right {
      text-align: right;
    }

    .td-left {
      text-align: left;
    }

    .td-center {
      text-align: center;
    }

    tr th {
      border-top: none;
    }

    tr td:first-child {
      border-top: none;
    }
  }

  .text-table-xs {
    table,
    th,
    td {
      font-size: 12px !important;
    }
  }

  // button cancel order
  .dex_table__cancel-order {
    width: 30px;
    cursor: pointer;
  }

  //scroll bar
  .dex_table__scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: var(--background-color);
    border-radius: 20px;
    scroll-margin-top: 40px;
  }

  .dex_table__scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar-ob);
    border-radius: 20px;
    height: 30px;
    scroll-margin-top: 40px;
  }

  .dex_table__scrollbar::-webkit-scrollbar-track {
    background: var(--background-color);
    border-radius: 20px;
    scroll-margin-top: 40px;
  }

  // tr hover cursor: pointer
  .cursor-pointer {
    tr:hover td {
      cursor: pointer;
    }
  }

  .dex_table__non-scrollbar {
    overflow: visible !important;

    > tr {
      > td {
        padding-right: 30px;
      }
    }
  }

  .dex_table--td-time {
    min-width: 150px;
  }

  .container-render-time {
    display: flex;
    flex-wrap: wrap;
    max-width: 100px;
  }
  .color-buy {
    color: var(--color-primary-1) !important;
  }
  .color-sell {
    color: var(--color-short-price) !important;
  }
  .btn-view {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const MobileStyle = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  .container-header {
    display: flex;
    justify-content: space-between;

    .hidden-btn {
      display: flex;
      align-items: center;
    }
  }
  .hidden-text {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text1};
  }
  .action-close-all {
    font-size: 12px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.colorTabActive};
  }
  .section1 {
    text-align: left;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .list-badges {
      margin-top: 4px;
      display: flex;
      align-items: center;
      gap: 4px;

      .badge {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 2px 4px;
        border-radius: 4px;
        min-width: 31px;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: white;
          margin: 0 auto;
        }
      }
      .App-positive-value {
        background-color: ${({ theme }) => theme.colors.success100};
        span {
          color: ${({ theme }) => theme.colors.greenChart};
        }
      }
      .App-negative-value {
        background-color: ${({ theme }) => theme.colors.error100};
        span {
          color: ${({ theme }) => theme.colors.errorChart};
        }
      }
      .normal {
        background-color: ${({ theme }) => theme.colors.gray100};
        span {
          color: ${({ theme }) => theme.colors.grayChart};
        }
      }
    }
  }
  .section2 {
    display: flex;
    justify-content: space-between;
    .font-big {
      font-size: 16px !important;
      font-weight: 700 !important;
    }
  }

  .section3 {
    display: flex;
    justify-content: space-between;
  }
  .section4 {
    display: flex;
    justify-content: space-between;
  }
  .section5 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.border1};
    .container-info {
      display: flex;
      justify-content: space-between;
      .info-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.text1};
      }
      .info-content {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
      .info-content-tpsl {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        .color-col {
          width: 1px;
          height: 10px;
          background-color: ${({ theme }) => theme.colors.colorTab};
        }
        .text-tp {
          color: ${({ theme }) => theme.colors.greenChart};
        }
        .text-sl {
          color: ${({ theme }) => theme.colors.errorChart};
        }
      }
    }
  }
  .section6 {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    .button {
      color: white;
      border-radius: 63px;
      background-color: #17181a;
      border: 1px solid ${({ theme }) => theme.colors.border1};
      font-size: 12px;
      font-weight: 500;
      min-width: 104px;
      padding: 8px 14px;
      max-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .container-item-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.colorTab};
      font-weight: 400;
      line-height: 18px;
    }
    .content {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.text1};
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    .text-right {
      text-align: right;
    }
  }

  .container-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .container-item {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
      padding-bottom: 32px;
      margin-bottom: 24px;
    }
  }
`;
