import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';
import { IOrder } from 'src/interfaces/order';

export interface IPosition {
  id: number | string;
  accountId: number;
  asset: string;
  ownerEmail: string;
  managerEmail: string;
  symbol: string;
  leverage: string;
  unrealisedPnl: string;
  currentQty: string;
  riskLimit: string;
  riskValue: string;
  initMargin: string;
  maintainMargin: string;
  extraMargin: string;
  requiredInitMarginPercent: string;
  requiredMaintainMarginPercent: string;
  liquidationPrice: string;
  bankruptPrice: string;
  entryPrice: string;
  entryValue: string;
  openOrderBuyQty: string;
  openOrderSellQty: string;
  openOrderBuyValue: string;
  openOrderSellValue: string;
  multiplier: string;
  liquidationProgress: boolean;
  liquidationOrderId: number;
  isCross: boolean;
  pnlRanking: string;
  closedId: number;
  closedPrice: string;
  createdAt: string;
  updatedAt: string;
  openOrderValue: string;
  openOrderMargin: string;
  netFunding?: string;
  maxLiquidationBalance: string;
  realisedPnl: string;
  latestRealisedPnl: string;
  operationId: string;
  adjustMargin: string;
  takeProfitOrderId: number;
  stopLossOrderId: number;
  orders: IOrder[];
  contractType: string;
}

export const initPosition = {
  id: 0,
  accountId: 0,
  ownerEmail: '',
  managerEmail: '',
  symbol: '',
  leverage: '1',
  unrealisedPnl: '',
  currentQty: '0',
  riskLimit: '0',
  riskValue: '0',
  initMargin: '0',
  maintainMargin: '0',
  extraMargin: '0',
  requiredInitMarginPercent: '0',
  requiredMaintainMarginPercent: '0',
  liquidationPrice: '0',
  bankruptPrice: '0',
  entryPrice: '0',
  entryValue: '0',
  openOrderBuyQty: '0',
  openOrderSellQty: '0',
  openOrderBuyValue: '0',
  openOrderSellValue: '0',
  multiplier: '0',
  liquidationProgress: false,
  liquidationOrderId: 0,
  isCross: false,
  pnlRanking: '',
  closedId: 0,
  closedPrice: '',
  createdAt: '',
  updatedAt: '',
  openOrderValue: '0',
  openOrderMargin: '0',
  maxLiquidationBalance: '0',
  realisedPnl: '0',
  latestRealisedPnl: '0',
  operationId: '0',
};

const initialState: {
  loading: boolean;
  error: boolean;
  positions: IPosition[];
  totalPage: number;
  totalItem: number;
  currentPage: number;
  currentSymbol: string;
  allPosition: IPosition[];
} = {
  loading: false,
  error: false,
  positions: [] as IPosition[],
  totalPage: 0,
  totalItem: 0,
  currentPage: 1,
  currentSymbol: '',
  allPosition: [] as IPosition[],
};

export const getPosition = createAsyncThunk(
  'getPosition',
  async ({
    page,
    size,
    symbol,
    contractType,
  }: {
    page: number;
    size: number;
    symbol?: string;
    contractType: string;
  }) => {
    return await axiosInstance.get(`/positions`, { params: { page, size, symbol, contractType } });
  },
);

export const getAllPosition = createAsyncThunk('getAllPosition', async (contractType: string) => {
  return await axiosInstance.get(`/positions`, { params: { contractType } });
});

export const closePosition = createAsyncThunk(
  'closePosition',
  async (payload: { positionId: number; quantity: number; type: string; limitPrice?: string }) => {
    return await axiosInstance.post('/positions/close', payload);
  },
);

export const closeAllPosition = createAsyncThunk('closeAllPosition', async (payload: { contractType: string }) => {
  return await axiosInstance.post('/positions/close-all-positions', payload);
});

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    updatePosition: (state, action) => {
      state.positions = action.payload.positions;
      state.totalItem = action.payload.newTotalItem;
      state.totalPage = action.payload.newTotalPage;
    },
    updateAllPosition: (state, action) => {
      state.allPosition = action.payload;
    },
    resetInitStatePosition: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.positions = initialState.positions;
    },
  },
  extraReducers: {
    [`${getPosition.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getPosition.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getPosition.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.positions = action.payload.data;
      state.totalPage = action.payload?.metadata?.totalPage;
      state.totalItem = action.payload?.metadata?.totalItem;
      state.currentPage = Number(action?.payload?.metadata?.query?.page);
      state.currentSymbol = action?.meta?.arg?.symbol ?? '';
    },
    [`${getAllPosition.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getAllPosition.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getAllPosition.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.allPosition = action.payload.data;
    },
    [`${closeAllPosition.pending}`]: (state) => {
      state.loading = true;
    },
    [`${closeAllPosition.rejected}`]: (state) => {
      state.loading = false;
    },
    [`${closeAllPosition.fulfilled}`]: (state) => {
      state.loading = false;
    },
    [`${closePosition.pending}`]: (state) => {
      state.loading = true;
    },
    [`${closePosition.rejected}`]: (state) => {
      state.loading = false;
    },
    [`${closePosition.fulfilled}`]: (state) => {
      state.loading = false;
    },
  },
});

const { reducer: positionReducer } = positionSlice;

export default positionReducer;

export const { updatePosition, resetInitStatePosition, updateAllPosition } = positionSlice.actions;
