import React from "react";
import * as S from "./style";
import { Button, Popover } from "@material-ui/core";
import { Typography } from "@mui/material";
import { ArrowDownIcon } from "src/components/svg";
import { DEPTH_CHART, TRADING_VIEW_CHART } from "../../constant";

interface TabsActionProps {
  tab: string;
  setTab: (value: string) => void;
}

const tabs = [
  { value: TRADING_VIEW_CHART, name: "Trading View" },
  { value: DEPTH_CHART, name: "Depth" },
] as const;
type TabItem = typeof tabs[number];

const TabsAction: React.FC<TabsActionProps> = ({ tab, setTab }) => {
  return (
    <S.Wrap>
      {tabs.map((item: TabItem) => {
        return (
          <S.TabItem
            key={item.value}
            className={`tab-item, ${item.value === tab ? "active" : ""}`}
            onClick={() => setTab(item.value)}
          >
            {item.name}
          </S.TabItem>
        );
      })}
    </S.Wrap>
  );
};

export default TabsAction;
