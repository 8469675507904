import { useTranslation } from "react-i18next";
import * as S from "../PositionHistory/style";
import ThHead from "../PositionHistory/thHead";
import { ITransactionHistory } from ".";
import { formatTime } from "src/helpers/stringFormatter";
import RenderTypeOrder from "./RenderType";
import RenderAmount from "./RenderAmount";
import RenderSymbol from "./RenderSymbol";

export default function TableTransactionHistory({
  rows,
  loading,
}: {
  rows: ITransactionHistory[];
  loading: boolean;
}) {
  const { t } = useTranslation("common");
  return (
    <S.TableStyle>
      <table className={"dex_table text-table-xs"}>
        <thead>
          <tr className={"table-head"}>
            <ThHead text={t("order.time")} />
            <ThHead text={t("order.type")} />
            <ThHead text={t("order.amount")} />
            <ThHead text={t("order.asset")} />
            <ThHead text={t("order.symbol")} />
          </tr>
        </thead>
        <tbody
          className={"dex_table__scrollbar overflow-tbody"}
          style={{
            maxHeight: 210,
            verticalAlign: "top",
            marginTop: 16,
          }}>
          {loading && <div style={{ height: 230 }}></div>}
          {!loading && !rows.length && <div style={{ height: 230 }}></div>}
          {!loading && !!rows.length && (
            <>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className={"text-normal"}>{formatTime(row.time)}</td>
                  <td className={"text-normal"}>
                    <RenderTypeOrder transaction={row} />
                  </td>
                  <td className={"text-normal"}>
                    <RenderAmount transaction={row} />
                  </td>
                  <td className={"text-normal"}>{row.asset ?? "--"}</td>
                  <td>
                    <RenderSymbol transaction={row} />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </S.TableStyle>
  );
}
