import TextNumber from "src/components/cores/TextNumber";
import { IFill } from "src/interfaces/fill";
import { Instrument } from "src/services/instrument";

export default function Fee({
  instruments,
  fill,
  isCoinM,
}: {
  instruments: Instrument[];
  fill: IFill;
  isCoinM: boolean;
}) {
  const currentInstrument = instruments?.find(
    (instrument) => instrument?.symbol === fill?.symbol
  );

  // return (
  //   <TooltipNumber
  //     defaultValue={fill.fee}
  //     characters={13}
  //     decimal={8}
  //     symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
  //   />
  // );

  return (
    <TextNumber
      defaultValue={fill.fee ?? "0"}
      symbol={
        isCoinM
          ? currentInstrument?.rootSymbol
          : currentInstrument?.quoteCurrency
      }
      isCoinM={false}
    />
  );
}
