import styled from "@emotion/styled";

export const DashboardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  width: 100%;
  padding: 15px;
  .order {
    height: 100%;
    width: 100%;
    flex: 1 0 auto;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .command {
    display: flex;
    gap: 0 16px;
    align-items: center;
    width: 100%;
    flex-direction: row;
    @media only screen and (max-width: 567px) {
      gap: 0;
      background: var(--bg-order-input);
      height: 28px;
      justify-content: space-evenly;
    }
    .open-iso {
      font-style: normal;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      font-family: inherit;
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      gap: 0 4px;
      color: ${({ theme }) => theme.colors.text1};
      @media only screen and (max-width: 567px) {
        width: 49%;
        height: 28px;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .line-center {
      display: none;
      @media only screen and (max-width: 567px) {
        display: block;
        width: 1px;
        height: 12px;
        background: #d0d5dd;
      }
    }

    .leverage {
      border: none;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.green1};
      cursor: pointer;
      @media only screen and (max-width: 567px) {
        width: 49%;
        height: 28px;
        color: ${({ theme }) => theme.colors.text1};
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        opacity: 0.9;
      }
    }

    button:disabled {
      cursor: not-allowed;
    }
  }
  .open-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 56px;
    margin: 20px 0 10px;
    @media only screen and (max-width: 567px) {
      margin: 15px 0 10px;
    }
    .bt-open {
      width: 50%;
      height: 38px;
      position: relative;
      color: #ffffff;
      background-color: ${({ theme }) => theme.colors.green1};
      /* background-color: var(--color-background-order-form_cross); */
      border-radius: 40px 0 0 40px;
      padding-left: 27px;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &::after {
        content: " ";
        position: absolute;
        display: block;
        width: 43px;
        height: 38px;
        top: 0;
        right: -5px;
        z-index: 1;
        background: ${({ theme }) => theme.colors.green1};
        transform-origin: bottom left;
        -ms-transform: skew(-45deg, 0deg);
        -webkit-transform: skew(-45deg, 0deg);
        transform: skew(-45deg, 0deg);
        -moz-transform: skew(-45deg, 0deg);
        -o-transform: skew(-45deg, 0deg);
        border-radius: 0 12px 8px 0;
      }
    }
    .bt-close {
      width: 50%;
      height: 38px;
      position: relative;
      cursor: pointer;
      background-color: ${({ theme }) => theme.chart.bgTabTrading};
      color: var(--color-text);
      border-radius: 0 40px 40px 0;
      padding-right: 27px;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: " ";
        position: absolute;
        display: block;
        width: 43px;
        height: 38px;
        top: 0;
        left: -43px;
        z-index: 1;
        background: ${({ theme }) => theme.chart.bgTabTrading};
        transform-origin: bottom right;
        -ms-transform: skew(-45deg, 0deg);
        -webkit-transform: skew(-45deg, 0deg);
        transform: skew(-45deg, 0deg);
        border-radius: 8px 0 0 12px;
        -webkit-border-radius: 8px 0 0 12px;
        -moz-border-radius: 8px 0 0 12px;
        -ms-border-radius: 8px 0 0 12px;
        -o-border-radius: 8px 0 0 12px;
      }
    }
  }
  .type-order {
    display: flex;
    align-items: stretch;
    margin: 12px 0px;
    gap: 10px;
    position: relative;
    border-bottom: 1px solid var(--bg-order-type-bot);
    .select-stop {
      min-width: 100px;
      border: 1px solid var(--color-line);
      color: var(--color-primary) !important;
    }
    .type-item {
      cursor: pointer;
      padding: 10px 3px;
      text-align: center;
      color: var(--text-type-order);
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      white-space: nowrap;
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .type-item-stop {
      display: flex;
      gap: 4px;
      cursor: pointer;
      text-overflow: ellipsis;
      color: var(--text-type-item);
    }
    .type-item-active {
      cursor: pointer;
      color: var(--text-type-order-active);
      border-bottom: 1px solid var(--text-type-order-active);
    }
    .tooltip {
      position: absolute;
      right: 0px;
      top: 10px;
      cursor: pointer;
    }
    .icon-up {
      transform: rotate(180deg);
    }
  }
  .order-detail {
    height: 100%;
    /* //   margin: 16px 20px; */
    .type-label {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: bold;
    }
    .order-detail-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0;

      .icon-calculator {
        cursor: pointer;
      }
    }
    .field-label {
      font-size: 12px;
      color: var(--text-field-label);
    }
    .order-size {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--color-line);
      background-color: #f8f8f8;
      border-radius: 8px;
      margin-bottom: 12px;
    }
    .order-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 48px;
      margin-bottom: 12px;
      background-color: var(--bg-order-input);
      border: 1px solid var(--bg-order);

      .input-order {
        width: 100%;
        padding: 5px 0;
        margin-left: 15px;
        font-family: inherit;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-text);
        background-color: var(--bg-order-input);
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .text-gray-600 {
        width: 60%;
      }
      input::placeholder {
        font-family: inherit;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-available);
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      &.last-order-price {
        margin-bottom: 0;
      }
      &:focus-within {
        border: 1px solid #00ddb3;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-label-2);
        cursor: pointer;
      }
      .tif-select {
        display: flex;
        align-items: center;
        padding-right: 12px;
        .label {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: var(--color-order-label);
          padding-right: 4px;
          white-space: nowrap;
          @media only screen and (max-width: 567px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .icon-up {
          transform: rotate(180deg);
          padding-top: 2px;
        }
        .label-active {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: var(--color-order-label-active);
          padding-right: 4px;
          white-space: nowrap;
          @media only screen and (max-width: 567px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    .callback-rate-container {
      display: flex;
      gap: 8px;
      cursor: pointer;
      .order-price-trailing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 48px;
        margin-bottom: 12px;
        background-color: var(--bg-order-input);
        border: 1px solid var(--bg-order);
        .input-order-trailing {
          .input-order {
            width: 90%;
            padding: 0;
            margin-left: 15px;
            font-family: inherit;
            font-size: 14px;
            line-height: 18px;
            color: var(--color-text);
            background-color: var(--bg-order-input);
            @media only screen and (max-width: 567px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
        .token-symbol {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: var(--color-label-4);
          padding-right: 8px;
          @media only screen and (max-width: 567px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .rate {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-order-input);
        border-radius: 40px;
        margin-bottom: 12px;
        width: 59px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        height: 32px;
        color: var(--color-label-4);
        padding: 5px;

        &:hover {
          color: var(--color-text);
          background: var(--bg-button);
        }
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .order-quantity {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 12px;
    }
    .callback-rate-container {
      display: flex;
      gap: 8px;
      cursor: pointer;
      .rate {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-order-input);
        border-radius: 40px;
        margin-bottom: 12px;
        width: 59px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-label-4);
        padding: 20px 5px;

        &:hover {
          color: var(--color-text);
          background: var(--bg-button);
        }
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .input {
      width: 90%;
      padding: 5px 0;
      margin-left: 15px;
      font-family: inherit;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-text);
      background-color: var(--bg-order-input);
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    input::placeholder {
      font-family: inherit;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-available);
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .message-error {
      text-align: center;
      color: var(--color-error);
    }
    .cost-mb {
      margin: 16px 0 0 !important;
      @media only screen and (max-width: 400px) {
        margin: 20px 0 16px 0 !important;
      }
    }
    .cost {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;

      .cost-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .label-span {
          color: var(--color-available);
        }

        .value-span {
          color: var(--color-text);
        }
      }
    }
    .select-tpls {
      border-top: 1px solid var(--bg-order-type-bot);
      border-bottom: 1px solid var(--bg-order-type-bot);
      padding: 12px 0 2px;
    }
    .order-value {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      color: var(--text-info);
    }
    .order-balance {
      color: var(--text-info);
      display: flex;
      gap: 4px;
      .value-span {
        color: var(--color-text);
        line-height: 16px;
      }
      span:first-child {
        color: var(--color-available);
        font-size: 14px;
        line-height: 16px;
        line-height: 16px;
      }
    }

    .disabled {
      cursor: not-allowed !important;
    }

    .label-checkbox {
      display: flex;
      align-items: center;
      gap: 6px;
      .label-checkbox-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-text);
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
      .tool {
        display: flex;
        align-items: baseline;
      }
    }

    .reduce-flex {
      display: flex;
      justify-content: space-between;
      /* padding-bottom: 30px; */
      .tif {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 10px;
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: var(--color-label-2);
          cursor: pointer;
          @media only screen and (max-width: 567px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
        .tif-label {
          font-size: 14px;
          line-height: 17px;
          color: var(--color-label-4);
          @media only screen and (max-width: 567px) {
            font-size: 12px;
            line-height: 15px;
          }
        }
        .tif-select {
          span {
            padding-right: 4px;
            color: var(--color-text);
            font-size: 14px;
            line-height: 17px;
            @media only screen and (max-width: 567px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
          img {
            padding-bottom: 2px;
          }
        }
        .icon-up {
          padding-top: 4px;
          transform: rotate(180deg);
        }
        .tif-icon {
          width: 16px;
          padding-bottom: 14px;
          cursor: pointer;
        }
      }
    }
    .container {
      display: block;
      text-align: left;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding-left: 28px;
      margin-bottom: 10px;
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      left: 0px;
      top: 2px;
      height: 16px;
      width: 16px;
      background-color: var(--checkmark);
      border-radius: 2px;
    }
    .container:hover .checkmark {
      // background: #ccc;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    .container .checkmark:after {
      left: 4.5px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid var(--color-text);
      border-width: 0 2.5px 2.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .container input:checked ~ .checkmark {
      background-color: var(--color-primary-1);
    }
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .last-price {
      display: flex;
      align-items: center;
      gap: 5px;
      .last-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-label-4);
        padding-right: 5px;
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .last {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #00ddb3;
      cursor: pointer;
      padding-left: 10px;
      white-space: nowrap;
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .addonAfter {
      padding-right: 15px;
      // .token-symbol {
      //   padding-right: 0;
      // }
    }
    .token-symbol {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-label-4);
      padding-right: 8px;
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .change-price {
      position: relative;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      // gap: 2px;
      justify-content: center;

      .arrow-up {
        cursor: pointer;
        user-select: none;
        &:hover {
          opacity: 0.8;
        }
      }
      .arrow-down {
        cursor: pointer;
        user-select: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .bottom-guest {
      display: block;
      margin-top: 60px;
      padding-top: 30px;
      text-align: center;
      border-top: 1px solid var(--line);
      .button {
        width: 100%;
        margin-top: 34px;
        background: var(--bg-primary);
        color: #fff;
        font-weight: bold;
        padding: 6px 24px 7px 24px;
      }
    }

    .command-form {
      display: flex;
      gap: 0 12px;
      align-items: center;
      width: 100%;
      flex-direction: row;
      margin-top: 16px;
      @media only screen and (max-width: 700px) {
        gap: 0 10px;
      }
      .open {
        font-style: normal;
        border-radius: 48px;
        font-weight: 600;
        font-size: 14px;
        font-family: inherit;
        cursor: pointer;
        width: 50%;
        padding: 10px 0;
        border: none;
        cursor: pointer;
        color: var(--color-white);
        @media only screen and (max-width: 700px) {
          width: 100%;
        }
        @media only screen and (max-width: 567px) {
          font-size: 12px;
          line-height: 16px;
        }

        &.buy {
          background: #00b277;
          @media only screen and (max-width: 700px) {
            width: 100%;
          }
        }

        &.sell {
          background: #e02d3c;
          @media only screen and (max-width: 700px) {
            width: 100%;
          }
        }
      }

      .cost {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        .cost-item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .label-span {
            color: var(--color-available);
          }

          .value-span {
            color: var(--color-text);
          }
        }
      }

      button:disabled {
        cursor: not-allowed;
      }
    }
  }
`;
