import { IOrder } from "src/interfaces/order";
import ItemInfo from "../PositionHistory/ItemInfo";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import { useTranslation } from "react-i18next";
import { formatTime } from "src/helpers/stringFormatter";
import { OrderSide, OrderType } from "src/features/PositionAndHistory/constant";
import RenderTypeOrder from "./RenderTypeOrder";
import { Instrument } from "src/services/instrument";
import { useAppSelector } from "src/store/hooks";
import RenderAmount from "./RenderAmount";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import Price from "./Price";
import { useState } from "react";
import FilledQuantity from "./FilledQuantity";
import { ViewOrderDialog } from "src/features/PositionAndHistory/dialog/ViewOrderDialog";

export default function ItemMobile({
  row,
  isCoinM,
  tradingRulesCache,
}: {
  row: IOrder;
  isCoinM: boolean;
  tradingRulesCache: ITradingRuleCache[];
}) {
  const { t } = useTranslation("common");
  const [orderSelectedToView, setOrderSelectedToView] =
    useState<IOrder | null>();
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const updatedTime =
    row.tpSLType === OrderType.takeProfitMarket ||
    (row.tpSLType === OrderType.stopMarket && row.isTpSlOrder)
      ? row.updatedAt
      : row.createdAt;
  const currentInstrument = instruments?.find(
    (instrument) => instrument?.symbol === row?.symbol
  );
  const handleViewOrder = (order: IOrder) => {
    let isShowBtnView = false;
    if (!order.parentOrderId) {
      if (order.isTpSlTriggered === false) {
        if (order.takeProfitOrderId || order.stopLossOrderId)
          isShowBtnView = true;
      }
    } else {
      isShowBtnView = true;
    }
    return isShowBtnView;
  };
  const handleClickDetail = (data: IOrder) => {
    setOrderSelectedToView(data);
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };
  return (
    <div
      className="container-item"
      style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <div style={{ display: "flex", gap: 10 }}>
        <div>
          <span
            className={`${
              row.side === OrderSide.buy ? "color-buy" : "color-sell"
            }`}>
            {t(formatOrderText(row.side))}
          </span>{" "}
          - <span>{getSymbolName(row.symbol ?? "")}</span>
        </div>
        {/* <ItemBadge value={`${row.assest}`} /> */}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ItemInfo
            title={formatTime(updatedTime)}
            description={`Closed time`}
            textStyle={{ textAlign: "left" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ItemInfo
            nodeTitle={
              <span className="text-color text-left">
                {getSymbolName(row.symbol ?? "")}
              </span>
            }
            description={`Futures l Coin`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <div
                className="text-color"
                style={{ display: "flex", fontSize: 12, gap: 4 }}>
                <span>{row.timeInForce}</span>
                <span>|</span>
                <RenderTypeOrder order={row} />
              </div>
            }
            description={`Type`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ItemInfo
            nodeTitle={
              <div
                style={{ display: "flex", gap: 4, fontSize: 12 }}
                className="text-color">
                <RenderAmount
                  order={row}
                  instruments={instruments}
                  tradingRulesCache={tradingRulesCache}
                  isCoinM={isCoinM}
                />
                <span>|</span>
                <span>{isCoinM ? "Cont" : currentInstrument?.rootSymbol}</span>
              </div>
            }
            description={`Futures l Coin`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <div style={{ fontSize: 12 }} className="text-color">
                <Price
                  position={row}
                  tradingRulesCache={tradingRulesCache}
                  currentPrice={row?.price ?? "0"}
                />
              </div>
            }
            description={`Price`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ItemInfo
            nodeTitle={
              <div
                style={{ fontSize: 12, textAlign: "left" }}
                className="text-color">
                {handleViewOrder(row) ? (
                  <span
                    className={"btn-view"}
                    onClick={(e) => {
                      handleClickDetail(row);
                      e.stopPropagation();
                    }}>
                    {t("order.view")}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            }
            description={`TP/SL:`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <span className="text-color text-right">
                {row.isReduceOnly ? t("order.yes") : t("order.no")}
              </span>
            }
            description={`Reduce-only`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ItemInfo
            nodeTitle={
              <div style={{ fontSize: 12 }} className="text-color">
                <FilledQuantity
                  order={row}
                  instruments={instruments}
                  tradingRulesCache={tradingRulesCache}
                  isCoinM={isCoinM}
                />
              </div>
            }
            description={`Filled`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            title={row.isPostOnly ? t("order.yes") : t("order.no")}
            description={`Post-only`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
      </div>
      {orderSelectedToView && (
        <ViewOrderDialog
          data={orderSelectedToView}
          handleCloseDialog={handleCloseViewDialog}
          openDialog={openViewDialog}
        />
      )}
    </div>
  );
}
