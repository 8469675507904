import styled from "@emotion/styled";

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin-top: 65px;
  max-width: 100%;
  gap: 10px;
  @media only screen and (max-width: 970px) {
    max-width: 100vw;
  }

  & > img {
    @media only screen and (max-width: 400px) {
      width: 41px;
      height: 50px;
      margin-left: 4px;
    }
  }
`;
