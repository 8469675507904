import BigNumber from "bignumber.js";
import TextNumber from "src/components/cores/TextNumber";
import { IOrder } from "src/interfaces/order";
import { Instrument } from "src/services/instrument";

export default function RenderFilledQuantity({
  order,
  instruments,
  isCoinM,
  customText,
}: {
  order: IOrder;
  instruments: Instrument[];
  isCoinM: boolean;
  customText?: string;
}) {
  const fillQuantity = new BigNumber(order.quantity ?? "0").minus(
    order.remaining ?? "0"
  );
  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === order.symbol
  );

  // return (
  //   <TooltipNumber
  //     defaultValue={fillQuantity}
  //     characters={9}
  //     decimal={isCoinM ? 0 : 4}
  //     symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
  //   />
  // );

  return (
    <TextNumber
      defaultValue={fillQuantity.toString()}
      symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
      isCoinM={isCoinM}
      customText={customText}
    />
  );
}
