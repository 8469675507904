import { Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AsksIcon, BidsIcon, BothIcon } from "../svg";
import * as S from "./style";

interface TabsActionProps {
  tab: FilterType;
  setTab: (value: FilterType) => void;
}

export enum FilterType {
  both,
  bids,
  asks,
}

const TabsOrderAction: React.FC<TabsActionProps> = ({ tab, setTab }) => {
  const { t } = useTranslation("common");

  const tabs = [
    {
      value: FilterType.both,
      icon: BothIcon,
      title: t("orderBook.order_book"),
    },
    {
      value: FilterType.bids,
      icon: BidsIcon,
      title: t("orderBook.buy_order"),
    },
    {
      value: FilterType.asks,
      icon: AsksIcon,
      title: t("orderBook.sell_order"),
    },
  ];

  return (
    <S.Wrap>
      {tabs.map(({ value, icon: Icon, title }) => {
        return (
          <S.TabItem
            key={value}
            className={`tab-item ${value === tab ? "active" : ""}`}
            onClick={() => setTab(value)}
          >
            <Tooltip title={title}>
              <Icon />
            </Tooltip>
          </S.TabItem>
        );
      })}
    </S.Wrap>
  );
};

export default TabsOrderAction;
