import { Box } from "@material-ui/core";
import { theme } from "highcharts";
import { useTranslation } from "react-i18next";
import { BsInfoCircleFill } from "react-icons/bs";
import TooltipNumber from "src/components/cores/Tooltip";
import TooltipText from "src/components/cores/TooltipText";
import { PriceTypeValue } from "src/features/PositionAndHistory/OpenOrder";
import { OrderType } from "src/features/PositionAndHistory/constant";
import { formatPriceRoundDown } from "src/helpers/numberFormatter";
import { IOrder, OrderStatus } from "src/interfaces/order";
import { THEME_MODE } from "src/interfaces/theme";
import { Instrument } from "src/services/instrument";
import { useAppSelector } from "src/store/hooks";

export default function RenderTriggerCondition({
  order,
  instruments,
  customText,
}: {
  order: IOrder;
  instruments: Instrument[];
  customText?: string;
}) {
  const { t } = useTranslation("common");
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  if (!order.stopCondition) return <span className={customText}>--</span>;
  if (
    order.tpSLType === OrderType.stopLimit &&
    order.status === OrderStatus.ACTIVE
  )
    return <span className={customText}>--</span>;

  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === order.symbol
  );
  const tickSize = Number(currentInstrument?.tickSize) || "0.01";
  const precision = -Math.ceil(Math.log10(Number(tickSize)));

  const isTrailingStop = order.tpSLType === OrderType.trailingStop;
  const text = `${t("order.activation_price")}: ${formatPriceRoundDown(
    order?.activationPrice,
    precision
  )}. ${t("order.trigger_type")}: ${
    order?.trigger === PriceTypeValue.last
      ? t("order.last_price")
      : t("order.mark_price")
  }, ${t("order.callback_rate")}: ${order?.callbackRate ?? "0"}%.`;

  if (order.trigger === PriceTypeValue.last) {
    if (isTrailingStop) {
      return (
        <Box display="flex" alignItems="center">
          {t("order.activation_price")}{" "}
          {order.stopCondition === "GT" ? ">=" : "<="}&nbsp;
          <TooltipNumber
            defaultValue={order?.activationPrice}
            characters={7}
            decimal={precision}
          />
          &nbsp;
          <TooltipText text={text}>
            <BsInfoCircleFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            />
          </TooltipText>
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          {t("order.last_price")} {order.stopCondition === "GT" ? ">=" : "<="}
          &nbsp;
          <TooltipNumber
            defaultValue={order?.tpSLPrice}
            characters={7}
            decimal={precision}
          />
        </Box>
      );
    }
  } else if (order.trigger === PriceTypeValue.mark) {
    if (isTrailingStop) {
      return (
        <Box display="flex" alignItems="center">
          {t("order.activation_price")}{" "}
          {order.stopCondition === "GT" ? ">=" : "<="}&nbsp;
          <TooltipNumber
            defaultValue={order?.activationPrice}
            characters={7}
            decimal={precision}
          />
          &nbsp;
          <TooltipText text={text}>
            <BsInfoCircleFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            />
          </TooltipText>
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          {t("order.mark_price")} {order.stopCondition === "GT" ? ">=" : "<="}
          &nbsp;
          <TooltipNumber
            defaultValue={order?.tpSLPrice}
            characters={7}
            decimal={precision}
          />
        </Box>
      );
    }
  }
  return <span className={customText}>--</span>;
}
