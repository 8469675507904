import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Trading from "src/features/Trading/Trading";
import * as S from "./style";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";

import ftAsset from "src/assets/icon/mobile/ftAsset.png";
import ftFutureDark from "src/assets/icon/mobile/ftFutureDark.png";
import ftFutureLight from "src/assets/icon/mobile/ftFutureLight.png";
import ftHome from "src/assets/icon/mobile/ftHome.png";
import ftMarket from "src/assets/icon/mobile/ftMarket.png";
import ftTrade from "src/assets/icon/mobile/ftTrade.png";

const FooterDashboardMobile = () => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const linkList = [
    { id: 0, icon: ftHome, link: "home" },
    { id: 1, icon: ftMarket, link: "market" },
    { id: 2, icon: ftTrade, link: "trade" },
    { id: 3, icon: theme === THEME_MODE.LIGHT ? ftFutureLight : ftFutureDark },
    { id: 4, icon: ftAsset, link: "asset" },
  ];
  return (
    <S.Container>
      {linkList.map((v: any, i: number) => (
        <img
          alt=""
          src={v.icon}
          key={i}
          onClick={() => (v.link ? alert(v.link) : console.log("futures"))}
        />
      ))}
    </S.Container>
  );
};

export default FooterDashboardMobile;
