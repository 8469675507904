import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
// import { iconCloseCommon, iconCloseCommonDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Preference/PreferenceModal.module.scss';
import { OrderConfirmation } from './OrderConfirmation';
import { Notification } from './Notification';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
import { BsXOctagonFill } from "react-icons/bs";

const cx = classnames.bind(styles);

type PreferenceProps = {
  open: boolean;
  handleClose: () => void;
};

export const PreferenceModal = ({ open, handleClose }: PreferenceProps) => {
  const [tabActive, settabActive] = useState(0);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation('common');

  const TAB_PREFERENCE = [`${t('tradingRule.order_confirm')}`, `${t('tradingRule.notification')}`];
  return (
    <Dialog className={cx('wrapper-pre')} maxWidth={'md'} open={open} onClose={handleClose}>
      {/*title*/}
      <DialogTitle className={cx('title-prefer')}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography className={cx('head-preference')} component={'div'}>
            {t('tradingRule.preference')}
          </Typography>
          <Typography component={'div'}>
            <IconButton size={'small'} className={cx('hidden')} onClick={handleClose}>
              {/* <img src={theme === THEME_MODE.LIGHT ? Icon : Icon} alt="close" /> */}
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2}}
              />
            </IconButton>
          </Typography>
        </Box>
      </DialogTitle>

      {/*contents*/}
      <DialogContent className={cx('content')}>
        {/* Tab here */}
        <div className={cx('tab-preference')}>
          {TAB_PREFERENCE.map((item, index) => (
            <div
              className={cx('tab-items', tabActive === index && 'tab-items-active')}
              key={index}
              onClick={() => settabActive(index)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className={cx('tab-detail')}>
          {tabActive === 0 && <OrderConfirmation />}
          {tabActive === 1 && <Notification />}
        </div>
      </DialogContent>
    </Dialog>
  );
};
