import classnames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
// import { dropIcon, dropIconMbLight, iconDrop, iconDropLight } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import Market from "src/features/Market/Market";
import {
  formatPrice,
  getValueClassName,
  formatNumber,
} from "src/helpers/numberFormatter";
import { getTicker24h } from "src/services/ticker";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import showCointDark from "src/assets/icon/menu/showCointDark.png";
import showCointLight from "src/assets/icon/menu/showCointLight.png";
import BTCUSDT from "src/assets/icon/menu/BTCUSDT.png";
// import arrowNext from 'src/assets/icon/light/arrow-next.svg';
import { Instrument } from "src/services/instrument";
import useScreen from "src/hooks/useScreen";
import { useCountdown } from "src/helpers/useCountDown";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import TooltipText from "src/components/cores/TooltipText";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useTranslation } from "react-i18next";
import { getIconPriceChange } from "src/features/Market/components/ChooseMarketModal/ChooseMarketModal";
import {
  BsCurrencyExchange,
  BsArrowBarLeft,
  BsArrowBarRight,
} from "react-icons/bs";

const PairStatistics: React.FC = () => {
  const currentInstrument: Instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );

  const dispatch = useAppDispatch();
  const instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const ticker = useAppSelector((state) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );
  // console.log("instrument fdsafdas", instrument);
  const tickerLoading = useAppSelector((state) => state.ticker.loading);
  const theme = useAppSelector((state) => state.theme.themeMode);

  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );

  const [needScroll, setNeedScroll] = useState(false);
  const [transformX, setTransformX] = useState(0);
  const [openMarket, setOpenMarket] = useState(false);
  const [fundingRate, setFundingRate] = useState(0);
  const [countDown, setCountDown] = useState(new Date().getTime());

  const contentRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [hours, minutes, seconds] = useCountdown(countDown);
  const { isMobile } = useScreen();
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const { t } = useTranslation("common");

  const setScroll = () => {
    const contentWidth = contentRef.current?.offsetWidth || 0;
    const parentWidth = parentRef.current?.offsetWidth || 0;

    if (contentWidth <= parentWidth) {
      setTransformX(0);
    } else {
      if (transformX != 0) {
        setTransformX(parentWidth - contentWidth);
      }
    }

    setNeedScroll(contentWidth > parentWidth);
  };

  useEffect(() => {
    dispatch(getTicker24h());
  }, []);
  useEffect(() => {
    if (ticker?.fundingRate) {
      setFundingRate(Number(ticker?.fundingRate) * 100);
    }
    if (ticker?.nextFunding && ticker?.nextFunding > new Date().getTime()) {
      setCountDown(ticker?.nextFunding);
    }
  }, [ticker?.fundingRate, ticker?.nextFunding]);

  useEffect(() => {
    setScroll();
  }, [parentRef.current?.offsetWidth]);

  useEffect(() => {
    if (!needScroll) {
      const contentWidth = contentRef.current?.offsetWidth || 0;
      const parentWidth = parentRef.current?.offsetWidth || 0;
      setNeedScroll(contentWidth > parentWidth);
    }
  }, [contentRef.current?.offsetWidth]);

  // const getIconPriceChange = (value: string | undefined) => {
  //   if (value === undefined || value === null) {
  //     return '';
  //   }
  //   const number = parseFloat(value);
  //   if (number > 0) {
  //     return <div className={'increase')}>+</div>;
  //   } else if (number === 0) {
  //     return '';
  //   } else {
  //     return <div className={'decrease')}>-</div>;
  //   }
  // };

  return (
    <>
      <div className={"box_container"}>
        <div className={"pair-statistics"} ref={parentRef}>
          <div className={"statistics-wrapper"}>
            <div
              className={"statistics-container"}
              style={{ transform: `translateX(${transformX}px)` }}
              ref={contentRef}>
              <div className={"coint-item"}>
                <div className={"icon"}>
                  <img alt="coint" src={BTCUSDT} />
                </div>
                <div className={"info"}>
                  <div
                    className={"network"}
                    onClick={() => setOpenMarket(!openMarket)}>
                    <div className={"symbol"}>
                      <span className={"coin-name"}>{instrument.name}</span>
                    </div>
                    <div className={"drop-icon"}>
                      <img
                        alt="down"
                        src={
                          theme === THEME_MODE.LIGHT
                            ? showCointLight
                            : showCointDark
                        }
                      />
                    </div>
                  </div>
                  <div className={"statistic-item-label-perpetual"}>
                    {t("pair.perpetual")}
                  </div>
                </div>
              </div>

              <div className={"statistic-item coint-choose"}>
                {tickerLoading ? (
                  <div className={"statistic-item-last-price"}>0</div>
                ) : (
                  <span
                    className={`statistic-item-last-price ${getValueClassName(
                      ticker?.lastPriceChange
                    )}`}>
                    <TooltipNumber
                      characters={9}
                      decimal={decimal}
                      defaultValue={ticker?.lastPrice}
                    />
                  </span>
                )}
              </div>
              <div className={"statistic-item-price"}>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value-price"}>
                    <TooltipNumber
                      characters={9}
                      decimal={decimal}
                      defaultValue={ticker?.lastPrice}
                    />
                  </span>
                )}
              </div>
              <div className={"statistic-item hidden-mb"}>
                <div className={"tooltip-filter"}>
                  <TooltipText text={t("pair.latest_mark_price_noti")}>
                    <div className={"statistic-item-label check-tooltip"}>
                      {t("pair.mark_price")}
                    </div>
                  </TooltipText>
                </div>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value"}>
                    <TooltipNumber
                      characters={8}
                      decimal={decimal}
                      defaultValue={ticker?.oraclePrice}
                    />
                  </span>
                )}
              </div>
              <div className={"statistic-item hidden-mb"}>
                <div className={"tooltip-filter"}>
                  <TooltipText
                    text={t("pair.index_price_noti", {
                      number: formatPrice(ticker?.indexPrice, instrument),
                    })}>
                    <div className={"statistic-item-label check-tooltip"}>
                      {t("pair.index_price")}
                    </div>
                  </TooltipText>
                </div>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value"}>
                    <TooltipNumber
                      characters={8}
                      decimal={decimal}
                      defaultValue={ticker?.indexPrice}
                    />
                  </span>
                )}
              </div>
              <div className={"statistic-item hidden-mb"}>
                <div className={"tooltip-filter"}>
                  <TooltipText text={t("pair.the_payment_noti")}>
                    <div
                      className={"statistic-item-label check-tooltip"}
                      // style={{ minWidth: 110 }}
                    >
                      {t("pair.funding")}/{t("pair.count_down")}
                    </div>
                  </TooltipText>
                </div>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value flex"}>
                    <div
                      className={`funding ${getValueClassName(
                        String(fundingRate)
                      )}`}>
                      {/* {fundingRate >= 0 ? "" : "-"} */}
                      {formatNumber(fundingRate, 4, "")}%{/* -0.0025% */}
                    </div>
                    <div className={"count-down"}>
                      {hours}:{minutes}:{seconds}
                    </div>
                  </span>
                )}
              </div>
              <div className={"statistic-item hidden-mb"}>
                <span
                  className={"statistic-item-label"}
                  // style={{ minWidth: 90 }}
                >
                  {t("pair.24h_change")}
                </span>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value time-change"}>
                    <div
                      className={`price-change ${getValueClassName(
                        ticker?.priceChange
                      )}`}>
                      {ticker?.priceChange ? (
                        <TooltipNumber
                          defaultValue={Math.abs(Number(ticker?.priceChange))}
                          characters={8}
                          decimal={decimal}
                          customText={"tooltip-change-percent"}
                        />
                      ) : (
                        "--"
                      )}
                    </div>
                    {/* <div className={'icon-status')}>{getIconPriceChange(ticker?.priceChangePercent)}</div> */}
                    <span
                      className={`${getValueClassName(
                        ticker?.priceChangePercent
                      )} change-percent`}
                      style={{ display: "flex" }}>
                      {ticker?.priceChangePercent ? (
                        <>
                          {getIconPriceChange(ticker?.priceChangePercent)}
                          <TooltipNumber
                            defaultValue={Math.abs(
                              Number(ticker?.priceChangePercent)
                            )}
                            characters={7}
                            customText={"price-change-percent"}
                            decimal={DECIMAL_TWO}
                            symbol="%"
                          />
                        </>
                      ) : (
                        "--"
                      )}
                    </span>
                  </span>
                )}
              </div>
              <div className={"statistic-item hidden-mb"}>
                <span className={"statistic-item-label"}>
                  {t("pair.24h_high")}
                </span>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value"}>
                    <TooltipNumber
                      defaultValue={ticker?.highPrice}
                      characters={8}
                      decimal={decimal}
                    />
                  </span>
                )}
              </div>
              <div className={"statistic-item hidden-mb"}>
                <span className={"statistic-item-label"}>
                  {t("pair.24h_low")}
                </span>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <span className={"statistic-item-value"}>
                    <TooltipNumber
                      defaultValue={ticker?.lowPrice}
                      characters={8}
                      decimal={decimal}
                    />
                  </span>
                )}
              </div>

              <div className={"statistic-item hidden-mb"}>
                <span className={"statistic-item-label"}>
                  {t("pair.24h_volume")}({instrument.rootSymbol})
                </span>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <div className={"volume-wrap"}>
                    <TooltipNumber
                      defaultValue={ticker?.volume}
                      characters={12}
                      customText={"statistic-item-value"}
                      decimal={DECIMAL_TWO}
                    />
                  </div>
                )}
              </div>

              <div className={"statistic-item hidden-mb"}>
                <span className={"statistic-item-label"}>
                  {t("pair.24h_volume")}(
                  {isCoinM ? "Cont" : instrument.quoteCurrency})
                </span>
                {tickerLoading ? (
                  <div className={"statistic-item-value"}>0</div>
                ) : (
                  <div className={"volume-wrap"}>
                    <TooltipNumber
                      defaultValue={ticker?.quoteVolume}
                      characters={12}
                      customText={"statistic-item-value"}
                      decimal={DECIMAL_TWO}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Market open={openMarket} handleClose={() => setOpenMarket(false)} />
        {needScroll && transformX !== 0 && (
          <div
            style={{
              left: "0px",
              backgroundImage:
                theme === THEME_MODE.LIGHT
                  ? "linear-gradient(-270deg, #fafafc 71.88%, rgba(250, 250, 252, 0) 100%)"
                  : "linear-gradient(-270deg, #1C1C28 71.88%, rgba(28, 28, 40, 0) 100%)",
            }}
            className={"statistic-prev"}
            onClick={() => {
              setTransformX(0);
            }}>
            <BsArrowBarLeft
              fill={`${theme === THEME_MODE.LIGHT ? "black" : "white"}`}
            />
          </div>
        )}
        {needScroll && transformX === 0 && (
          <div
            style={{
              backgroundImage:
                theme === THEME_MODE.LIGHT
                  ? "linear-gradient(270deg, #FAFAFC 71.88%, rgba(250, 250, 252, 0) 100%)"
                  : "linear-gradient(270deg, #1C1C28 71.88%, rgba(28, 28, 40, 0) 100%)",
            }}
            className={"statistic-next"}
            onClick={() => {
              const contentWidth = contentRef.current?.offsetWidth || 0;
              const parentWidth = parentRef.current?.offsetWidth || 0;
              setTransformX(parentWidth - contentWidth);
            }}>
            <BsArrowBarRight
              fill={`${theme === THEME_MODE.LIGHT ? "black" : "white"}`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PairStatistics;
