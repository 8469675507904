import React, { CSSProperties, ReactElement } from "react";

export default function ItemInfo({
  title,
  description,
  style,
  textStyle,
  classNameCustom,
  node,
  nodeTitle,
}: {
  title?: string;
  description?: string;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  classNameCustom?: string;
  node?: ReactElement;
  nodeTitle?: ReactElement;
}) {
  return (
    <div className="container-item-info" style={{ ...style }}>
      {nodeTitle ? (
        nodeTitle
      ) : (
        <div className="title" style={{ ...textStyle }}>
          {title}
        </div>
      )}
      {node ? (
        node
      ) : (
        <div className={`content ${classNameCustom}`} style={{ ...textStyle }}>
          {description}
        </div>
      )}
    </div>
  );
}
