import { TransactionType } from "src/features/PositionAndHistory/constant";
import { getSymbolName } from "src/features/PositionAndHistory/helper";
import { ITransactionHistory } from "src/features/PositionAndHistory/TransactionHistory";

export default function RenderSymbol({
  transaction,
}: {
  transaction: ITransactionHistory;
}) {
  if (
    transaction.type === TransactionType.DEPOSIT ||
    transaction.type === TransactionType.WITHDRAWAL
  ) {
    return <span>{transaction.asset}</span>;
  }

  return <span>{getSymbolName(transaction.symbol)}</span>;
}
