import { useTranslation } from "react-i18next";
import { OrderType } from "src/features/PositionAndHistory/constant";
import { IOrder } from "src/interfaces/order";
import RenderQuantity from "./RenderQuantity";
import { Instrument } from "src/services/instrument";

export default function RenderAmount({
  order,
  instruments,
  isCoinM,
}: {
  order: IOrder;
  instruments: Instrument[];
  isCoinM: boolean;
}) {
  const { t } = useTranslation();
  if (!order.parentOrderId) {
    if (
      order.tpSLType === OrderType.takeProfitMarket ||
      (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
    ) {
      return t("order.close_position");
    }
  }
  return (
    <RenderQuantity order={order} instruments={instruments} isCoinM={isCoinM} />
  );
}
