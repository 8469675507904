import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "src/features/Market/components/TradingRule/TradingRule.module.scss";
import classnames from "classnames/bind";
// import { IconNext } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";

const cx = classnames.bind(styles);

type Props = {
  setTabActive: any;
  tabActive: number;
};

export const TabsMobileTradingRule = ({ setTabActive, tabActive }: Props) => {
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const { t } = useTranslation("common");
  const TAB_TRADING_RULE = [
    `${t("tradingRule.trading_rules")}`,
    `${t("tradingRule.coin_info")}`,
    `${t("tradingRule.funding_rate_history")}`,
    `${t("tradingRule.leverage_margin")}`,
  ];
  const container = document.getElementById("scrollTab");
  const handleScroll = () => {
    if (container) {
      setShowPrev(container.scrollLeft > 0);
      setShowNext(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    handleScroll();
    const container = document.getElementById("scrollTab");
    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [container]);

  const handlePrevClick = () => {
    if (container) {
      container.scrollLeft -= container.clientWidth / 5;
    }
  };

  const handleNextClick = () => {
    if (tabActive === TAB_TRADING_RULE.length - 1 && container) {
      container.scrollLeft = container.clientWidth;
    } else {
      if (container) {
        container.scrollLeft += container.clientWidth / 5;
      }
    }
  };

  const handleClickItem = (index: number) => {
    if (container) {
      if (index === TAB_TRADING_RULE.length - 1) {
        container.scrollLeft = container.clientWidth * 1.4;
        setShowNext(false);
      }
      if (index === 0 || (showPrev && index === 1)) {
        container.scrollLeft = 0;
      }

      if (index === 2) {
        container.scrollLeft = container.clientWidth / 2;
      }
      setTabActive(index);
    }
  };

  return (
    <div className={cx("tab-mobile")}>
      {showPrev && <span onClick={handlePrevClick}>{Icon}</span>}
      <div id="scrollTab" className={cx("tab-preference", "no-scroll")}>
        {TAB_TRADING_RULE.map((item, index) => (
          <div
            key={index}
            className={cx(
              "tab-items",
              tabActive === index && "tab-items-active"
            )}
            onClick={() => handleClickItem(index)}
          >
            {t(item)}
          </div>
        ))}
      </div>
      {showNext && <span onClick={handleNextClick}>{Icon}</span>}
    </div>
  );
};
