import React from "react";
const SettingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.61883 3.28338C8.69417 2.83132 9.08529 2.5 9.54357 2.5H10.4552C10.9135 2.5 11.3046 2.83132 11.38 3.28338L11.5041 4.02822C11.563 4.38178 11.8238 4.66532 12.1548 4.80276C12.486 4.94027 12.8663 4.9219 13.1581 4.71348L13.7727 4.27445C14.1457 4.00807 14.6565 4.05035 14.9806 4.37441L15.6252 5.01903C15.9492 5.34309 15.9915 5.85393 15.7252 6.22686L15.286 6.84172C15.0776 7.13343 15.0592 7.51361 15.1967 7.84469C15.3341 8.17565 15.6176 8.43636 15.971 8.49527L16.716 8.61944C17.1681 8.69478 17.4994 9.0859 17.4994 9.54418V10.4558C17.4994 10.9141 17.1681 11.3052 16.716 11.3806L15.9712 11.5047C15.6176 11.5636 15.3341 11.8244 15.1966 12.1554C15.0591 12.4866 15.0775 12.8669 15.2859 13.1587L15.7249 13.7732C15.9912 14.1461 15.949 14.657 15.6249 14.981L14.9803 15.6256C14.6562 15.9497 14.1454 15.992 13.7725 15.7256L13.1578 15.2866C12.8661 15.0782 12.4858 15.0598 12.1547 15.1973C11.8238 15.3347 11.563 15.6182 11.5041 15.9717L11.38 16.7166C11.3046 17.1687 10.9135 17.5 10.4552 17.5H9.54357C9.08529 17.5 8.69417 17.1687 8.61883 16.7166L8.49469 15.9718C8.43576 15.6182 8.17499 15.3347 7.84396 15.1972C7.51279 15.0597 7.13251 15.0781 6.84072 15.2865L6.22604 15.7256C5.85312 15.992 5.34227 15.9497 5.01822 15.6256L4.37359 14.981C4.04953 14.6569 4.00725 14.1461 4.27363 13.7732L4.71282 13.1583C4.92119 12.8666 4.93956 12.4864 4.8021 12.1553C4.6647 11.8244 4.38122 11.5636 4.02774 11.5047L3.28277 11.3806C2.83071 11.3052 2.49939 10.9141 2.49939 10.4558V9.54418C2.49939 9.0859 2.83071 8.69478 3.28277 8.61944L4.0276 8.4953C4.38117 8.43637 4.66471 8.1756 4.80216 7.84456C4.93966 7.51337 4.9213 7.13308 4.71287 6.84128L4.27396 6.2268C4.00758 5.85388 4.04986 5.34304 4.37392 5.01898L5.01854 4.37435C5.3426 4.0503 5.85344 4.00802 6.22637 4.27439L6.84103 4.71344C7.13276 4.92181 7.51295 4.94018 7.84406 4.80272C8.17503 4.66531 8.43575 4.38182 8.49467 4.02833L8.61883 3.28338Z"
        stroke="#8E8E92"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 10C12.5 11.3807 11.3807 12.5 9.99997 12.5C8.61926 12.5 7.49997 11.3807 7.49997 10C7.49997 8.61931 8.61926 7.50002 9.99997 7.50002C11.3807 7.50002 12.5 8.61931 12.5 10Z"
        stroke="#8E8E92"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SettingIcon;
