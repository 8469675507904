import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
// import { iconDown, IconDropdown, iconSearchSelect, iconX } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import useOnClickOutside from "src/hooks/useClickOutside";
import { useTranslation } from 'react-i18next';
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import {
  BsCaretDownFill,
  BsXOctagonFill,
  BsSearchHeartFill,
} from "react-icons/bs";

type Props = {
  setPairSelect: any;
  pairSelect: any;
  coins: any;
};

const cx = classnames.bind(styles);

export const SelectPair = ({ setPairSelect, pairSelect, coins }: Props) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [openSelect, setOpenSelect] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [listCoin, setListCoin] = useState(coins);
  const el = useRef(null);
  const { t } = useTranslation('common');

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const handleSearch = (text: string) => {
    setTextSearch(text);

    const resultSearch = coins?.filter(
      (item: any) => item?.name && item?.name.toLowerCase().includes(text.toLowerCase()),
    );
    setListCoin(resultSearch);
  };

  useEffect(() => {
    setListCoin(coins);
  }, [coins]);

  return (
    <div className={cx("select-wrapper", "select-pair")} ref={el}>
      <div
        className={cx("select-value")}
        onClick={() => setOpenSelect(!openSelect)}
      >
        <div>
          {pairSelect.name} {t("pair.perpetual")}
        </div>
        <BsCaretDownFill
          fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
        />
      </div>

      {openSelect && (
        <div className={cx("select-list", "max-width")}>
          <div className={cx("select-search")}>
            <input
              placeholder={`${t("pair.search")}`}
              value={textSearch}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {textSearch && (
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.25, cursor: "pointer" }}
                onClick={() => {
                  setTextSearch("");
                  setListCoin(coins);
                }}
              />
            )}
            <BsSearchHeartFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.25 }}
            />
          </div>

          {listCoin.length > 0 ? (
            listCoin?.map((item: any, index: number) => (
              <div
                key={index}
                className={cx(
                  "select-item",
                  pairSelect === item && "select-item-active"
                )}
                onClick={() => {
                  setPairSelect(item);
                  setOpenSelect(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                {item.name} {t("pair.perpetual")}
              </div>
            ))
          ) : (
            <div className={cx("select-item")}>{t("calculator.no_result")}</div>
          )}
        </div>
      )}
    </div>
  );
};
