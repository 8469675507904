import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import ItemInfo from "../PositionHistory/ItemInfo";
import { formatTime } from "src/helpers/stringFormatter";
import { useTranslation } from "react-i18next";
import { IOrder } from "src/interfaces/order";
import RenderTypeOrder from "../OpenOrder/RenderTypeOrder";
import {
  OrderSide,
  OrderStatusText,
  OrderType,
} from "src/features/PositionAndHistory/constant";
import RenderAverage from "./RenderAverage";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import Price from "../OpenOrder/Price";
import RenderFilledQuantity from "./RenderFilledQuantity";
import { Instrument } from "src/services/instrument";
import RenderReduceOnly from "../OpenOrder/RenderReduceOnly";
import RenderTriggerCondition from "../OpenOrder/RenderTriggerCondition";

export default function ItemMobile({
  row,
  tradingRulesCache,
  instruments,
  isCoinM,
}: {
  row: IOrder;
  tradingRulesCache: ITradingRuleCache[];
  instruments: Instrument[];
  isCoinM: boolean;
}) {
  const { t } = useTranslation("common");
  return (
    <div
      className="container-item"
      style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <div style={{ display: "flex", gap: 10 }}>
        <div>
          <span
            className={`${
              row.side === OrderSide.buy ? "color-buy" : "color-sell"
            }`}>
            {t(formatOrderText(row.side))}
          </span>{" "}
          - <span>{getSymbolName(row.symbol ?? "")}</span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            title={formatTime(row.updatedAt)}
            description={`Time`}
            textStyle={{ textAlign: "left" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            title={getSymbolName(row.symbol ?? "")}
            description={`Futures l Coin`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <div
                style={{ display: "flex", fontSize: 12, gap: 4 }}
                className="text-color">
                <span>{row.timeInForce}</span>
                <span>|</span>
                <RenderTypeOrder order={row} />
              </div>
            }
            description={`Type`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            nodeTitle={
              <span
                style={{ fontSize: 12 }}
                className={`${
                  row.side === OrderSide.buy ? "color-buy" : "color-sell"
                }`}>
                {t(formatOrderText(row.side))}
              </span>
            }
            description={`Side`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <div style={{ fontSize: 12 }} className="text-color">
                <RenderAverage
                  customText="text-right"
                  data={row}
                  tradingRulesCache={tradingRulesCache}
                />
              </div>
            }
            description={`Average`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            nodeTitle={
              row.type === OrderType.limit ? (
                <div style={{ fontSize: 12 }} className="text-color">
                  <Price
                    position={row}
                    tradingRulesCache={tradingRulesCache}
                    currentPrice={row.price ?? "0"}
                  />
                </div>
              ) : (
                <span style={{ fontSize: 12 }} className="text-color">
                  --
                </span>
              )
            }
            description={`Price`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <div style={{ fontSize: 12 }} className="text-color">
                <RenderFilledQuantity
                  order={row}
                  instruments={instruments}
                  isCoinM={isCoinM}
                  customText="text-right"
                />
              </div>
            }
            description={`Executed`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            title={"32.24 BTC"}
            description={`Amount`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <div style={{ fontSize: 12 }} className="text-right text-color">
                <RenderTriggerCondition order={row} instruments={instruments} />
              </div>
            }
            description={`Trigger Conditions`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            nodeTitle={
              <div style={{ fontSize: 12 }} className="text-left text-color">
                <RenderReduceOnly order={row} />
              </div>
            }
            description={`Reduce-only`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            title={row?.isPostOnly ? t("order.yes") : t("order.no")}
            description={`Post only`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            title={t(
              formatOrderText(Object(OrderStatusText)[row?.status ?? "PENDING"])
            )}
            description={`Status`}
            textStyle={{ textAlign: "left" }}
          />
        </div>
      </div>
    </div>
  );
}
