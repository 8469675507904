import React, {
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useEffect,
} from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
// import { downFilterDark, downFilterLight, IConDownDefault, iconSearchSelect, iconX } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import useOnClickOutside from "src/hooks/useClickOutside";
import styled from "@emotion/styled";
import { useAppSelector } from "src/store/hooks";
import useScreen, { useWindowSize } from "src/hooks/useScreen";
import { THEME_MODE } from "src/interfaces/theme";
import { useTranslation } from "react-i18next";
// import CancelAllOrderModal from '../CancelAllOrderModal';
import {
  BsSearch,
  BsFillXOctagonFill,
  BsChevronUp,
  BsChevronDown,
} from "react-icons/bs";

type Props = {
  isSearch?: boolean;
  dataList: any;
  placeHolder?: any;
  isCancel?: any;
  customSelect?: any;
  className?: any;
  setValueAssetFilter: Dispatch<SetStateAction<string>>;
  isReset: number;
  setIsReset?: Dispatch<SetStateAction<number>>;
  width: number;
  handleFilter?: any;
};

const cx = classnames.bind(styles);

type SelectType = {
  customSelect?: any;
  openSelect?: boolean;
  placeHolder?: any;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  optionSelect?: any;
  theme: any;
  isMobile?: boolean;
  size: number;
  width: number;
};

type FilterActionType = {
  size: number;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  theme: string;
};

type PlaceHolderType = {
  size: number;
  theme: string;
};

type SelectWrapperType = {
  theme: string;
  isMobile?: boolean;
  size: number;
  width: number;
};

const FilterAction = styled.div<FilterActionType>`
  width: 100%;
  color: ${(props) =>
    props.theme === THEME_MODE.LIGHT ? "#ffffff" : "#333333"};
  display: flex;
  & .iconDown {
    path {
      stroke: ${(props) =>
        props.theme === THEME_MODE.LIGHT ? "#333333" : "#ffffff"};
    }
  }
  justify-content: space-between;
`;

const SelectValueWrapper = styled.div<SelectWrapperType>`
  /* background: ${(props) =>
    props.theme === THEME_MODE.LIGHT ? "#f8f8f8" : "#1B1D24"}; */
  border-radius: 24px;
  border: 1px solid
    ${(props) => (props.theme === THEME_MODE.LIGHT ? "#efefef" : "#2d2e2f")};
  padding: 10px;
  width: ${(props) => (props.isMobile || props.size <= 970 ? "auto" : "200px")};
  display: flex;
  font-weight: 400;
  font-size: ${(props) =>
    props.isMobile || props.size <= 970 ? "14px" : "18px"};
  color: ${(props) =>
    props.theme === THEME_MODE.LIGHT ? "#888888" : "#6d7387"};
  cursor: pointer;
  justify-content: space-between;
  user-select: none;
`;

const Wrapper = styled.div`
  display: block;
`;

const PlaceHolder = styled.div<PlaceHolderType>`
  display: block;
  font-size: 14px;
  color: ${(props) =>
    props.theme === THEME_MODE.LIGHT ? "#17181a" : "#ffffff"};
  white-space: nowrap;
  margin-bottom: 6px;
`;

const FilterName = styled.div<PlaceHolderType>`
  width: auto;
  color: ${(props) =>
    props.theme === THEME_MODE.LIGHT ? "#333333" : "#ffffff"};
`;

const FilterIcon = styled.div`
  display: flex;
  align-items: center;
`;

const fn = (text: string, count: number) => {
  return text?.slice(0, count) + (text?.length > count ? "..." : "");
};

const SelectValue = (props: SelectType) => {
  const { t } = useTranslation("common");
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  return (
    <Wrapper>
      <PlaceHolder size={props.size} theme={props.theme}>
        {props.size === 300 ? fn(props.placeHolder, 2) : props.placeHolder}
      </PlaceHolder>
      <SelectValueWrapper
        isMobile={props.isMobile}
        theme={props.theme}
        onClick={() => props.setOpenSelect(!props.openSelect)}
        size={props.size}
        width={props.width}
      >
        {/* <PlaceHolder size={props.size} theme={props.theme}>
        {props.size === 280 ? fn(props.placeHolder, 2) : props.placeHolder}
      </PlaceHolder> */}
        <FilterAction
          size={props.size}
          setOpenSelect={props.setOpenSelect}
          theme={props.theme}
        >
          <FilterName size={props.size} theme={props.theme}>
            <div className={cx("filter-open-order")}>
              {props?.optionSelect?.value === "ALL"
                ? t(props?.optionSelect.name)
                : props.isMobile && props.size != 280
                ? fn(t(props?.optionSelect?.name), 6)
                : props.size === 280
                ? fn(t(props?.optionSelect?.name), 1)
                : props?.optionSelect?.name}
            </div>
          </FilterName>
          <FilterIcon>
            {props.openSelect ? (
              <BsChevronUp
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2 }}
              />
            ) : (
              <BsChevronDown
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                // style={{ zoom: 1.2 }}
              />
            )}
          </FilterIcon>
        </FilterAction>
      </SelectValueWrapper>
    </Wrapper>
  );
};

const SelectValueNoFirstWrapper = styled.div<SelectWrapperType>`
  border-radius: 8px;
  padding: 14px 0px;
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: ${(props) =>
    props.isMobile || props.size <= 970 ? "14px" : "18px"};
  gap: 10px;
  color: ${(props) =>
    props.theme === THEME_MODE.LIGHT ? "#888888" : "#6d7387"};
  cursor: pointer;
  justify-content: flex-end;
  user-select: none;
  margin-left: 22px;
`;

const SelectValueNoFirst = (props: SelectType) => {
  return (
    <SelectValueNoFirstWrapper
      size={props.size}
      theme={props.theme}
      onClick={() => props.setOpenSelect(!props.openSelect)}
      width={props.width}
    >
      <PlaceHolder size={props.size} theme={props.theme}>
        {props.placeHolder}
      </PlaceHolder>
      <FilterIcon theme={props.theme}>
        {/* <IConDownDefault className="iconDown" /> */}
        {IconSVG}
      </FilterIcon>
    </SelectValueNoFirstWrapper>
  );
};

export const SelectFilterAssetOrder: React.FC<Props> = ({
  dataList,
  isSearch,
  placeHolder,
  isCancel,
  customSelect,
  className,
  setValueAssetFilter,
  isReset,
  setIsReset,
  handleFilter,
  width,
}) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { isMobile } = useScreen();
  const [openSelect, setOpenSelect] = useState(false);
  const [optionSelect, setOptionSelect] = useState(dataList[0]);
  const el = useRef(null);
  const [textSearch, setTextSearch] = useState("");
  const [dataSelect, setDataSelect] = useState([]);
  const size = useWindowSize();
  const { t } = useTranslation("common");

  useEffect(() => {
    setDataSelect(dataList);
  }, [dataList]);

  useEffect(() => {
    if (isReset !== 0) {
      setOptionSelect(dataList[0]);
      setValueAssetFilter("");
    }
  }, [dataList, isReset]);

  const handleSearch = (text: string) => {
    setTextSearch(text);

    const resultSearch = dataList?.filter(
      (item: any) =>
        item.name && item.name.toLowerCase().includes(text.toLowerCase())
    );
    setDataSelect(resultSearch);
  };

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const [isOpenCancel, setIsOpenCancel] = useState(false);

  return (
    <div className={cx("select-wrapper", className)} ref={el}>
      {!isCancel ? (
        <SelectValue
          setOpenSelect={setOpenSelect}
          customSelect={customSelect}
          openSelect={openSelect}
          placeHolder={placeHolder}
          optionSelect={optionSelect}
          theme={theme}
          isMobile={isMobile}
          size={size[0]}
          width={width}
        />
      ) : (
        <SelectValueNoFirst
          size={size[0]}
          theme={theme}
          openSelect={openSelect}
          setOpenSelect={setOpenSelect}
          width={width}
        />
      )}

      {openSelect && (
        <div className={cx("select-list")}>
          {isSearch && (
            <div style={{ padding: "15px 15px 0px 15px" }}>
              <div className={cx("select-search")}>
                <input
                  placeholder="Search"
                  value={textSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <BsFillXOctagonFill
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 1.2, cursor: "pointer" }}
                  onClick={() => setTextSearch("")}
                />
                <BsSearch
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 1.2, cursor: "pointer" }}
                />
              </div>
            </div>
          )}

          {dataSelect.length > 0 ? (
            dataSelect?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  {!isCancel ? (
                    <div
                      className={cx(
                        "select-item",
                        item.value === optionSelect?.value &&
                          "select-item-active"
                      )}
                      onClick={() => {
                        setOptionSelect(item);
                        setOpenSelect(false);
                        setValueAssetFilter(item.value);
                        handleFilter();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {item.value === "ALL" ? t(item.name) : item.name}
                    </div>
                  ) : (
                    <>
                      <div
                        className={cx("select-item")}
                        onClick={() => {
                          setIsOpenCancel(true);
                          setOpenSelect(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        {item.name}
                      </div>
                      {/* <CancelAllOrderModal open={isOpenCancel} handleClose={() => setIsOpenCancel(false)} /> */}
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <div className={cx("no-result")}>{t("order.no_data")}</div>
          )}
        </div>
      )}
    </div>
  );
};
