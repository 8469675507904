import React from "react";

const ExitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.7342 6.2749C17.65 6.19052 17.55 6.12358 17.4399 6.0779C17.3298 6.03223 17.2117 6.00872 17.0925 6.00872C16.9733 6.00872 16.8553 6.03223 16.7452 6.0779C16.6351 6.12358 16.535 6.19052 16.4508 6.2749L12 10.7166L7.54916 6.26579C7.4649 6.18153 7.36486 6.11468 7.25476 6.06908C7.14466 6.02347 7.02665 6 6.90748 6C6.78831 6 6.6703 6.02347 6.5602 6.06908C6.4501 6.11468 6.35006 6.18153 6.26579 6.26579C6.18153 6.35006 6.11468 6.4501 6.06908 6.5602C6.02347 6.6703 6 6.78831 6 6.90748C6 7.02665 6.02347 7.14466 6.06908 7.25476C6.11468 7.36486 6.18153 7.4649 6.26579 7.54916L10.7166 12L6.26579 16.4508C6.18153 16.5351 6.11468 16.6351 6.06908 16.7452C6.02347 16.8553 6 16.9733 6 17.0925C6 17.2117 6.02347 17.3297 6.06908 17.4398C6.11468 17.5499 6.18153 17.6499 6.26579 17.7342C6.35006 17.8185 6.4501 17.8853 6.5602 17.9309C6.6703 17.9765 6.78831 18 6.90748 18C7.02665 18 7.14466 17.9765 7.25476 17.9309C7.36486 17.8853 7.4649 17.8185 7.54916 17.7342L12 13.2834L16.4508 17.7342C16.5351 17.8185 16.6351 17.8853 16.7452 17.9309C16.8553 17.9765 16.9733 18 17.0925 18C17.2117 18 17.3297 17.9765 17.4398 17.9309C17.5499 17.8853 17.6499 17.8185 17.7342 17.7342C17.8185 17.6499 17.8853 17.5499 17.9309 17.4398C17.9765 17.3297 18 17.2117 18 17.0925C18 16.9733 17.9765 16.8553 17.9309 16.7452C17.8853 16.6351 17.8185 16.5351 17.7342 16.4508L13.2834 12L17.7342 7.54916C18.0801 7.20329 18.0801 6.62077 17.7342 6.2749Z"
        fill="#8E8E92"
      />
    </svg>
  );
};

export default ExitIcon;
