// import {
//   dashboardIcon,
//   earnIcon,
//   englishIcon,
//   faqIcon,
//   futureIcon,
//   koreaIcon,
//   noticeIcon,
//   overviewIcon,
//   referralIcon,
//   rewardIcon,
//   savingIcon,
//   securityIcon,
//   spotIcon,
//   stakingIcon,
//   transactionIcon,
//   vietnamIcon,
// } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import IconEN from "src/assets/icon/navbar/flag_en.svg";
import IconKO from "src/assets/icon/navbar/flag_ko.svg";
import IconVN from "src/assets/icon/navbar/flag_vi.svg";

export enum TRADE_OPTIONS {
  TRADE = 'Trade',
  SELL = 'Sell',
}

export enum OPTION_FEATURE {
  ACCOUNT = 'account',
  TRADE = 'Trade',
}

export enum EARN_OPTIONS {
  EARN = 'Earn',
  SELL = 'Sell',
}

export enum SUPPORT_OPTIONS {
  SUPPORT = 'Support',
  OTHER = 'Other',
}
export enum WALLET_OPTIONS {
  WALLET = 'Wallet',
  COLD_WALLET = 'Cold Wallet',
}
export const tradeOptions = [
  { value: TRADE_OPTIONS.TRADE, label: TRADE_OPTIONS.TRADE },
  { value: TRADE_OPTIONS.SELL, label: TRADE_OPTIONS.SELL },
];

export const earnOptions = [
  { value: EARN_OPTIONS.EARN, label: EARN_OPTIONS.EARN },
  { value: EARN_OPTIONS.SELL, label: EARN_OPTIONS.SELL },
];

export const languages = [
  { value: "en", label: "English", icon: IconEN },
  // { value: "ko", label: "Korea", icon: IconKO },
  { value: "vi", label: "Vietnam", icon: IconVN },
  { value: "zh", label: "China", icon: IconVN },
  { value: "ja", label: "Japan", icon: IconVN },
  { value: "de", label: "Germany", icon: IconVN },
  { value: "es", label: "Spain", icon: IconVN },
  { value: "fr", label: "France", icon: IconVN },
  { value: "id", label: "Indonesian", icon: IconVN },
];

export const earnOptionsList = [
  {
    icon: Icon,
    label: 'Earn',
  },
  {
    icon: Icon,
    label: 'Saving',
  },
  {
    icon: Icon,
    label: 'Staking',
  },
];

export const supportOptions = [
  {
    icon: Icon,
    label: 'FAQ',
  },
  {
    icon: Icon,
    label: 'Support Center',
  },
];

export const walletOptions = [
  {
    icon: Icon,
    label: 'Overview',
  },
  {
    icon: Icon,
    label: 'Spot',
  },
  {
    icon: Icon,
    label: 'Futures',
  },
  {
    icon: Icon,
    label: 'Transaction History',
  },
];

export const profileOptions = [
  {
    icon: Icon,
    label: 'Dashboard',
  },
  {
    icon: Icon,
    label: 'Security',
  },
  {
    icon: Icon,
    label: 'Referral',
  },
  {
    icon: Icon,
    label: 'Reward Center',
  },
];
