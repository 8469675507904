import TooltipNumber from "src/components/cores/Tooltip";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { Instrument } from "src/services/instrument";
import { ITicker } from "src/services/ticker";

export default function MarketPrice({
  position,
  tickers,
  instruments,
}: {
  position: IPositionItem;
  tickers: ITicker[];
  instruments: Instrument[];
}) {
  const currentTicker = tickers?.find(
    (ticker) => ticker.symbol === position.symbol
  );
  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === position.symbol
  );
  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );

  return (
    <TooltipNumber
      characters={11}
      decimal={decimal}
      defaultValue={currentTicker?.oraclePrice}
    />
  );
}
