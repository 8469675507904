import { useEffect, useState } from "react";
import DropdownMenu from "src/components/cores/Dropdown/Dropdown";
import { StatusType } from "src/features/PositionAndHistory/OrderHistory";
import ItemMobile from "./ItemMobile";
import * as S from "./style";
import { STATUS_ACTION } from "./TableOpenHistory";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { getOrderHistory } from "src/services/order-history";
import { getCookieStorage } from "src/helpers/storage";
import { TypeTrade } from "src/constants/common.constants";
import Loading from "src/components/Loading";

export default function MobileOpenHistory() {
  const instruments = useAppSelector((state) => state.instrument.instrument);

  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const dispatch = useAppDispatch();
  const { ordersHistory, loading } = useAppSelector(
    (state) => state.orderHistory
  );
  const account = useAppSelector((state) => state.account.account);

  const [rows, setRows] = useState<any[]>([]);
  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const [statusType, setStatusType] = useState<string>(StatusType.all);
  const loadingHistory = useAppSelector((state) => state.orderHistory.loading);
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;

  const handleChangeStatusType = (value: string) => {
    setStatusType(value);
  };
  const handleFormatTime = () => {
    const endTime = new Date();
    const startTime = new Date();
    startTime.setMonth(startTime.getMonth() - 3);
    return {
      startTime: startTime.getTime(),
      endTime: endTime.getTime(),
    };
  };
  const fetchData = async (page: number) => {
    const { startTime, endTime } = handleFormatTime();
    const params = { page: page, size: 100 };

    const payload: any = {
      startTime: "0",
      endTime: "0",
      status: "",
      contractType,
    };

    if (startTime) {
      payload.startTime = startTime;
    }
    if (endTime) {
      payload.endTime = endTime;
    }

    if (statusType) {
      payload.status = statusType;
    }
    await dispatch(getOrderHistory({ params, payload }));
  };
  useEffect(() => {
    fetchData(1);
  }, [statusType]);
  useEffect(() => {
    if (ordersHistory) {
      setRows(ordersHistory);
    }
  }, [ordersHistory, account[0]?.id]);
  return (
    <S.MobileStyle>
      <div className="container-des">
        <span className="text-des">
          Show only 100 orders in the past 3 months
        </span>
        <div
          className="action-close-all"
          onClick={(event: React.MouseEvent<HTMLImageElement>) => {
            setRefEml(event.currentTarget);
          }}>
          More
        </div>
      </div>
      <div className="container-list">
        {loadingHistory ? (
          <div
            style={{
              width: "100%",
              height: 30,
              display: "flex",
              justifyContent: "center",
            }}>
            <Loading />
          </div>
        ) : (
          rows.map((item) => (
            <ItemMobile
              key={item.id}
              row={item}
              tradingRulesCache={tradingRulesCache}
              instruments={instruments}
              isCoinM={isCoinM}
            />
          ))
        )}
      </div>
      <DropdownMenu
        open={Boolean(refElm)}
        options={STATUS_ACTION}
        active={statusType}
        handleClose={() => setRefEml(null)}
        refElm={refElm}
        handleChooseOption={handleChangeStatusType}
      />
    </S.MobileStyle>
  );
}
