import classnames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
import Market from "src/features/Market/Market";
import {
  formatPrice,
  getValueClassName,
  formatNumber,
} from "src/helpers/numberFormatter";
import { getTicker24h } from "src/services/ticker";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import ListDark from "src/assets/icon/menu/ListDark.png";
import ListLight from "src/assets/icon/menu/ListLight.png";
import { Instrument } from "src/services/instrument";
import useScreen from "src/hooks/useScreen";
import { useCountdown } from "src/helpers/useCountDown";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useTranslation } from "react-i18next";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";

const listCointUI = [
  { id: 0, name: "BTCUSDT", rate: 2.144, isNew: true },
  { id: 0, name: "SYSUSDT", rate: -1.726, isNew: true },
  { id: 0, name: "SUNUSDT", rate: 3.144, isNew: false },
  { id: 0, name: "VIDTUSDT", rate: 1.325, isNew: true },
  { id: 0, name: "NULSUSDT", rate: -0.644, isNew: false },
  { id: 0, name: "APLACAUSDT", rate: 1.938, isNew: true },
];

const ListCoint: React.FC = () => {
  const currentInstrument: Instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );

  const dispatch = useAppDispatch();
  const instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const ticker = useAppSelector((state) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );
  // console.log("instrument fdsafdas", instrument);
  const tickerLoading = useAppSelector((state) => state.ticker.loading);
  const theme = useAppSelector((state) => state.theme.themeMode);

  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );

  const [needScroll, setNeedScroll] = useState(false);
  const [transformX, setTransformX] = useState(0);
  const [openMarket, setOpenMarket] = useState(false);
  const [fundingRate, setFundingRate] = useState(0);
  const [countDown, setCountDown] = useState(new Date().getTime());

  const contentRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [hours, minutes, seconds] = useCountdown(countDown);
  const { isMobile } = useScreen();
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const { t } = useTranslation("common");

  const setScroll = () => {
    const contentWidth = contentRef.current?.offsetWidth || 0;
    const parentWidth = parentRef.current?.offsetWidth || 0;

    if (contentWidth <= parentWidth) {
      setTransformX(0);
    } else {
      if (transformX != 0) {
        setTransformX(parentWidth - contentWidth);
      }
    }

    setNeedScroll(contentWidth > parentWidth);
  };

  useEffect(() => {
    dispatch(getTicker24h());
  }, []);
  useEffect(() => {
    if (ticker?.fundingRate) {
      setFundingRate(Number(ticker?.fundingRate) * 100);
    }
    if (ticker?.nextFunding && ticker?.nextFunding > new Date().getTime()) {
      setCountDown(ticker?.nextFunding);
    }
  }, [ticker?.fundingRate, ticker?.nextFunding]);

  useEffect(() => {
    setScroll();
  }, [parentRef.current?.offsetWidth]);

  useEffect(() => {
    if (!needScroll) {
      const contentWidth = contentRef.current?.offsetWidth || 0;
      const parentWidth = parentRef.current?.offsetWidth || 0;
      setNeedScroll(contentWidth > parentWidth);
    }
  }, [contentRef.current?.offsetWidth]);

  return (
    <div className={"box_list_coint"}>
      <div className={"icon"}>
        <img
          alt="icon"
          src={theme === THEME_MODE.LIGHT ? ListLight : ListDark}
        />
      </div>
      <div className={"list-icon"}>
        {listCointUI.map((item: any, i: number) => (
          <div key={i} className={"item"}>
            <div className={"name"}>{item.name}</div>
            <div className={`rate ${getValueClassName(item.rate)}`}>
              {formatNumber(item.rate, 2, "")}%
            </div>
            {item.isNew && <div className={"new"}>New</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListCoint;
