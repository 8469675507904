// import { dropIcon, garbageIcon } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";

export const ORDERS_TAB = [
  'order.open_orders',
  'order.order_history',
  'order.trade_history',
  'order.transaction_history',
];
export const ORDERS_TAB_VALUE = ['open-order', 'order-history', 'trade-history', 'transaction-history'];

export const dataOrder = [
  {
    name: 'order.all',
    value: '',
  },
  {
    name: 'order.limit',
    value: 'LIMIT',
  },
  {
    name: 'order.stop_limit',
    value: 'STOP_LIMIT',
  },
  {
    name: 'order.stop_market',
    value: 'STOP_MARKET',
  },
  {
    name: 'order.trailing_stop',
    value: 'TRAILING_STOP',
  },
  {
    name: 'order.take_profit',
    value: 'TAKE_PROFIT',
  },
  // {
  //   name: 'Take Profit Market',
  //   value: 'TAKE_PROFIT_MARKET',
  // },
  {
    name: 'order.stop_loss',
    value: 'STOP_LOSS',
  },
];

export const OPEN_ORDER_TABLE_HEAD = [
  // {
  //   label: '',
  // },
  {
    label: 'order.time',
  },
  {
    label: 'order.symbol',
  },
  {
    label: 'order.type',
  },
  {
    label: 'order.side',
  },
  {
    label: 'order.price',
  },
  {
    label: 'order.amount',
  },
  {
    label: 'order.filled',
  },
  {
    label: 'order.trigger_condition',
  },
  {
    label: 'order.reduceOnly',
  },
  {
    label: 'order.postOnly',
  },
];

export const fakeDataSymbol = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'BTCUSDT',
    value: 'BTCUSDT',
  },
  {
    name: 'ADABTC',
    value: 'ADABTC',
  },
];

export const fakeDataSide = [
  {
    name: 'order.all',
    value: '',
  },
  {
    name: 'order.buy',
    value: 'BUY',
  },
  {
    name: 'order.sell',
    value: 'SELL',
  },
];

export const fakeDataCancel = [
  {
    name: 'order.all',
    value: 'ALL',
  },
  {
    name: 'order.limit_order',
    value: 'LIMIT',
  },
  {
    name: 'order.stop_order',
    value: 'STOP',
  },
];

export const fakeDataType = [
  {
    name: 'order.all',
    value: '',
  },
  {
    name: 'order.limit',
    value: 'LIMIT',
  },
  {
    name: 'order.market',
    value: 'MARKET',
  },
  {
    name: 'order.stop_limit',
    value: 'STOP_LIMIT',
  },
  {
    name: 'order.stop_market',
    value: 'STOP_MARKET',
  },
  {
    name: 'order.trailing_stop',
    value: 'TRAILING_STOP',
  },
  {
    name: 'order.liquidation',
    value: 'LIQUIDATION',
  },
  {
    name: 'order.take_profit',
    value: 'TAKE_PROFIT_MARKET',
  },
  {
    name: 'order.stop_loss',
    value: 'STOP_LOSS_MARKET',
  },
];

export const HISTORY_TABLE_HEAD = [
  {
    label: 'Time',
    icon: '',
  },
  {
    label: 'Symbol',
    icon: '',
  },
  {
    label: 'Type',
    icon: '',
  },
  {
    label: 'Side',
    icon: '',
  },
  {
    label: 'Price',
    icon: '',
  },
  {
    label: 'Executed',
    icon: '',
  },
  {
    label: 'Amount',
    icon: '',
  },
  {
    label: 'Trigger Conditions',
    icon: '',
  },
  {
    label: 'Reduce only',
    icon: '',
  },
  {
    label: 'Post only',
    icon: '',
  },
  {
    label: 'Status',
    icon: Icon,
  },
];

export const STATUS_DATA = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Filled',
    value: '',
  },
  {
    name: 'Partially Filled',
    value: '',
  },
  {
    name: 'Cancelled',
    value: '',
  },
  {
    name: 'Expired',
    value: '',
  },
];

export const TRADE_TABLE_HEAD = [
  {
    label: 'order.time',
    icon: '',
  },
  {
    label: 'order.symbol',
    icon: '',
  },
  {
    label: 'order.side',
    icon: '',
  },
  {
    label: 'order.price',
    icon: '',
  },
  {
    label: 'order.quantity',
    icon: '',
  },
  {
    label: 'order.fee',
    icon: '',
  },
  {
    label: 'order.role',
    icon: '',
  },
  {
    label: 'order.realized_profit',
    icon: '',
  },
];

export const assetSelectTrans = [
  {
    name: 'All',
    value: 'ALL',
  },
  {
    name: 'USDT',
    value: 'USDT',
  },
  {
    name: 'USD',
    value: 'USD',
  },
];

export const typeSelectTrans = [
  {
    name: 'order.all',
    value: 'ALL',
  },
  {
    name: 'order.transfer',
    value: 'TRANSFER',
  },
  {
    name: 'order.realized_pnl',
    value: 'REALIZED_PNL',
  },
  {
    name: 'order.funding_fee',
    value: 'FUNDING_FEE',
  },
  {
    name: 'order.trading_fee',
    value: 'TRADING_FEE',
  },
  {
    name: 'order.liquidation_clearance',
    value: 'LIQUIDATION_CLEARANCE',
  },
];

export const TRANSACTION_TABLE_HEAD = [
  {
    label: 'Time',
    icon: '',
  },
  {
    label: 'Type',
    icon: '',
  },
  {
    label: 'Amount',
    icon: '',
  },
  {
    label: 'Asset',
    icon: '',
  },
  {
    label: 'Symbol',
    icon: '',
  },
];
