import { useTranslation } from "react-i18next";
import * as S from "./style";
import ItemMobile from "./ItemMobile";
import { useEffect, useRef, useState } from "react";
import { IOrder } from "src/interfaces/order";
import { IAccount } from "src/services/account";
import { TypeTrade, RECORDS_PER_PAGE } from "src/constants/common.constants";
import { getContractType } from "src/features/PositionAndHistory/helper";
import {
  getOpenOrder,
  getAllOpenOrder,
  cancelAllOrder,
} from "src/services/order";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import { CancelOrderDialog } from "src/features/PositionAndHistory/dialog/CancelOrderDialog";
import { CancelType } from "src/features/PositionAndHistory/OpenOrder";
import Loading from "src/components/Loading";
import { useInView } from "react-intersection-observer";

export default function MobileOpenOrder({
  hideAssetChecked,
  hideOtherPairsChecked,
  tab,
  account,
  tradingRulesCache,
  handleChange,
}: {
  hideAssetChecked: boolean;
  hideOtherPairsChecked: boolean;
  tab: number;
  account: IAccount[];
  tradingRulesCache: ITradingRuleCache[];
  handleChange: (event: any) => void;
}) {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [openCancelAllDialog, setOpenCancelAllDialog] =
    useState<boolean>(false);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.order.currentPage);
  const totalPage = useAppSelector((state) => state.order.totalPage);
  const [rows, setRows] = useState<IOrder[]>([]);
  const { t } = useTranslation("common");
  const contractType = getContractType();
  const orders = useAppSelector((state) => state.order.orders);
  const isCoinM = contractType === TypeTrade.COIN_M;
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const [rowDisplay, setRowDisplay] = useState(rows);
  const changeCheckboxRef = useRef(hideOtherPairsChecked);
  const changeInstrumentRef = useRef(currentInstrument.symbol);
  const [loading, setLoading] = useState(false);
  const loadingOrder = useAppSelector((state) => state.order.loading);
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  const hasMore = currentPage < totalPage;
  const fetchData = async (page: number) => {
    const params = { page: page, size: RECORDS_PER_PAGE };

    if (hideOtherPairsChecked) {
      const payload = { symbol: currentInstrument.symbol, contractType };
      await dispatch(getOpenOrder({ params, payload }));
    } else {
      const payload = { contractType };
      await dispatch(getOpenOrder({ params, payload }));
      await dispatch(getAllOpenOrder(contractType));
    }
  };
  useEffect(() => {
    setLoading(loadingInstrument || loadingTradingRules);
  }, [loadingInstrument, loadingTradingRules]);

  useEffect(() => {
    if (currentPage) {
      if (
        changeCheckboxRef.current === hideOtherPairsChecked &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(1);
      } else {
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairsChecked;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [hideOtherPairsChecked, currentInstrument]);
  useEffect(() => {
    if (rows && rows.length > 0) {
      setRowDisplay((prevDisplay) => {
        const updatedDisplay = [...prevDisplay];

        rows.forEach((newRow) => {
          const existingIndex = updatedDisplay.findIndex(
            (item) => item.id === newRow.id
          );
          if (existingIndex !== -1) {
            // If the item exists, update it
            updatedDisplay[existingIndex] = newRow;
          } else {
            // If it's a new item, append it
            updatedDisplay.push(newRow);
          }
        });

        return updatedDisplay;
      });
    } else {
      setRowDisplay([]);
    }
  }, [rows]);
  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page]);
  useEffect(() => {
    if (orders) {
      setRows(orders);
    }
  }, [orders, account[0]?.id]);

  useEffect(() => {
    if (inView && hasMore && !loadingOrder) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, hasMore, loadingOrder]);

  const handleCloseCancelAllDialog = () => {
    setOpenCancelAllDialog(false);
  };
  const handleOpenCancelAllDialog = () => {
    setOpenCancelAllDialog(true);
  };
  const handleCancelAllOrder = async () => {
    handleCloseCancelAllDialog();
    await dispatch(cancelAllOrder({ type: "ALL", contractType }));
  };

  // Clean up
  useEffect(() => {
    return () => {
      fetchData(1);
    };
  }, []);
  console.log("rowDisplay", rowDisplay);
  return (
    <S.MobileStyle>
      <div className="container-header">
        <div className={"hidden-btn"}>
          <label className={"container"}>
            <input
              type="checkbox"
              checked={tab === 5 ? hideAssetChecked : hideOtherPairsChecked}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
          <span className={"hidden-text"}>
            {tab === 5 ? t("order.hide_asset") : t("order.hide_other_pair")}
          </span>
        </div>
        <div
          className="action-close-all"
          onClick={() => {
            handleOpenCancelAllDialog();
          }}>
          Cancel all
        </div>
      </div>
      <div className="container-list">
        {rowDisplay.length > 0 &&
          rowDisplay.map((item) => (
            <ItemMobile
              key={item.id}
              row={item}
              isCoinM={isCoinM}
              tradingRulesCache={tradingRulesCache}
            />
          ))}
      </div>
      {loadingOrder ? <Loading /> : <div ref={ref} />}
      {orders.length > 0 && (
        <CancelOrderDialog
          cancelType={CancelType.all}
          handleCloseDialog={handleCloseCancelAllDialog}
          handleConfirm={handleCancelAllOrder}
          openDialog={openCancelAllDialog}
        />
      )}
    </S.MobileStyle>
  );
}
