import { useTranslation } from "react-i18next";
import * as S from "../PositionHistory/style";
import ThHead from "../PositionHistory/thHead";
import { useState } from "react";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import DropdownMenu from "src/components/cores/Dropdown/Dropdown";
import { StatusType } from "src/features/PositionAndHistory/OrderHistory";
import { Instrument } from "src/services/instrument";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import Time from "./Time";
import { IOrder } from "src/interfaces/order";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import RenderTypeOrder from "../OpenOrder/RenderTypeOrder";
import RenderAverage from "./RenderAverage";
import Price from "../OpenOrder/Price";
import {
  OrderSide,
  OrderStatusText,
  OrderType,
} from "src/features/PositionAndHistory/constant";
import RenderFilledQuantity from "./RenderFilledQuantity";
import RenderAmount from "./RenderAmount";
import RenderTriggerCondition from "../OpenOrder/RenderTriggerCondition";
import RenderReduceOnly from "../OpenOrder/RenderReduceOnly";

export enum StatusLabel {
  all = "order.all",
  filled = "order.filled",
  partiallyFilled = "order.partially_filled",
  cancelled = "order.cancelled",
}

export const STATUS_ACTION = [
  { value: StatusType.all, label: StatusLabel.all },
  { value: StatusType.filled, label: StatusLabel.filled },
  { value: StatusType.partiallyFilled, label: StatusLabel.partiallyFilled },
  { value: StatusType.cancelled, label: StatusLabel.cancelled },
];

export default function TableOpenHistory({
  rows,
  loading,
  tradingRulesCache,
  isCoinM,
  instruments,
}: {
  loading: boolean;
  hideOtherPairs: boolean;
  rows: IOrder[];
  currentInstrument: Instrument;
  tradingRulesCache: ITradingRuleCache[];
  instruments: Instrument[];
  isCoinM: boolean;
}) {
  const { t } = useTranslation("common");
  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [statusType, setStatusType] = useState<string>(StatusType.all);
  const handleChangeStatusType = (value: string) => {
    setStatusType(value);
  };
  return (
    <S.TableStyle>
      <table className={"dex_table text-table-xs"}>
        <thead>
          <tr className={"table-head"}>
            <ThHead text={t("order.time")} style={{ paddingLeft: "0" }} />
            <ThHead text={t("order.symbol")} />
            <ThHead text={t("order.type")} />
            <ThHead text={t("order.side")} />
            <ThHead text={t("order.average")} />
            <ThHead text={t("order.price")} />
            <ThHead text={t("order.executed")} />
            <ThHead text={t("order.amount")} />
            <ThHead text={t("order.trigger_condition")} />
            <ThHead text={t("order.reduceOnly")} />
            <ThHead text={t("order.postOnly")} />
            <th>
              <div
                className={"type-cancel-order"}
                onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  setRefEml(event.currentTarget);
                }}>
                <span>{t("order.status")}</span>
                {Boolean(refElm) ? (
                  <BsArrowDownCircle
                    fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                  />
                ) : (
                  <BsArrowUpCircle
                    fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                  />
                )}
              </div>
            </th>
            <DropdownMenu
              open={Boolean(refElm)}
              options={STATUS_ACTION}
              active={statusType}
              handleClose={() => setRefEml(null)}
              refElm={refElm}
              handleChooseOption={handleChangeStatusType}
            />
          </tr>
        </thead>
        <tbody
          className={"dex_table__scrollbar overflow-tbody"}
          style={{
            maxHeight: 210,
            verticalAlign: "top",
            marginTop: 16,
          }}>
          {loading && <div style={{ height: 230 }}></div>}
          {!loading && !rows.length && <div style={{ height: 230 }}></div>}
          {!loading && !!rows.length && (
            <>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Time time={row.updatedAt} />
                  </td>
                  <td>
                    <div className={"text-normal"}>
                      {getSymbolName(row.symbol ?? "")}
                    </div>
                    <div className={"text-perpetual"}>
                      {t("order.perpetual")}
                    </div>
                  </td>
                  <td className={"text-normal"}>
                    <RenderTypeOrder order={row} />
                  </td>
                  <td
                    className={`${
                      row.side === OrderSide.buy ? "color-buy" : "color-sell"
                    }`}>
                    {t(formatOrderText(row.side))}
                  </td>
                  <td className={"text-normal"}>
                    <RenderAverage
                      data={row}
                      tradingRulesCache={tradingRulesCache}
                    />
                  </td>
                  <td className={"text-normal"}>
                    {row.type === OrderType.limit ? (
                      <Price
                        position={row}
                        tradingRulesCache={tradingRulesCache}
                        currentPrice={row.price ?? "0"}
                      />
                    ) : (
                      "--"
                    )}
                  </td>
                  <td className={"text-normal"}>
                    <RenderFilledQuantity
                      order={row}
                      instruments={instruments}
                      isCoinM={isCoinM}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <RenderAmount
                      instruments={instruments}
                      isCoinM={isCoinM}
                      order={row}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <RenderTriggerCondition
                      order={row}
                      instruments={instruments}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <RenderReduceOnly order={row} />
                  </td>
                  <td className={"text-normal"}>
                    {row?.isPostOnly ? t("order.yes") : t("order.no")}
                  </td>
                  <td className={"text-normal"}>
                    {t(
                      formatOrderText(
                        Object(OrderStatusText)[row?.status ?? "PENDING"]
                      )
                    )}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </S.TableStyle>
  );
}
