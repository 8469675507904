import TextNumber from "src/components/cores/TextNumber";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";

export default function PNL({
  position,
  currentPNL,
}: {
  position: IPositionItem;
  currentPNL: string;
}) {
  return (
    <TextNumber
      defaultValue={currentPNL}
      symbol={position?.asset}
      isCoinM={false}
    />
  );
}
