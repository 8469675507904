import { useTranslation } from "react-i18next";
import * as S from "../PositionHistory/style";
import ThHead from "../PositionHistory/thHead";
import { IFill } from "src/interfaces/fill";
import { formatTime } from "src/helpers/stringFormatter";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import { TradeSide } from "src/constants/common.constants";
import Price from "./Price";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import Quantity from "./Quantity";
import { Instrument } from "src/services/instrument";
import Fee from "./Fee";
import RealizedProfit from "./RealizedProfit";

export default function TableTradeHistory({
  loading,
  rows,
  tradingRulesCache,
  instruments,
  isCoinM,
}: {
  loading: boolean;
  rows: IFill[];
  tradingRulesCache: ITradingRuleCache[];
  instruments: Instrument[];
  isCoinM: boolean;
}) {
  const { t } = useTranslation("common");
  return (
    <S.TableStyle>
      <table className={"dex_table text-table-xs"}>
        <thead>
          <tr className={"table-head"}>
            <ThHead text={t("order.time")} />
            <ThHead text={t("order.symbol")} />
            <ThHead text={t("order.side")} />
            <ThHead text={t("order.price")} />
            <ThHead text={t("order.quantity")} />
            <ThHead text={t("order.fee")} />
            <ThHead text={t("order.role")} />
            <ThHead text={t("order.realized_profit")} />
          </tr>
        </thead>
        <tbody
          className={"dex_table__scrollbar overflow-tbody"}
          style={{
            maxHeight: 210,
            verticalAlign: "top",
            marginTop: 16,
          }}>
          {loading && <div style={{ height: 230 }}></div>}
          {!loading && !rows.length && <div style={{ height: 230 }}></div>}
          {!loading && !!rows.length && (
            <>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className={"text-normal"}>{formatTime(row.createdAt)}</td>
                  <td>
                    <div className={"text-normal"}>
                      {getSymbolName(row.symbol ?? "")}
                    </div>
                    <div className={"text-perpetual"}>
                      {t("order.perpetual")}
                    </div>
                  </td>
                  <td
                    className={`${
                      row?.tradeSide === TradeSide.BUY
                        ? "color-buy"
                        : "color-sell"
                    }`}>
                    {t(formatOrderText(row.tradeSide))}
                  </td>
                  <td className="text-normal">
                    <Price
                      fill={row}
                      currentPrice={row.price ?? "0"}
                      tradingRulesCache={tradingRulesCache}
                    />
                  </td>
                  <td className="text-normal">
                    <Quantity
                      fill={row}
                      instruments={instruments}
                      isCoinM={isCoinM}
                      tradingRulesCache={tradingRulesCache}
                    />
                  </td>
                  <td className="text-normal">
                    <Fee
                      fill={row}
                      instruments={instruments}
                      isCoinM={isCoinM}
                    />
                  </td>
                  <td className="text-normal">
                    {t(formatOrderText(row.liquidity))}
                  </td>
                  <td className="text-normal">
                    <RealizedProfit
                      fill={row}
                      instruments={instruments}
                      isCoinM={isCoinM}
                    />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </S.TableStyle>
  );
}
