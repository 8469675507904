import { useContext } from "react";
import { ChartContext } from "src/features/TradingViewChart/Components/Chart/components/ChartIndex";
// import { ChartContext } from "src/features/TradingViewChart/Components/Chart/ChartProvider";

export const useChart = () => {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error("useChart must be used within a ChartProvider");
  }
  return context;
};
