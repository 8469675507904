import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.chart.bgContainer};
  padding: 5px 5px 10px;
  border-radius: 8px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Wrap = styled(Flex)`
  padding: 6px 16px;
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.chart.bgContainer};
`;

export const WrapLeft = styled(Flex)`
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
  .left-action {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapRight = styled(Flex)`
  gap: 16px;
  justify-content: flex-end;
  .full-screen {
    cursor: pointer;
  }
`;

export const bgLine = styled.div`
  width: 1px;
  height: 24px;
  background: ${({ theme }) => theme.chart.bgLine};
`;

export const ChartContent = styled.div`
  flex: 1;
  height: calc(100% - 40px);
  width: 100%;
  min-width: 295px;
  background-color: ${({ theme }) => theme.chart.bgContainer};
  margin-top: 2px;
`;
