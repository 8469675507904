import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import { iconCloseCommon, iconCloseCommonDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { CInput } from 'src/components/cores/Input';
import { SelectInput } from 'src/components/cores/SelectInput';
import { formatPriceRoundDown, isNumber } from 'src/helpers/numberFormatter';
import BigNumber from 'bignumber.js';
import { IPosition } from 'src/services/position';
import { Instrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';
import { OrderSide, OrderType } from '../../constant';
import { getPositionDirection } from '../../helper';
import { getNumberRegex } from 'src/features/Market/components/OrderForm';
import { formatValidPrice } from 'src/features/Market/MarketHelper';
import useIsMobile from 'src/hooks/useIsMobile';
import { MOBILE_DIMENSION } from 'src/constants/common.constants';
import { SelectTPSL } from 'src/components/cores/SelectTPSL';
import { THEME_MODE } from 'src/interfaces/theme';
import axiosInstance from 'src/helpers/config';
import { noti } from 'src/hooks/useNoti';
import { successMessage } from 'src/messages/successMessages';
import { IOrder } from 'src/interfaces/order';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { useTranslation } from 'react-i18next';
import { USDT } from 'src/features/Market/Constant';
import { useWindowSize } from 'src/hooks/useScreen';
import Loading from 'src/components/Loading';
import { BsXOctagonFill } from "react-icons/bs";

export enum PriceTypeValue {
  last = 'LAST',
  mark = 'ORACLE',
}

export enum PriceTypeLabel {
  last = 'order.last',
  mark = 'order.mark',
}

export enum CurrencyTypeValue {
  usdt = 'USDT',
  roe = 'ROE',
}

export enum CurrencyTypeLabel {
  usdt = 'USDT',
  usd = 'USD',
  roe = '%ROE',
}

export const ERROR = {
  ERROR_TRIGGER_HIGHER_PRICE_USDT: (label: string) => `Trigger Price should be higher than ${label} Price`,
  ERROR_TRIGGER_LOWER_PRICE_USDT: (label: string) => `Trigger Price should be lower than ${label} Price`,
  // ERROR_TRIGGER_GREATER_ROE: (percent: string) => `The ROE% must be greater than -${percent}%`,
  // ERROR_TRIGGER_LESS_ROE: (percent: string) => `The ROE% must be less than ${percent}%`,
};

interface EntierPositionProps {
  data: IPosition | undefined;
  handleCloseDialog: () => void;
  openDialog: boolean;
  listPosition: IPosition[];
}

const cx = classnames.bind(styles);

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '725px',
      maxWidth: '725px',
      background: 'var(--color-background-modal)',
      borderRadius: '8px',
    },
    '& .MuiDialogTitle-root': {
      padding: '23px 27px 0px 50px',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 50px',
    },
    '& .MuiDialogActions-root': {
      padding: '0px 40px 40px 40px',
    },
    '& .MuiTypography-root': {
      fontFamily: 'inherit',
    },
  },
});

const useStylesMobile = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '500px',
      background: 'var(--color-background-modal)',
      borderRadius: '8px',
      minWidth: '343px',
    },
    '& .MuiDialogTitle-root': {
      padding: '20px 15px 0px 15px',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 16px',
    },
    '& .MuiDialogActions-root': {
      padding: '0px 15px 15px 15px',
    },
  },
});

const EntierPositionDialog: React.FC<EntierPositionProps> = ({ data, openDialog, handleCloseDialog, listPosition }) => {
  const instruments: Instrument[] = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = instruments?.find((instrument) => instrument.symbol === data?.symbol);
  const { t } = useTranslation('common');

  const SELECT_PRICE_TYPE = [
    { value: PriceTypeValue.last, label: t(PriceTypeLabel.last) },
    { value: PriceTypeValue.mark, label: t(PriceTypeLabel.mark) },
  ];

  const SELECT_CURRENCY_TYPE = [
    {
      value: CurrencyTypeValue.usdt,
      label: currentInstrument?.quoteCurrency === USDT ? CurrencyTypeLabel.usdt : CurrencyTypeLabel.usd,
    },
    { value: CurrencyTypeValue.roe, label: CurrencyTypeLabel.roe },
  ];

  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const size = useWindowSize();
  const tickers = useAppSelector((state) => state.ticker.tickers);
  const theme = useAppSelector((state) => state.theme.themeMode);
  const currentTicker = tickers?.find((ticker) => ticker.symbol === data?.symbol);

  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === currentInstrument?.symbol);
  const minPrice = curTradingRule[0]?.minPrice;
  const maxPrice = curTradingRule[0]?.maxPrice;
  const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

  const [takeProfitPrice, setTakeProfitPrice] = useState<string>('');
  const [stopLossPrice, setStopLossPrice] = useState<string>('');
  const [showTPInput, setShowTPInput] = useState<boolean>(true);
  const [showSLInput, setShowSLInput] = useState<boolean>(true);

  const [currencyTypeTP, setCurrencyTypeTP] = useState<CurrencyTypeValue>(SELECT_CURRENCY_TYPE[0].value);
  const [currencyTypeSL, setCurrencyTypeSL] = useState<CurrencyTypeValue>(SELECT_CURRENCY_TYPE[0].value);

  const [priceTypeTP, setPriceTypeTP] = useState<PriceTypeValue>(SELECT_PRICE_TYPE[0].value);
  const [priceTypeSL, setPriceTypeSL] = useState<PriceTypeValue>(SELECT_PRICE_TYPE[0].value);

  const [messageErrorTP, setMessageErrorTP] = useState<string>('');
  const [messageErrorSL, setMessageErrorSL] = useState<string>('');

  const [disableBtnCancelTP, setDisableBtnCancelTP] = useState<boolean>(false);
  const [disableBtnCancelSL, setDisableBtnCancelSL] = useState<boolean>(false);
  const [disableBtnConfirm, setDisableBtnConfirm] = useState<boolean>(false);

  const [position, setPosition] = useState<any>({});
  const [takeProfitOrder, setTakeProfitOrder] = useState<any>({});
  const [stopLossOrder, setStopLossOrder] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const markPrice = currentTicker?.oraclePrice ?? '0';
  const lastPrice = currentTicker?.lastPrice ?? '0';

  const priceRegex = getNumberRegex(Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO);
  const roeRegex = getNumberRegex(DECIMAL_TWO);

  // const maxROEPercent = () => {
  //   const leverage = Number(position?.leverage).toFixed();
  //   const percent = new BigNumber(leverage).times(100).toString();
  //   return percent;
  // };

  const convertPrice = (percent: string, type: string) => {
    if (!percent) return '0.00';
    const entryPrice = Number(position?.entryPrice);
    const leverage = position?.leverage ? Number(position?.leverage).toFixed() : 0;

    if (position?.side === OrderSide.buy) {
      const takeProfitPrice = new BigNumber(entryPrice).times(
        new BigNumber(1).plus(new BigNumber(percent).dividedBy(100).dividedBy(leverage)),
      );
      const stopLossPrice = new BigNumber(entryPrice).times(
        new BigNumber(1).minus(new BigNumber(percent).dividedBy(100).dividedBy(leverage)),
      );
      if (type === 'take-profit') {
        return takeProfitPrice.lte(0) ? '0.00' : takeProfitPrice.toString();
      } else {
        return stopLossPrice.lte(0) ? '0.00' : stopLossPrice.toString();
      }
    } else {
      const takeProfitPrice = new BigNumber(entryPrice).times(
        new BigNumber(1).minus(new BigNumber(percent).dividedBy(100).dividedBy(leverage)),
      );
      const stopLossPrice = new BigNumber(entryPrice).times(
        new BigNumber(1).plus(new BigNumber(percent).dividedBy(100).dividedBy(leverage)),
      );
      if (type === 'take-profit') {
        return takeProfitPrice.lte(0) ? '0.00' : takeProfitPrice.toString();
      } else {
        return stopLossPrice.lte(0) ? '0.00' : stopLossPrice.toString();
      }
    }
  };

  const validateTakeProfitPrice = () => {
    const takeProfitValue =
      takeProfitPrice && currencyTypeTP === CurrencyTypeValue.roe
        ? convertPrice(takeProfitPrice, 'take-profit')
        : takeProfitPrice;
    if (position?.side === OrderSide.buy) {
      if (new BigNumber(takeProfitValue).lte(position?.entryPrice)) {
        setMessageErrorTP(t('order.noti.min_trigger_price_entry'));
      } else if (new BigNumber(takeProfitValue).lt(minPrice)) {
        setMessageErrorTP(
          t('order.noti.min_take_profit', {
            number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
          }),
        );
      } else if (new BigNumber(takeProfitValue).gt(maxPrice)) {
        setMessageErrorTP(
          t('order.noti.max_take_profit', {
            number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
          }),
        );
      } else {
        setMessageErrorTP('');
      }
    } else if (position?.side === OrderSide.sell) {
      if (new BigNumber(takeProfitValue).gte(position?.entryPrice)) {
        setMessageErrorTP(t('order.noti.max_trigger_price_entry'));
      } else if (new BigNumber(takeProfitValue).lt(minPrice)) {
        setMessageErrorTP(
          t('order.noti.min_take_profit', {
            number: Number(minPrice).toFixed(Number(maxFiguresForPrice)),
          }),
        );
      } else if (new BigNumber(takeProfitValue).gt(maxPrice)) {
        setMessageErrorTP(
          t('order.noti.max_take_profit', {
            number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)),
          }),
        );
      } else {
        setMessageErrorTP('');
      }
    }
  };

  const validateStopLossPrice = () => {
    const stopLossValue =
      stopLossPrice && currencyTypeSL === CurrencyTypeValue.roe
        ? convertPrice(stopLossPrice, 'stop-loss')
        : stopLossPrice;
    if (position?.side === OrderSide.buy) {
      if (new BigNumber(stopLossValue).gte(position?.entryPrice)) {
        setMessageErrorSL(t('order.noti.max_trigger_price_entry'));
      } else if (new BigNumber(stopLossValue).lt(minPrice)) {
        setMessageErrorSL(
          t('order.noti.min_stop_loss', { number: Number(minPrice).toFixed(Number(maxFiguresForPrice)) }),
        );
      } else if (new BigNumber(stopLossValue).gt(maxPrice)) {
        setMessageErrorSL(
          t('order.noti.max_stop_loss', { number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)) }),
        );
      } else {
        setMessageErrorSL('');
      }
    } else if (position?.side === OrderSide.sell) {
      if (new BigNumber(stopLossValue).lte(position?.entryPrice)) {
        setMessageErrorSL(t('order.noti.min_trigger_price_entry'));
      } else if (new BigNumber(stopLossValue).lt(minPrice)) {
        setMessageErrorSL(
          t('order.noti.min_stop_loss', { number: Number(minPrice).toFixed(Number(maxFiguresForPrice)) }),
        );
      } else if (new BigNumber(stopLossValue).gt(maxPrice)) {
        setMessageErrorSL(
          t('order.noti.max_stop_loss', { number: Number(maxPrice).toFixed(Number(maxFiguresForPrice)) }),
        );
      } else {
        setMessageErrorSL('');
      }
    }
  };

  useEffect(() => {
    if (!data?.id || !openDialog) return;
    (async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/positions/update-position/${data.id}`);
        setLoading(false);
        if (response.data) {
          const findTakeProfitOrder = response?.data?.find(
            (order: IOrder) => order.tpSLType === OrderType.takeProfitMarket,
          );
          const findStopLossOrder = response?.data?.find((order: IOrder) => order.tpSLType === OrderType.stopMarket);
          if (findTakeProfitOrder) {
            setTakeProfitOrder(findTakeProfitOrder);
            setTakeProfitPrice(findTakeProfitOrder.tpSLPrice);
            setPriceTypeTP(findTakeProfitOrder.trigger);
            setShowTPInput(false);
          }
          if (findStopLossOrder) {
            setStopLossOrder(findStopLossOrder);
            setStopLossPrice(findStopLossOrder.tpSLPrice);
            setPriceTypeSL(findStopLossOrder.trigger);
            setShowSLInput(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [data, openDialog]);

  useEffect(() => {
    const newPosition = { ...data, quantity: new BigNumber(data ? data.currentQty : '0').abs().toString() };
    switch (getPositionDirection(data)) {
      case 'Long':
        setPosition({ ...newPosition, side: OrderSide.buy });
        break;
      case 'Short':
        setPosition({ ...newPosition, side: OrderSide.sell });
        break;
      default:
        break;
    }
  }, [data]);

  useEffect(() => {
    validateTakeProfitPrice();
  }, [takeProfitPrice]);

  useEffect(() => {
    validateStopLossPrice();
  }, [stopLossPrice]);

  useEffect(() => {
    const findPosition = listPosition?.find((item) => Number(item?.id) === Number(position?.id));
    if (findPosition) {
      const isCancelTakeProfitOrder = findPosition?.orders?.some(
        (item) => Number(item?.id) === Number(position?.takeProfitOrderId),
      );
      const isCancelStopLossOrder = findPosition?.orders?.some(
        (item) => Number(item?.id) === Number(position?.stopLossOrderId),
      );
      if (!isCancelTakeProfitOrder && showTPInput === false) {
        setShowTPInput(true);
        setTakeProfitPrice('');
        setCurrencyTypeTP(SELECT_CURRENCY_TYPE[0].value);
        setPriceTypeTP(SELECT_PRICE_TYPE[0].value);
      }
      if (!isCancelStopLossOrder && showSLInput === false) {
        setShowSLInput(true);
        setStopLossPrice('');
        setCurrencyTypeSL(SELECT_CURRENCY_TYPE[0].value);
        setPriceTypeSL(SELECT_PRICE_TYPE[0].value);
      }
    }
  }, [listPosition, position]);

  const handleChangeTakeProfitPrice = (value: string) => {
    if (currencyTypeTP === CurrencyTypeValue.usdt) {
      if (!isNumber(value) && value) {
        return;
      }

      value = value?.trim().replace(/^0+\B/, '');
      const s = value?.split('.');
      const beforeDecimal = s[0];
      const decimal = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
      if (beforeDecimal.length > 8 || (s[1] && s[1].length > decimal)) {
        return;
      }

      if (priceRegex.test(value)) {
        setTakeProfitPrice(value);
      } else {
        if (!currentInstrument) return;
        const price = formatValidPrice(value, currentInstrument);
        setTakeProfitPrice(price);
      }
    }

    if (currencyTypeTP === CurrencyTypeValue.roe) {
      if (!isNumber(value) && value) {
        return;
      }

      value = value?.trim().replace(/^0+\B/, '');
      const s = value?.split('.');
      const beforeDecimal = s[0];
      const decimal = DECIMAL_TWO;
      if (beforeDecimal.length > 5 || (s[1] && s[1].length > decimal)) {
        return;
      }

      if (roeRegex.test(value)) {
        setTakeProfitPrice(value);
      } else {
        if (!currentInstrument) return;
        const price = formatValidPrice(value, currentInstrument);
        setTakeProfitPrice(price);
      }
    }
  };

  const handleChangeStopLossPrice = (value: string) => {
    if (value === '-' || value === '') {
      value = '';
    } else {
      value = value.includes('-') ? value?.split('-')[1] : value;
    }

    if (currencyTypeSL === CurrencyTypeValue.usdt) {
      if (!isNumber(value) && value) {
        return;
      }

      value = value?.trim().replace(/^0+\B/, '');
      const s = value?.split('.');
      const beforeDecimal = s[0];
      const decimal = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
      if (beforeDecimal.length > 8 || (s[1] && s[1].length > decimal)) {
        return;
      }

      if (priceRegex.test(value)) {
        setStopLossPrice(value);
      } else {
        if (!currentInstrument) return;
        const price = formatValidPrice(value, currentInstrument);
        setStopLossPrice(price);
      }
    }

    if (currencyTypeSL === CurrencyTypeValue.roe) {
      if (!isNumber(value) && value) {
        return;
      }

      value = value?.trim().replace(/^0+\B/, '');
      const s = value?.split('.');
      const beforeDecimal = s[0];
      const decimal = DECIMAL_TWO;
      if (beforeDecimal.length > 5 || (s[1] && s[1].length > decimal)) {
        return;
      }

      if (roeRegex.test(value)) {
        setStopLossPrice(value);
      } else {
        if (!currentInstrument) return;
        const price = formatValidPrice(value, currentInstrument);
        setStopLossPrice(price);
      }
    }
  };

  const handleChangeCurrency = (type: string, value: CurrencyTypeValue) => {
    if (type === 'take-profit') {
      setCurrencyTypeTP(value);
      setTakeProfitPrice('');
      setMessageErrorTP('');
    } else {
      setCurrencyTypeSL(value);
      setStopLossPrice('');
      setMessageErrorSL('');
    }
  };

  const handleChangePriceType = (type: string, value: PriceTypeValue) => {
    if (type === 'take-profit') {
      setPriceTypeTP(value);
    } else {
      setPriceTypeSL(value);
    }
  };

  const handleCancelTakeProfit = async () => {
    try {
      const payload = {
        positionId: +position.id,
        takeProfitOrderId: position.takeProfitOrderId,
      };
      setDisableBtnCancelTP(true);
      const response: any = await axiosInstance.delete('positions/update-position', { params: payload });
      setDisableBtnCancelTP(false);
      if (response.status === 400) {
        noti.error({
          title: response?.data?.info?.message,
        });
        setShowTPInput(false);
      }
    } catch (error) {
      setDisableBtnCancelTP(false);
      console.log(error);
    }
  };

  const handleCancelStopLoss = async () => {
    try {
      const payload = {
        positionId: +position.id,
        stopLossOrderId: position.stopLossOrderId,
      };
      setDisableBtnCancelSL(true);
      const response: any = await axiosInstance.delete('positions/update-position', { params: payload });
      setDisableBtnCancelSL(false);
      if (response.status === 400) {
        noti.error({
          title: response?.data?.info?.message,
        });
        setShowSLInput(false);
      }
    } catch (error) {
      setDisableBtnCancelSL(false);
      console.log(error);
    }
  };

  const resetState = () => {
    setCurrencyTypeTP(SELECT_CURRENCY_TYPE[0].value);
    setCurrencyTypeSL(SELECT_CURRENCY_TYPE[0].value);
    setPriceTypeTP(SELECT_PRICE_TYPE[0].value);
    setPriceTypeSL(SELECT_PRICE_TYPE[0].value);
    setTakeProfitPrice('');
    setStopLossPrice('');
    setMessageErrorTP('');
    setMessageErrorSL('');
    setShowTPInput(true);
    setShowSLInput(true);
  };

  const handleConfirmEntierPosition = async () => {
    handleCloseDialog();
    if (messageErrorTP || messageErrorSL) {
      return;
    }

    try {
      if (!takeProfitPrice && !stopLossPrice) return;

      const payload = {
        positionId: +position.id,
        takeProfit:
          currencyTypeTP === CurrencyTypeValue.roe ? convertPrice(takeProfitPrice, 'take-profit') : takeProfitPrice,
        stopLoss: currencyTypeSL === CurrencyTypeValue.roe ? convertPrice(stopLossPrice, 'stop-loss') : stopLossPrice,
        takeProfitTrigger: takeProfitPrice ? priceTypeTP : '',
        stopLossTrigger: stopLossPrice ? priceTypeSL : '',
      };

      setDisableBtnConfirm(true);
      const response: any = await axiosInstance.put('positions/update-position', payload);
      setDisableBtnConfirm(false);
      if (response?.code === 200) {
        noti.success({
          title: t('order.noti.order_submitted'),
        });
      }
      if (response.status === 400) {
        noti.error({
          title: response?.data?.info?.message,
        });
      }
    } catch (error) {
      setDisableBtnConfirm(false);
      console.log(error);
    }

    resetState();
  };

  const renderTriggerConditions = (type: string) => {
    const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === position?.symbol);
    const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    if (type === 'take-profit') {
      if (priceTypeTP === PriceTypeValue.last) {
        return (
          <div className={cx('flex')}>
            {t('order.last_price')} {takeProfitOrder.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber
              defaultValue={takeProfitPrice}
              characters={10}
              decimal={maxFiguresForPrice}
              symbol={currentInstrument?.quoteCurrency}
            />
          </div>
        );
      } else {
        return (
          <div className={cx('flex')}>
            {t('order.mark_price')} {takeProfitOrder.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber
              defaultValue={takeProfitPrice}
              characters={10}
              decimal={maxFiguresForPrice}
              symbol={currentInstrument?.quoteCurrency}
            />
          </div>
        );
      }
    } else if (type === 'stop-loss') {
      if (priceTypeSL === PriceTypeValue.last) {
        return (
          <div className={cx('flex')}>
            {t('order.last_price')} {stopLossOrder.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber
              defaultValue={stopLossPrice}
              characters={10}
              decimal={maxFiguresForPrice}
              symbol={currentInstrument?.quoteCurrency}
            />
          </div>
        );
      } else {
        return (
          <div className={cx('flex')}>
            {t('order.mark_price')} {stopLossOrder.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber
              defaultValue={stopLossPrice}
              characters={10}
              decimal={maxFiguresForPrice}
              symbol={currentInstrument?.quoteCurrency}
            />
          </div>
        );
      }
    }
  };

  const showLabelTakeProfit = () => {
    if ((size[0] <= 560 && !takeProfitPrice) || size[0] > 560) {
      return true;
    } else {
      return false;
    }
  };

  const showLabelStopLoss = () => {
    if ((size[0] <= 560 && !stopLossPrice) || size[0] > 560) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      open={openDialog}
      className={!isMobile ? classes.root : classesMobile.root}
      onClose={() => {
        handleCloseDialog();
        resetState();
      }}
    >
      {loading ? (
        <div style={{ width: '100%', height: '600px' }}>
          <Loading />
        </div>
      ) : (
        <>
          <DialogTitle className={cx('wrapper-title')}>
            <Box display="flex" justifyContent="flex-end">
              <Typography>
                <IconButton
                  size={'small'}
                  onClick={() => {
                    handleCloseDialog();
                    resetState();
                  }}
                >
                  {/* <img src={theme === THEME_MODE.LIGHT ? Icon : Icon} alt="close" /> */}
                  <BsXOctagonFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  />
                </IconButton>
              </Typography>
            </Box>
            <Box>
              <Typography className={cx('dialog-title')}>{t('order.tpsl_entire_position')}</Typography>
            </Box>
          </DialogTitle>
          <DialogContent className={cx('dialog-content')}>
            <Box className={cx('content-wrapper')}>
              <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9', 'gap-xs')}>
                <Typography className={cx('text-fold')}>{t('order.symbol')}</Typography>
                <Typography
                  className={cx(position?.side === OrderSide.buy ? 'App-positive-value' : 'App-negative-value')}
                >
                  {currentInstrument?.name} {t('order.perpetual')} {data?.leverage && Number(data?.leverage).toFixed().concat('x')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                <Typography className={cx('text-fold')}>{t('order.entry_price')}</Typography>
                <Typography className={cx('text-modal')}>
                  <TooltipNumber
                    characters={11}
                    decimal={maxFiguresForPrice}
                    defaultValue={data?.entryPrice ?? '--'}
                    symbol={currentInstrument?.quoteCurrency}
                  />
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                <Typography className={cx('text-fold')}>{t('order.mark_price')}</Typography>
                <Typography className={cx('text-modal')}>
                  <TooltipNumber
                    characters={11}
                    decimal={maxFiguresForPrice}
                    defaultValue={currentTicker?.oraclePrice ?? '--'}
                    symbol={currentInstrument?.quoteCurrency}
                  />
                </Typography>
              </Box>

              {showTPInput ? (
                <>
                  <div className={cx('take-profit-input')}>
                    <div className={cx('input-group', messageErrorTP && 'error-price')}>
                      {showLabelTakeProfit() && <div className={cx('input-label')}>{t('order.take_profit')}</div>}
                      <CInput
                        classNamePrefix={cx(showLabelTakeProfit() ? 'input' : 'input-not-label')}
                        classNamePrefixWrapper={cx('input-wrapper')}
                        placeholder="0.00"
                        type="text"
                        size=""
                        outline={false}
                        valueHandSet={takeProfitPrice}
                        onChange={(value: string) => handleChangeTakeProfitPrice(value)}
                      />
                      <div className={cx('trigger-value')}>
                        <SelectTPSL
                          className={cx('take-profit-menu')}
                          classNameWrapper={cx('take-profit-select')}
                          defaultValue={SELECT_CURRENCY_TYPE[0]}
                          value={{
                            value: currencyTypeTP,
                            label: SELECT_CURRENCY_TYPE.find((e) => e.value === currencyTypeTP)?.label || '',
                          }}
                          onChange={(value) => handleChangeCurrency('take-profit', value)}
                          menuPlacement="bottom"
                          placeholder={''}
                          options={SELECT_CURRENCY_TYPE}
                        />
                      </div>
                    </div>

                    <div className={cx('trigger-price')}>
                      <SelectInput
                        onChange={(value) => handleChangePriceType('take-profit', value)}
                        defaultValue={SELECT_PRICE_TYPE[0]}
                        value={{
                          value: priceTypeTP,
                          label: SELECT_PRICE_TYPE.find((e) => e.value === priceTypeTP)?.label || '',
                        }}
                        options={SELECT_PRICE_TYPE}
                        menuPlacement="bottom"
                        className={cx('choose-trigger')}
                        classNameWrapper={cx('select-wrapper')}
                      />
                    </div>
                  </div>
                  <div className={cx('message-error')}>{messageErrorTP}</div>
                </>
              ) : (
                <div className={cx('take-profit-input', 'wrap-text')}>
                  <div className={cx('text-fold')}>{t('order.take_profit')}</div>
                  <Box display="flex" alignItems="center">
                    <span className={cx('text-conditions')}>{renderTriggerConditions('take-profit')}</span>
                    <button className={cx('btn-cancel')} onClick={handleCancelTakeProfit} disabled={disableBtnCancelTP}>
                      {t('order.cancel')}
                    </button>
                  </Box>
                </div>
              )}

              <div className={cx('margin-top-bottom-9', 'text-note')}>
                {t('order.take_profit_trigger', {
                  price:
                    SELECT_PRICE_TYPE.find((e) => e.value === priceTypeTP)?.label === t(PriceTypeLabel.last)
                      ? t('order.last_price')
                      : t('order.mark_price'),
                  number:
                    currencyTypeTP === CurrencyTypeValue.roe
                      ? formatPriceRoundDown(convertPrice(takeProfitPrice, 'take-profit'), DECIMAL_TWO)
                      : formatPriceRoundDown(takeProfitPrice, maxFiguresForPrice) || '0.00',
                })}
              </div>

              {showSLInput ? (
                <>
                  <div className={cx('stop-loss-input')}>
                    <div className={cx('input-group', messageErrorSL && 'error-price')}>
                      {showLabelStopLoss() && <div className={cx('input-label')}>{t('order.stop_loss')}</div>}
                      <CInput
                        classNamePrefix={cx(showLabelStopLoss() ? 'input' : 'input-not-label')}
                        classNamePrefixWrapper={cx('input-wrapper')}
                        type="text"
                        placeholder="0.00"
                        size="xs"
                        outline={false}
                        valueHandSet={
                          currencyTypeSL === CurrencyTypeValue.roe && stopLossPrice
                            ? `-${stopLossPrice}`
                            : stopLossPrice
                        }
                        onChange={(value: string) => handleChangeStopLossPrice(value)}
                      />

                      <div className={cx('trigger-value')}>
                        <SelectTPSL
                          className={cx('take-profit-menu')}
                          classNameWrapper={cx('take-profit-select')}
                          defaultValue={SELECT_CURRENCY_TYPE[0]}
                          value={{
                            value: currencyTypeSL,
                            label: SELECT_CURRENCY_TYPE.find((e) => e.value === currencyTypeSL)?.label || '',
                          }}
                          onChange={(value) => handleChangeCurrency('stop-loss', value)}
                          menuPlacement="bottom"
                          options={SELECT_CURRENCY_TYPE}
                        />
                      </div>
                    </div>

                    <div className={cx('trigger-price')}>
                      <SelectInput
                        onChange={(value) => handleChangePriceType('stop-loss', value)}
                        defaultValue={SELECT_PRICE_TYPE[0]}
                        value={{
                          value: priceTypeSL,
                          label: SELECT_PRICE_TYPE.find((e) => e.value === priceTypeSL)?.label || '',
                        }}
                        options={SELECT_PRICE_TYPE}
                        menuPlacement="bottom"
                        className={cx('choose-trigger')}
                        classNameWrapper={cx('select-wrapper')}
                      />
                    </div>
                  </div>
                  <div className={cx('message-error')}>{messageErrorSL}</div>
                </>
              ) : (
                <div className={cx('stop-loss-input', 'wrap-text')}>
                  <div className={cx('text-fold')}>{t('order.stop_loss')}</div>
                  <Box display="flex" alignItems="center">
                    <span className={cx('text-conditions')}>{renderTriggerConditions('stop-loss')}</span>
                    <button className={cx('btn-cancel')} onClick={handleCancelStopLoss} disabled={disableBtnCancelSL}>
                      {t('order.cancel')}
                    </button>
                  </Box>
                </div>
              )}

              <div className={cx('margin-top-bottom-9', 'text-note')}>
                {t('order.stop_loss_trigger', {
                  price:
                    SELECT_PRICE_TYPE.find((e) => e.value === priceTypeSL)?.label === t(PriceTypeLabel.last)
                      ? t('order.last_price')
                      : t('order.mark_price'),
                  number:
                    currencyTypeSL === CurrencyTypeValue.roe
                      ? formatPriceRoundDown(convertPrice(stopLossPrice, 'stop-loss'), DECIMAL_TWO)
                      : formatPriceRoundDown(stopLossPrice, maxFiguresForPrice) || '0.00',
                })}
              </div>

              <div className={cx('margin-top-bottom-9', 'text-entire')}>{t('order.info_edit_tpsl')}</div>
            </Box>
          </DialogContent>
          <DialogActions
            className={cx(
              'dialog-action',
              !!messageErrorTP || !!messageErrorSL || disableBtnConfirm || disableBtnCancelTP || disableBtnCancelSL
                ? 'disable'
                : '',
            )}
          >
            <Button
              disabled={
                !!messageErrorTP || !!messageErrorSL || disableBtnConfirm || disableBtnCancelTP || disableBtnCancelSL
              }
              onClick={handleConfirmEntierPosition}
            >
              {t('order.confirm')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EntierPositionDialog;
