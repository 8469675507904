import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { CInput } from "src/components/cores/Input";
import { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { TypeTrade } from "src/constants/common.constants";
import { getNumberRegex } from "src/features/Market/components/OrderForm";
import {
  formatValidPrice,
  formatValidAmount,
} from "src/features/Market/MarketHelper";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { Instrument } from "src/services/instrument";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { isNumber } from "src/helpers/numberFormatter";

interface InputNumberProps {
  defaultValue: string;
  position: IPositionItem;
  type: "price" | "quantity";
  onChange?: (value: string, position: IPositionItem) => void;
}

export default function InputNumber(props: InputNumberProps) {
  const { defaultValue, position, type, onChange } = props;
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const [error, setError] = useState<boolean>(false);
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const currentInstrument = instruments?.find(
    (instrument) => instrument?.symbol === position?.symbol
  );
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item?.symbol === position?.symbol
  );

  const decimalPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const priceRegex = getNumberRegex(decimalPrice);
  const decimalAmount =
    Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;
  const amountRegex = getNumberRegex(decimalAmount);

  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;

  useEffect(() => {
    if (isNumber(defaultValue)) {
      setInputValue(defaultValue);
    } else {
      setInputValue("");
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!Number(inputValue)) {
      setError(true);
    } else if (
      type === "quantity" &&
      new BigNumber(inputValue).gt(
        new BigNumber(position.currentQty)
          .abs()
          .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
      )
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [inputValue]);

  const handleChangeValue = (value: string) => {
    if (!isNumber(value) && value) {
      return;
    }

    value = value?.trim().replace(/^0+\B/, "");
    if (isCoinM && type === "quantity") value = value.replace(".", "");
    const s = value?.split(".");
    const beforeDecimal = s[0];
    if (beforeDecimal.length > 8) {
      return;
    }

    if (type === "price") {
      if (priceRegex.test(value)) {
        setInputValue(value);
        onChange?.(value, position);
      } else {
        if (!currentInstrument || (s[1] && s[1].length > decimalPrice)) return;

        const price = formatValidPrice(value, currentInstrument);
        setInputValue(price);
        onChange?.(price, position);
      }
    } else if (type === "quantity") {
      if (amountRegex.test(value)) {
        setInputValue(value);
        onChange?.(value, position);
      } else {
        if (!currentInstrument || (s[1] && s[1].length > decimalAmount)) return;
        const amount = formatValidAmount(value, currentInstrument);
        setInputValue(amount);
        onChange?.(amount, position);
      }
    }
  };

  return (
    <CInput
      classNamePrefix={`input-positions ${error && "error-input"}`}
      type="text"
      placeholder=""
      size="xs"
      outline={false}
      valueHandSet={inputValue}
      onChange={handleChangeValue}
    />
  );
}
