import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { iconCloseCommon, iconCloseTradingRuleDialog } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/TradingRule/TradingRule.module.scss';
import { TradingRules } from './TradingRules';
import { CoinInfo } from './CoinInfo';
import { SelectContract } from './SelectContract';
import { FundingRateHistory } from './FundingRateHistory';
import { LeverageMargin } from './LeverageMargin';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Instrument } from 'src/services/instrument';
import { getCoinInfo, getFundingRateHistory, getLeverageMargin, getTradingRule } from 'src/services/general';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
import useScreen from 'src/hooks/useScreen';
import { TabsMobileTradingRule } from './TabMobileTradingRule';
import { BsXOctagonFill, BsCaretDownFill } from "react-icons/bs";

const cx = classnames.bind(styles);

type TradingRuleProps = {
  open: boolean;
  handleClose: () => void;
};

const TAB_TRADING_RULE = [
  'tradingRule.trading_rules',
  'tradingRule.coin_info',
  'tradingRule.funding_rate_history',
  'tradingRule.leverage_margin',
];

export const TradingRuleModal = ({ open, handleClose }: TradingRuleProps) => {
  const { t } = useTranslation('common');
  const instrument: Instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const [tabActive, setTabActive] = useState(0);
  const [pairSelect, setPairSelect] = useState(instrument);
  const dispatch = useAppDispatch();
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { isMobile } = useScreen();

  useEffect(() => {
    setPairSelect(instrument);
  }, [instrument]);

  useEffect(() => {
    dispatch(getTradingRule(pairSelect.symbol));
    dispatch(getLeverageMargin(pairSelect.symbol));
    dispatch(getCoinInfo(pairSelect.rootSymbol as string));
    dispatch(
      getFundingRateHistory({
        symbol: pairSelect.symbol,
      }),
    );
  }, [pairSelect]);

  return (
    <Dialog className={cx('wrapper')} maxWidth={'md'} open={open} onClose={handleClose}>
      {/*title*/}
      <DialogTitle className={cx('title-trading-rule')}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <SelectContract setContractSelect={setPairSelect} contractSelect={pairSelect} />

          <Typography component={'div'}>
            <IconButton size={'small'} className={cx('hidden', 'icon-close-modal')} onClick={handleClose}>
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2 }}
              />
            </IconButton>
          </Typography>
        </Box>
      </DialogTitle>

      {/*contents*/}
      <DialogContent className={cx('content')}>
        {/* Tab here */}
        {isMobile ? (
          <TabsMobileTradingRule setTabActive={setTabActive} tabActive={tabActive} />
        ) : (
          <div className={cx('tab-preference', 'no-scroll')}>
            {TAB_TRADING_RULE.map((item, index) => (
              <div
                className={cx('tab-items', tabActive === index && 'tab-items-active')}
                key={index}
                onClick={() => setTabActive(index)}
              >
                {t(item)}
              </div>
            ))}
          </div>
        )}

        <div className={cx('tab-detail')}>
          {tabActive === 0 && <TradingRules pairSelect={pairSelect} />}
          {tabActive === 1 && <CoinInfo pairSelect={pairSelect} />}
          {tabActive === 2 && <FundingRateHistory pairSelect={pairSelect} />}
          {tabActive === 3 && <LeverageMargin pairSelect={pairSelect} />}
        </div>
      </DialogContent>
    </Dialog>
  );
};
