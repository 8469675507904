import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import axiosInstance from "src/helpers/config";
import { noti } from "src/hooks/useNoti";
import { getPosition } from "src/services/position";
import { useAppDispatch } from "src/store/hooks";

export default function ReverseAction({ row }: { row: IPositionItem }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const handleReversePrice = async (row: IPositionItem) => {
    setLoading(true);
    console.log("🚀 ~ handleReversePrice ~ row:", row);
    let params = {
      side: Number(row.currentQty) > 0 ? "SELL" : "BUY",
      symbol: row.symbol,
      type: "MARKET",
      quantity: Math.abs(Number(row.currentQty)) * 2,
      price: "",
      remaining: Math.abs(Number(row.currentQty)) * 2,
      stopPrice: "",
      stopCondition: "",
      takeProfitCondition: "",
      stopLossCondition: "",
      takeProfit: "",
      stopLoss: "",
      trigger: "ORACLE",
      takeProfitTrigger: "",
      stopLossTrigger: "",
      timeInForce: "GTC",
      isReduceOnly: false,
      callbackRate: "",
      activationPrice: "",
      tpSLType: "",
      tpSLPrice: "",
      isPostOnly: false,
      contractType: row.contractType,
      asset: row.asset,
    };
    const res: any = await axiosInstance.post("/order", params);
    if (res?.code === 200 || res?.code === 201) {
      noti.success({ title: `${t("order.noti.order_submitted")}` });
      // setOpenOrderConfirmModal(false);
      const params = { page: 1, size: RECORDS_PER_PAGE };
      const contractType = location.pathname.includes(TypeTrade.COIN_M)
        ? TypeTrade.COIN_M
        : TypeTrade.USD_M;
      const payload = {
        contractType: contractType,
      };
      dispatch(getPosition({ ...params, symbol: undefined, contractType }));
      setLoading(false);
    }
    if (res.status === 400 || res.status === 404) {
      noti.error({
        title: res.data?.info?.message,
      });
      setLoading(false);
    }
  };
  return (
    <div
      className={"button-reverse text-normal"}
      style={{ textAlign: "center", width: 92 }}
      onClick={() => handleReversePrice(row)}>
      <span>{loading ? "...loading" : t("order.reverse")}</span>
    </div>
  );
}
