import styled from "@emotion/styled";

export const Container = styled.div`
  .search-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000010;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    .search-content {
      width: 90%;
      max-width: 480px;
      height: auto;
      max-height: 80vh;
      background: var(--bg-order);
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      -ms-border-radius: 16px;
      -o-border-radius: 16px;
      .search-title {
        padding: 24px 16px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-tab-border-right);
        .search-name {
          font-family: "NotoSan-semiBold";
          color: var(--color-text);
          font-size: 16px;
        }
      }
      .search-children {
        padding: 16px;
      }
    }
    .search-action {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 12px 16px 24px;
      .search-reset {
        width: 50%;
        height: 40px;
        border-radius: 20px;
        border: 1px solid var(--color-tab-border-right);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .search-search {
        width: 50%;
        height: 40px;
        border-radius: 20px;
        /* color: var(--color-background-pagination);
        background: var(--color-slider-ranger);
        display: flex;
        align-items: center;
        justify-content: center; */
        .search-text {
          width: 100%;
          height: 40px;
          border-radius: 20px;
          color: var(--color-background-pagination);
          background: var(--color-slider-ranger);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;
