import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import * as S from "./style";
import Loading from "src/components/Loading";
import TablePositionHistory from "./TablePosition";
import { Instrument } from "src/services/instrument";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import { ITicker } from "src/services/ticker";
import { IPosition, getPosition } from "src/services/position";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { IAccount } from "src/services/account";
import { RootState } from "src/store/store";
import { Pagination } from "@mui/material";
import NoData from "../NoData";

export default function PositionHistory({
  hideOtherPairs,
  rows,
  tickers,
  tradingRulesCache,
  account,
  allPosition,
  handleChangeLimitPrice,
  handleChangePositionSize,
  handleCloseWithMarketPrice,
  handleCloseWithLimitPrice,
}: {
  hideOtherPairs: boolean;
  rows: IPositionItem[];
  tickers: ITicker[];
  tradingRulesCache: ITradingRuleCache[];
  account: IAccount[];
  allPosition: IPosition[];
  handleChangeLimitPrice: (value: string, position: IPositionItem) => void;
  handleChangePositionSize: (value: string, position: IPositionItem) => void;
  handleCloseWithMarketPrice: (position: IPositionItem) => void;
  handleCloseWithLimitPrice: (position: IPositionItem) => void;
}) {
  const currentPage = useAppSelector((state) => state.position.currentPage);
  const totalPage = useAppSelector((state) => state.position.totalPage);
  const dispatch = useAppDispatch();
  const changeCheckboxRef = useRef(hideOtherPairs);
  const ticker = useAppSelector((state: RootState) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const changeInstrumentRef = useRef(currentInstrument.symbol);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [loading, setLoading] = useState(false);
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTicker = useAppSelector((state) => state.ticker.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  useEffect(() => {
    setLoading(loadingInstrument || loadingTicker || loadingTradingRules);
  }, [loadingInstrument, loadingTicker, loadingTradingRules]);
  const fetchData = async (page: number) => {
    const payload: any = { page: page, size: RECORDS_PER_PAGE, contractType };
    if (hideOtherPairs) {
      payload.symbol = currentInstrument.symbol;
    }
    await dispatch(getPosition(payload));
    // await dispatch(getAllPosition(contractType));
  };
  useEffect(() => {
    if (currentPage) {
      // if (changeCheckboxRef.current !== hideOtherPairs || changeInstrumentRef.current !== currentInstrument.symbol) {
      //   fetchData(1);
      // }
      if (
        changeCheckboxRef.current === hideOtherPairs &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(currentPage);
      } else {
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [hideOtherPairs, currentInstrument]);
  return (
    <S.Container>
      {loading && <Loading />}
      {!loading && (
        <TablePositionHistory
          account={account}
          allPosition={allPosition}
          currentInstrument={currentInstrument}
          instruments={instruments}
          isCoinM={isCoinM}
          loading={loading}
          rows={rows}
          theme={theme}
          tickers={tickers}
          tradingRulesCache={tradingRulesCache}
          ticker={ticker}
          handleChangeLimitPrice={handleChangeLimitPrice}
          handleChangePositionSize={handleChangePositionSize}
          handleCloseWithMarketPrice={handleCloseWithMarketPrice}
          handleCloseWithLimitPrice={handleCloseWithLimitPrice}
        />
      )}
      {!loading && !rows.length && <NoData />}
      {!loading && totalPage > 1 && (
        <div className={"pagination"}>
          <Pagination
            page={currentPage}
            onChange={(e, page) => {
              fetchData(page);
            }}
            count={totalPage}
            variant="outlined"
            shape="rounded"
            className={"pagination-custom"}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "var(--color-value-1) !important",
              },
              "& .Mui-selected": {
                backgroundColor:
                  "var(--color-background-pagination) !important",
                border: "1px solid var(--color-outline-pagination) !important",
                color: "var(--color-text-pagination) !important",
                borderRadius: "5px !important",
              },
              "& .MuiPagination-ul": {
                flexWrap: "nowrap",
              },
            }}
          />
        </div>
      )}
    </S.Container>
  );
}
