import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  Button,
  DialogActions,
} from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import { iconCloseCommon, warningIcon, iconWarningWhite, iconCloseCommonDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { getContractType, getPositionDirection } from '../../helper';
import { closeAllPosition } from 'src/services/position';
import { Instrument } from 'src/services/instrument';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import BigNumber from 'bignumber.js';
import { OrderType } from '../../constant';
import { IPositionItem } from '../../PositionRecord';
import PositionCalculator from 'src/helpers/PositionCalculator';
import { THEME_MODE } from 'src/interfaces/theme';
import useIsMobile from 'src/hooks/useIsMobile';
import { MOBILE_DIMENSION } from 'src/constants/common.constants';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { useTranslation } from 'react-i18next';
import { BsXOctagonFill,BsExclamationCircleFill } from "react-icons/bs";

interface ClosePositionProps {
  closePostionData: { type: OrderType.market | OrderType.limit; price: string } | undefined;
  data: IPositionItem | undefined;
  handleCloseDialog: () => void;
  handleConfirm: (type: OrderType.market | OrderType.limit, position: IPositionItem) => void;
  openDialog: boolean;
}

interface CloseAllPositionProps {
  handleCloseDialog: () => void;
  openDialog: boolean;
}

const cx = classnames.bind(styles);

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '560px',
      background: 'var(--color-background-modal)',
      borderRadius: '8px',
    },
    '& .MuiDialogTitle-root': {
      padding: '20px 40px 0px 40px',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 50px',
    },
    '& .MuiDialogActions-root': {
      padding: '0px 50px 50px 50px',
      fontFamily: 'inherit',
    },
    '& .MuiTypography-root': {
      fontFamily: 'inherit',
    },
  },
});

const useStylesMobile = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '500px',
      background: 'var(--color-background-modal)',
    },
    '& .MuiDialogTitle-root': {
      padding: '20px 15px 0px 15px',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 15px',
    },
    '& .MuiDialogActions-root': {
      padding: '0px 15px 15px 15px',
    },
  },
});

export const ClosePositionDialog: React.FC<ClosePositionProps> = ({
  closePostionData,
  data,
  openDialog,
  handleCloseDialog,
  handleConfirm,
}) => {
  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const { t } = useTranslation('common');
  const instruments: Instrument[] = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = instruments?.find((instrument) => instrument.symbol === data?.symbol);
  const theme = useAppSelector((state) => state.theme.themeMode);

  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === currentInstrument?.symbol);
  const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const maxFiguresForSize = Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

  const orderQty = new BigNumber(data?.positionSize || 0).abs().toString();

  const estimatedPNLMarket = PositionCalculator.getUnrealizedPNL(data);
  const estimatedPNLLimit = PositionCalculator.getRealizedPNL(data, closePostionData?.price);
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);

  const handleClosePosition = async () => {
    if (!closePostionData?.type || !data) return;
    handleCloseDialog();
    handleConfirm(closePostionData?.type, data);
  };

  return (
    <Dialog open={openDialog} className={!isMobile ? classes.root : classesMobile.root} onClose={handleCloseDialog}>
      <DialogTitle>
        <Box>
          <Typography component={'div'} className={cx('icon-close')}>
            <IconButton size={'small'} onClick={handleCloseDialog}>
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              />
            </IconButton>
          </Typography>
          <div className={cx('dialog-title-order')}>{t('order.order_confirmation')}</div>
        </Box>
      </DialogTitle>
      <DialogContent className={cx('content-order')}>
        <Box className={cx('content-wrapper')}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={cx('text-modal-order')}>
              {currentInstrument?.name} {t('order.perpetual')}
            </Typography>
            <Typography className={cx(getPositionDirection(data) === 'Long' ? 'close-long-text' : 'close-short-text')}>
              {getPositionDirection(data) === 'Long' ? `${t('close_long')}` : `${t('close_short')}`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('mt-16')}>
            <Typography className={cx('text-left-order')}>{t('order.price')}</Typography>
            <Typography className={cx('text-right-order')}>
              {closePostionData?.type === OrderType.limit ? (
                <TooltipNumber
                  characters={9}
                  decimal={maxFiguresForPrice}
                  defaultValue={closePostionData?.price}
                  symbol={currentInstrument?.quoteCurrency}
                />
              ) : (
                `${t('order.market')}`
              )}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('mt-16')}>
            <Typography className={cx('text-left-order')}>{t('order.order_qty')}</Typography>
            <Typography className={cx('text-right-order')}>
              <TooltipNumber
                characters={9}
                decimal={isCoinM ? 0 : maxFiguresForSize}
                defaultValue={orderQty}
                symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
              />
            </Typography>
          </Box>
          <div className={cx('divider')}></div>
          <Box display="flex" justifyContent="space-between">
            <Typography className={cx('text-left-order')}>{t('order.estimated_pnl')}</Typography>
            <Typography className={cx('text-right-order')}>
              {closePostionData?.type === OrderType.limit ? (
                <TooltipNumber
                  characters={Number(estimatedPNLLimit) > 0 ? 10 : 11}
                  decimal={isCoinM ? 5 : 2}
                  defaultValue={estimatedPNLLimit}
                  symbol={data?.asset}
                />
              ) : (
                <TooltipNumber
                  characters={Number(estimatedPNLMarket) > 0 ? 10 : 11}
                  decimal={isCoinM ? 5 : 2}
                  defaultValue={estimatedPNLMarket}
                  symbol={data?.asset}
                />
              )}
            </Typography>
          </Box>
          <div className={cx('close-short-text', 'dialog-warning')}>{t('order.warning_close_position')}</div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" className={cx('button-wrapper-order')}>
          <Button className={cx('close-dialog-order')} onClick={handleCloseDialog}>
            {t('order.close')}
          </Button>
          <Button
            className={cx(getPositionDirection(data) === 'Long' ? 'close-long-btn' : 'close-short-btn')}
            onClick={handleClosePosition}
          >
            {getPositionDirection(data) === 'Long' ? `${t('close_long')}` : `${t('close_short')}`}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export const CloseAllPosition: React.FC<CloseAllPositionProps> = ({ openDialog, handleCloseDialog }) => {
  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const dispatch = useAppDispatch();
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const { t } = useTranslation('common');

  const contractType = getContractType();

  const handleClosePosition = async () => {
    handleCloseDialog();
    await dispatch(closeAllPosition({ contractType }));
  };

  return (
    <Dialog open={openDialog} className={!isMobile ? classes.root : classesMobile.root} onClose={handleCloseDialog}>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <Typography component={'div'}>
            <IconButton size={'small'} onClick={handleCloseDialog}>
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              />
            </IconButton>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          {/* <img
            className={cx('warning-icon')}
            src={theme === THEME_MODE.LIGHT ? Icon : Icon}
            alt=""
          /> */}
          <BsExclamationCircleFill
            fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            style={{zoom:2.5}}
          />
        </Box>
        <Box className={cx('content-wrapper')}>
          <Typography className={cx('dialog-title')}> {t('order.close_all_position')}</Typography>
          <Typography className={cx('dialog-desc')}>{t('order.confirm_close_position')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" className={cx('button-wrapper')}>
          <Button className={cx('close-dialog')} onClick={handleCloseDialog}>
            {t('order.close')}
          </Button>
          <Button className={cx('confirm-close-btn')} onClick={handleClosePosition}>
            {t('order.confirm')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
