import React from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import { iconStarActive } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import { BsStarFill } from "react-icons/bs";

const cx = classnames.bind(styles);

interface Props {
  title: string;
  takerFee: number;
  makerFee: number;
}

const FeeLevel: React.FC<Props> = ({ title, takerFee, makerFee }) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation("common");
  return (
    <div className={cx("wrapper")}>
      <div className={cx("title")}>{title}</div>
      <div className={cx("content")}>
        <div>{t("order.taker")}</div>
        <div>{takerFee}%</div>
        <div>/</div>
        <div>{t("order.maker")}</div>
        <div>{makerFee}%</div>
      </div>
    </div>
  );
};

export default FeeLevel;
