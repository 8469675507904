import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import { iconCloseCommon, iconCloseCommonDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { CInput } from 'src/components/cores/Input';
import { isNumber } from 'src/helpers/numberFormatter';
import { SelectInput } from 'src/components/cores/SelectInput';
import BigNumber from 'bignumber.js';
import { Instrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';
import { getNumberRegex } from 'src/features/Market/components/OrderForm';
import { IPositionItem } from '../../PositionRecord';
import PositionCalculator from 'src/helpers/PositionCalculator';
import useIsMobile from 'src/hooks/useIsMobile';
import { MOBILE_DIMENSION } from 'src/constants/common.constants';
import axiosInstance from 'src/helpers/config';
import { USDT } from 'src/features/Market/Constant';
import { getAvailableBalance } from 'src/helpers/balanceCalculator';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
import TextNumber from 'src/components/cores/TextNumber';
import { BsXOctagonFill } from "react-icons/bs";

export enum PriceTypeValue {
  add = 'ADD',
  remove = 'REMOVE',
}

export enum PriceTypeLabel {
  add = 'order.add',
  remove = 'order.remove',
}

export const ERROR = {
  ERROR_MAX_ADD_AMOUNT: 'Adding amount should be lower than max addable amount',
  ERROR_MAX_REMOVE_AMOUNT: 'Removing amount should be lower than max removable amount',
  ERROR_EMPTY_AMOUNT: 'This field is required',
};

interface AdjustMarginProps {
  data: IPositionItem | undefined;
  handleCloseDialog: () => void;
  openDialog: boolean;
}

const cx = classnames.bind(styles);

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '725px !important',
      background: 'var(--color-background-modal)',
      borderRadius: '8px',
      padding: '7px 26px 42px 26px',
    },
    '& .MuiDialogActions-root': {
      padding: '0 24px',
      fontFamily: 'inherit',
    },
    '& .MuiTypography-root': {
      fontFamily: 'inherit',
    },
    '& .MuiDialogTitle-root': {
      marginBottom: '3px',
    },
  },
});

const useStylesMobile = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '500px',
      background: 'var(--color-background-modal)',
    },
    '& .MuiDialogTitle-root': {
      padding: '20px 15px 0px 15px',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 15px',
    },
    '& .MuiDialogActions-root': {
      padding: '0px 15px 15px 15px',
    },
    '& .MuiTypography-root': {
      fontFamily: 'inherit',
    },
  },
});

const decimal = 8;

const AdjustMarginDialog: React.FC<AdjustMarginProps> = ({ data, openDialog, handleCloseDialog }) => {
  const { t } = useTranslation('common');

  const SELECT_ACTION = [
    { value: PriceTypeValue.add, label: t(PriceTypeLabel.add) },
    { value: PriceTypeValue.remove, label: t(PriceTypeLabel.remove) },
  ];

  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const account = useAppSelector((state) => state.account.account);
  const instruments: Instrument[] = useAppSelector((state) => state.instrument.instrument);
  const allPosition = useAppSelector((state) => state.position.allPosition);
  const currentInstrument = instruments?.find((instrument) => instrument.symbol === data?.symbol);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache?.filter((item) => item?.symbol === data?.symbol);
  const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const theme = useAppSelector((state) => state.theme.themeMode);
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);

  const [adjustType, setAdjustType] = useState<string>(SELECT_ACTION[0].value);
  const [messageErrorAmount, setMessageErrorAmount] = useState<string>('');
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(true);

  const [amount, setAmount] = useState<string>('');
  const [liquidationPrice, setLiquidationPrice] = useState<string>('');
  const allocatedMargin = PositionCalculator.getAllocatedMargin(data);

  const availableBalance = getAvailableBalance(data?.asset);
  const maxAddable = isNumber(availableBalance) ? availableBalance : '0';
  const maxRemovable = PositionCalculator.getMaxRemovable(data);

  const priceRegex = getNumberRegex(decimal);

  const handleChangeSelect = (value: string) => {
    setAdjustType(value);
  };

  const validateMargin = () => {
    if (new BigNumber(amount).gt(adjustType === PriceTypeValue.add ? maxAddable : maxRemovable)) {
      setMessageErrorAmount(
        adjustType === PriceTypeValue.add ? t('order.validate_add_amount') : t('order.validate_remove_amount'),
      );
      setDisableConfirmBtn(true);
    } else {
      setMessageErrorAmount('');
      setDisableConfirmBtn(false);
    }
  };

  useEffect(() => {
    validateMargin();
  }, [amount, adjustType, maxAddable, maxRemovable]);

  useEffect(() => {
    if (!amount) {
      const liquidationPriceDefault = PositionCalculator.getLiquidationPrice(
        data,
        account,
        allPosition,
        data?.adjustMargin ?? '0',
      );
      setLiquidationPrice(liquidationPriceDefault);
    } else {
      const marginValue =
        adjustType === PriceTypeValue.add
          ? new BigNumber(data?.adjustMargin ?? '0').plus(amount).toString()
          : new BigNumber(data?.adjustMargin ?? '0').minus(amount).toString();
      const liquidationPrice = PositionCalculator.getLiquidationPrice(data, account, allPosition, marginValue ?? '0');
      setLiquidationPrice(liquidationPrice);
    }
  }, [data, amount, adjustType]);

  const handleChangeAmount = (value: string, digitBefore = 9) => {
    if (!isNumber(value) && value) {
      return;
    }

    value = value?.trim().replace(/^0+\B/, '');
    const s = value?.split('.');
    const beforeDecimal = s[0];
    if (beforeDecimal.length > digitBefore || (s[1] && s[1].length > decimal)) {
      return;
    }

    if (priceRegex.test(value)) {
      setAmount(value);
    } else {
      setAmount('');
    }
  };

  const handleChangeMaxAmount = () => {
    const maxAmount = new BigNumber(adjustType === PriceTypeValue.add ? maxAddable : maxRemovable).toFixed(
      decimal,
      BigNumber.ROUND_DOWN,
    );
    setAmount(Number(maxAmount) !== 0 ? maxAmount : '0');
  };

  const resetState = () => {
    setAdjustType(SELECT_ACTION[0].value);
    setAmount('');
    setMessageErrorAmount('');
  };

  const handleConfirm = async () => {
    if (messageErrorAmount) return;
    if (!amount) {
      setMessageErrorAmount(t('order.field_required'));
      return;
    }
    if (!data?.id) return;
    handleCloseDialog();

    try {
      const marginValue = adjustType === PriceTypeValue.add ? amount : `-${amount}`;
      const payload = {
        positionId: Number(data.id),
        assignedMarginValue: marginValue,
      };
      await axiosInstance.put('positions/adjust-margin', payload);
    } catch (error) {
      console.log(error);
    }
    resetState();
  };

  return (
    <Dialog
      open={openDialog}
      className={!isMobile ? classes.root : classesMobile.root}
      onClose={() => {
        handleCloseDialog();
        resetState();
      }}
    >
      <DialogTitle className={cx('wrapper-title')}>
        <Box display="flex" justifyContent="flex-end">
          <Typography>
            <IconButton
              size={'small'}
              onClick={() => {
                handleCloseDialog();
                resetState();
              }}
            >
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              />
            </IconButton>
          </Typography>
        </Box>
        <Box>
          <Typography className={cx('dialog-title')}>{t('order.adjust_margin')}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={cx('content-wrapper')}>
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9', 'column')}>
            <Typography className={cx('text-label')}>
              {t('order.amount')} {`(${data?.asset ?? ''})`}
            </Typography>
            <Typography className={cx('wrap-addable')}>
              <div className={cx('text-max')}>
                {' '}
                {adjustType === PriceTypeValue.add ? t('order.max_addable') : t('order.max_removable')}
              </div>
              <TooltipNumber
                defaultValue={adjustType === PriceTypeValue.add ? maxAddable : maxRemovable}
                characters={15}
                decimal={decimal}
                symbol={data?.asset}
                customText={cx('text-type')}
              />
            </Typography>
          </Box>
          <Box className={cx('wrap-value', 'column')}>
            <div className={cx('trigger-amount')}>
              <SelectInput
                onChange={(value) => handleChangeSelect(value)}
                defaultValue={SELECT_ACTION[0]}
                value={{
                  value: adjustType,
                  label: SELECT_ACTION.find((e) => e.value === adjustType)?.label || '',
                }}
                options={SELECT_ACTION}
                menuPlacement="bottom"
                className={cx('choose-trigger')}
                classNameWrapper={cx('select-wrapper')}
              />
            </div>
            <div className={cx('wrapper-input')}>
              <div className={cx('input-group', messageErrorAmount && 'error-price')}>
                <CInput
                  classNamePrefixWrapper={cx('input')}
                  type="text"
                  placeholder=""
                  size="sm"
                  outline={false}
                  valueHandSet={amount}
                  onChange={(value: string): void => {
                    handleChangeAmount(value);
                  }}
                />
                <div className={cx('max-btn')} onClick={handleChangeMaxAmount}>
                  {t('transfer.max')}
                </div>
              </div>
            </div>
          </Box>
          <div className={cx('message-error')}>{messageErrorAmount}</div>
          <Box display="flex" justifyContent="space-between" className={cx('wrap-current', 'column')}>
            <Typography className={cx('text-label')}>{t('order.cur_assigned_margin')}</Typography>
            <Typography className={cx('text-type')}>
              {Number(allocatedMargin) !== 0 ? (
                // <TooltipNumber
                //   characters={11}
                //   decimal={isCoinM ? 5 : 2}
                //   defaultValue={allocatedMargin}
                //   symbol={data?.asset}
                // />
                <TextNumber defaultValue={allocatedMargin} symbol={data?.asset} isCoinM={false} />
              ) : (
                `-- ${data?.asset}`
              )}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9', 'column')}>
            <Typography className={cx('text-label')}>
              {adjustType === PriceTypeValue.add
                ? t('order.est_liq_price_increase')
                : t('order.est_liq_price_reduction')}
            </Typography>
            <Typography className={cx('text-type')}>
              {Number(liquidationPrice) !== 0 ? (
                <TooltipNumber
                  characters={11}
                  decimal={maxFiguresForPrice}
                  defaultValue={liquidationPrice}
                  symbol={currentInstrument?.quoteCurrency}
                />
              ) : (
                `-- ${currentInstrument?.quoteCurrency}`
              )}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={cx('button-wrapper', disableConfirmBtn ? 'disable' : '')}>
          <Button disabled={disableConfirmBtn} className={cx('confirm-close-btn')} onClick={handleConfirm}>
            {t('order.confirm')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AdjustMarginDialog;
