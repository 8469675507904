import moment from "moment";
import { OrderType } from "src/features/PositionAndHistory/constant";
import { formatTime } from "src/helpers/stringFormatter";
import { IOrder } from "src/interfaces/order";

export default function RenderTime({ order }: { order: IOrder }) {
  if (
    order.tpSLType === OrderType.takeProfitMarket ||
    (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
  ) {
    if (!order.updatedAt) {
      return <span>--</span>;
    }
    return (
      <div className="container-render-time">
        <span>{moment(order.updatedAt).format("YYYY-MM-DD")}</span>
        <span>{moment(order.updatedAt).format("HH:mm:ss")}</span>
      </div>
    );
  }
  if (!order.createdAt) {
    return <span>--</span>;
  }
  return (
    <div className="container-render-time">
      <span>{moment(order.createdAt).format("YYYY-MM-DD")}</span>
      <span>{moment(order.createdAt).format("HH:mm:ss")}</span>
    </div>
  );
}
