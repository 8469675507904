import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import { closePriceIcon, editIcon3, editPriceIcon } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { isNumber } from 'src/helpers/numberFormatter';
import BigNumber from 'bignumber.js';
import { OrderSide } from '../../constant';
import { IOrder } from 'src/interfaces/order';
import { getNumberRegex } from 'src/features/Market/components/OrderForm';
import { formatValidPrice } from 'src/features/Market/MarketHelper';
import { useAppSelector } from 'src/store/hooks';
import { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { useTranslation } from 'react-i18next';

export const ERROR = {
  ERROR_MIN_TRIGGER_STOP_PRICE: 'Trigger price should be higher than primary order’s price',
  ERROR_MAX_TRIGGER_STOP_PRICE: 'Trigger price should be lower than primary order’s price',
  ERROR_EMPTY_TAKE_PROFIT_PRICE: 'Please input valid Take Profit Price',
  ERROR_EMPTY_STOP_LOSS_PRICE: 'Please input valid Stop Loss Price',
};
interface StopPriceProps {
  index: number;
  isViewOnly: boolean;
  remainingOrders: IOrder[];
  entryPriceInit: any;
  orderSideInit: any;
  takeProfitPriceInit: any;
  stopLossPriceInit: any;
  currentInstrument: any;
  handleUpdatePrice: (takeProfitPriceValue: string, stopLossPriceValue: string) => void;
}

const cx = classnames.bind(styles);

const StopPrice = (props: StopPriceProps) => {
  const {
    index,
    isViewOnly = false,
    remainingOrders,
    entryPriceInit,
    orderSideInit,
    takeProfitPriceInit,
    stopLossPriceInit,
    currentInstrument,
    handleUpdatePrice,
  } = props;
  const [editTakeProfitPrice, setEditTakeProfitPrice] = useState<boolean>(false);
  const [editStopLossPrice, setEditStopLossPrice] = useState<boolean>(false);

  const [takeProfitPrice, setTakeProfitPrice] = useState<string>('');
  const [stopLossPrice, setStopLossPrice] = useState<string>('');

  const [messageErrorTP, setMessageErrorTP] = useState<string>('');
  const [messageErrorSL, setMessageErrorSL] = useState<string>('');
  const [disableEditBtn, setDisableEditBtn] = useState<boolean>(false);

  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache?.filter((item) => item?.symbol === currentInstrument?.symbol);
  const decimalPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const priceRegex = getNumberRegex(decimalPrice);

  const { t } = useTranslation('common');

  useEffect(() => {
    setTakeProfitPrice(takeProfitPriceInit);
    setStopLossPrice(stopLossPriceInit);
  }, [takeProfitPriceInit, stopLossPriceInit]);

  const handleChangePrice = (value: string, type: string) => {
    if (!isNumber(value) && value) {
      return;
    }

    value = value?.trim().replace(/^0+\B/, '');
    const s = value?.split('.');
    const beforeDecimal = s[0];
    if (beforeDecimal?.length > 8 || (s[1] && s[1]?.length > decimalPrice)) {
      return;
    }

    if (type === 'take-profit') {
      let stopPrice;

      if (priceRegex.test(value)) {
        stopPrice = value;
        setTakeProfitPrice(value);
      } else {
        stopPrice = formatValidPrice(value, currentInstrument);
        setTakeProfitPrice(stopPrice);
      }

      if (orderSideInit === OrderSide.buy) {
        if (new BigNumber(stopPrice).lte(entryPriceInit)) {
          setMessageErrorTP(ERROR.ERROR_MIN_TRIGGER_STOP_PRICE);
          setDisableEditBtn(true);
        } else if (stopPrice === '') {
          setMessageErrorTP(ERROR.ERROR_EMPTY_TAKE_PROFIT_PRICE);
          setDisableEditBtn(true);
        } else {
          setMessageErrorTP('');
          setDisableEditBtn(false);
        }
      } else if (orderSideInit === OrderSide.sell) {
        if (new BigNumber(stopPrice).gte(entryPriceInit)) {
          setMessageErrorTP(ERROR.ERROR_MAX_TRIGGER_STOP_PRICE);
          setDisableEditBtn(true);
        } else if (stopPrice === '') {
          setMessageErrorTP(ERROR.ERROR_EMPTY_TAKE_PROFIT_PRICE);
          setDisableEditBtn(true);
        } else {
          setMessageErrorTP('');
          setDisableEditBtn(false);
        }
      }
    } else if (type === 'stop-loss') {
      let stopPrice;

      if (priceRegex.test(value)) {
        stopPrice = value;
        setStopLossPrice(value);
      } else {
        stopPrice = formatValidPrice(value, currentInstrument);
        setStopLossPrice(stopPrice);
      }

      if (orderSideInit === OrderSide.buy) {
        if (new BigNumber(stopPrice).gte(entryPriceInit)) {
          setMessageErrorSL(ERROR.ERROR_MAX_TRIGGER_STOP_PRICE);
          setDisableEditBtn(true);
        } else if (stopPrice === '') {
          setMessageErrorSL(ERROR.ERROR_EMPTY_STOP_LOSS_PRICE);
          setDisableEditBtn(true);
        } else {
          setMessageErrorSL('');
          setDisableEditBtn(false);
        }
      } else if (orderSideInit === OrderSide.sell) {
        if (new BigNumber(stopPrice).lte(entryPriceInit)) {
          setMessageErrorSL(ERROR.ERROR_MIN_TRIGGER_STOP_PRICE);
          setDisableEditBtn(true);
        } else if (stopPrice === '') {
          setMessageErrorSL(ERROR.ERROR_EMPTY_STOP_LOSS_PRICE);
          setDisableEditBtn(true);
        } else {
          setMessageErrorSL('');
          setDisableEditBtn(false);
        }
      }
    }
  };

  const handleEditPriceBtn = (type: string) => {
    if (type === 'take-profit') {
      setEditTakeProfitPrice(!editTakeProfitPrice);
    } else if (type === 'stop-loss') {
      setEditStopLossPrice(!editStopLossPrice);
    }
  };

  const handleConfirmPrice = (type: string) => {
    handleEditPriceBtn(type);
    handleUpdatePrice(takeProfitPrice, stopLossPrice);
  };

  const handleClosePrice = (type: string) => {
    handleEditPriceBtn(type);
    if (type === 'take-profit') {
      setTakeProfitPrice(takeProfitPriceInit);
      setMessageErrorTP('');
      setDisableEditBtn(false);
    } else if (type === 'stop-loss') {
      setStopLossPrice(stopLossPriceInit);
      setMessageErrorSL('');
      setDisableEditBtn(false);
    }
  };

  if (remainingOrders?.length === 1) {
    return (
      <div className="stop-price-wrapper">
        <Box display="flex" justifyContent="space-between" alignItems="center" className={cx('margin-top-bottom-9')}>
          <Typography component="span" className={cx('text-order-box')}>
            {t('order.stop_price')}
          </Typography>
          <div>
            {!editTakeProfitPrice && !editStopLossPrice ? (
              <Box display="flex" justifyContent="space-between">
                <span className={cx('input-value-text')}>{takeProfitPriceInit ? takeProfitPrice : stopLossPrice}</span>
                {!isViewOnly && (
                  <img
                    src={Icon}
                    className={cx('edit-stop-price')}
                    alt=""
                    onClick={() => handleEditPriceBtn(takeProfitPriceInit ? 'take-profit' : 'stop-loss')}
                  />
                )}
              </Box>
            ) : (
              <div className={cx('stop-price-input')}>
                <input
                  type="text"
                  className={cx('input-value')}
                  value={takeProfitPriceInit ? takeProfitPrice : stopLossPrice}
                  onChange={(e) => handleChangePrice(e.target.value, takeProfitPriceInit ? 'take-profit' : 'stop-loss')}
                />
                <div className={cx('stop-price-btn')}>
                  <button
                    disabled={disableEditBtn}
                    onClick={() => handleConfirmPrice(takeProfitPriceInit ? 'take-profit' : 'stop-loss')}
                  >
                    <img src={Icon} className={cx('edit-icon', disableEditBtn ? 'disabled' : '')} />
                  </button>

                  <img
                    onClick={() => handleClosePrice(takeProfitPriceInit ? 'take-profit' : 'stop-loss')}
                    src={Icon}
                    className={cx('close-icon')}
                  />
                </div>
              </div>
            )}
          </div>
        </Box>
        {(messageErrorTP || messageErrorSL) && (
          <div className={cx('stop-price-error')} style={{ right: '55px' }}>
            {takeProfitPriceInit ? messageErrorTP : messageErrorSL}
          </div>
        )}
      </div>
    );
  }

  if (remainingOrders?.length === 2) {
    return (
      <div className="stop-price-wrapper">
        <Box display="flex" justifyContent="space-between" alignItems="center" className={cx('margin-top-bottom-9')}>
          <Typography component="span" className={cx('text-order-box')}>
            {t('order.stop_price')}
          </Typography>
          <div>
            {(index === 0 && !editTakeProfitPrice) || (index === 1 && !editStopLossPrice) ? (
              <Box display="flex" justifyContent="space-between">
                <span className={cx('input-value-text')}>{index === 0 ? takeProfitPrice : stopLossPrice}</span>
                {!isViewOnly && (
                  <img
                    src={Icon}
                    className={cx('edit-stop-price')}
                    alt=""
                    onClick={() => handleEditPriceBtn(index === 0 ? 'take-profit' : 'stop-loss')}
                  />
                )}
              </Box>
            ) : (
              <div className={cx('stop-price-input')}>
                <input
                  type="text"
                  className={cx('input-value')}
                  value={index === 0 ? takeProfitPrice : stopLossPrice}
                  onChange={(e) => handleChangePrice(e.target.value, index === 0 ? 'take-profit' : 'stop-loss')}
                />

                <div className={cx('stop-price-btn')}>
                  <button
                    disabled={disableEditBtn}
                    onClick={() => handleConfirmPrice(index === 0 ? 'take-profit' : 'stop-loss')}
                  >
                    <img src={Icon} className={cx('edit-icon', disableEditBtn ? 'disabled' : '')} />
                  </button>

                  <img
                    onClick={() => handleClosePrice(index === 0 ? 'take-profit' : 'stop-loss')}
                    src={Icon}
                    className={cx('close-icon')}
                  />
                </div>
              </div>
            )}
          </div>
        </Box>
        {(messageErrorTP || messageErrorSL) && (
          <div className={cx('stop-price-error')}>{index === 0 ? messageErrorTP : messageErrorSL}</div>
        )}
      </div>
    );
  }

  return null;
};

export default StopPrice;
