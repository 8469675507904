import classnames from "classnames/bind";
import React, { useCallback, useRef } from "react";
// import { DropIconMenu, IconDropDark, linkIcon } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import styles from "src/components/cores/SelectBoxMobile/index.module.scss";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";

const cx = classnames.bind(styles);

export type TOption = {
  label: string;
  value?: string | number;
  icon?: string;
  onAction?: () => void;
  key?: OPTION_KEY;
  isNotArrow?: boolean;
  subMenu?: {
    label: string;
    path: () => void;
  }[];
};

interface Props {
  title?: string;
  iconTitle?: string;
  options?: TOption[];
  isIcon?: boolean;
  isOpen?: boolean;
  handleToggleSelection?: () => void;
  handleToggleSubMenu?: (key?: OPTION_KEY) => void;
  currentOption?: OPTION_KEY;
  iconLink?: any;
  iconOptions?: any;
  classes?: string;
}

export type OPTION_KEY = "SPOT" | "FUTURE" | "CONVERT";

const SelectBoxMobile: React.FC<Props> = ({
  title,
  options,
  isOpen,
  handleToggleSelection,
  handleToggleSubMenu,
  iconLink,
  iconOptions,
  currentOption,
  classes,
}) => {
  const rfTitle = useRef<HTMLDivElement>(null);
  const rfUnderPanel = useRef<HTMLUListElement>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const handleOpenSelect = useCallback(() => {
    handleToggleSelection?.();
  }, [handleToggleSelection]);

  const handleOnTitleMouseEntered = useCallback(() => {
    if (!rfTitle.current) return;
    if (!rfUnderPanel.current) return;
    if (
      window.innerWidth - rfTitle.current.getBoundingClientRect().right <
      rfUnderPanel.current.getBoundingClientRect().width + 20
    ) {
      rfUnderPanel.current.style.right = "0px";
    }
  }, []);

  return (
    <div className={cx("select-wrapper")}>
      <ul>
        <li onClick={handleOpenSelect} onMouseEnter={handleOnTitleMouseEntered}>
          <div
            className={cx("select-title", isOpen && "open-select")}
            ref={rfTitle}
          >
            {title}
            <span
              className={`icon ${
                isOpen ? "icon-arrow-up-bold1" : "icon-arrow-down-bold1"
              }`}
              style={{ zoom: 0.5 }}
            />
          </div>

          {isOpen && options && (
            <ul
              className={cx("option", classes)}
              ref={rfUnderPanel}
              onClick={(e) => e.stopPropagation()}
            >
              {options?.map((option, index) => (
                <>
                  <div
                    key={index}
                    className={cx("option-value")}
                    onClick={() => {
                      option.onAction?.();
                      handleToggleSubMenu?.(option.key);
                    }}
                  >
                    <div className={cx("wrapper-select")}>
                      {option.icon && (
                        <img
                          style={{ marginLeft: "15px" }}
                          width={25}
                          height={25}
                          src={option.icon}
                        />
                      )}
                      {option.label && (
                        <div className={cx("option-text")}>{option.label}</div>
                      )}
                      {iconLink && (
                        <span
                          className="icon icon-arrow7"
                          style={{ color: "#00ddb3" }}
                        />
                      )}
                      {iconOptions && !option.isNotArrow && (
                        <span
                          className={`icon ${
                            currentOption === option.key
                              ? "icon-arrow-up-bold1"
                              : "icon-arrow-down-bold1"
                          }`}
                          style={{ zoom: 0.4 }}
                        />
                      )}
                    </div>
                  </div>
                  <div className={cx("sub-menu")}>
                    {option.subMenu?.map((subItem, i) => {
                      if (currentOption === option.key) {
                        return (
                          <div className={cx("sub-menu-item")} key={i}>
                            <div
                              key={i}
                              onClick={() => {
                                subItem.path?.();
                              }}
                              className={cx("path-select")}
                            >
                              {subItem.label}
                            </div>
                            <span
                              className="icon icon-arrow7"
                              style={{ color: "#00ddb3" }}
                            />
                          </div>
                        );
                      }
                      return;
                    })}
                  </div>
                </>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default SelectBoxMobile;
