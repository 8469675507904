import classnames from "classnames/bind";
import React from "react";
import { useTranslation } from "react-i18next";
// import { facebookIcon, logoMonas, teleIcon, twitterIcon, youtubeIcon } from 'src/assets/icon';
import { getLocalStorage } from "src/helpers/storage";
import useScreen from "src/hooks/useScreen";
import { AboutUs, Produtcs, Service, Support, Legal, Contact } from "./config";

import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";

import LogoFooterDark from "src/assets/img/logo-footer-dark.png";
import LogoFooterLight from "src/assets/img/logo-footer-light.png";
// import LogoDark from "src/assets/icon/logo-white.svg";
import IconFacebook from "src/assets/icon/images/icon/social/icon-facebook.svg";
import IconTelegram from "src/assets/icon/images/icon/social/icon-telegram.svg";
import IconTwitter from "src/assets/icon/images/icon/social/icon-twitter.svg";
import IconWeibo from "src/assets/icon/images/icon/social/icon-weibo.svg";
import IconYoutube from "src/assets/icon/images/icon/social/icon-youtube.svg";

import styles from "./Footer.module.scss";

const cx = classnames.bind(styles);

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation("common");
  const theme = useAppSelector((state) => state.theme.themeMode);
  const { isMobile, isTablet } = useScreen();
  const masterdata = getLocalStorage("masterdata");
  const social_networks = masterdata?.social_networks || null;

  const settingSite = (key: string) => {
    if (!masterdata) return "";
    const resultSetting = masterdata?.settings?.filter(
      (item: any) => item.key === key
    );

    return resultSetting.length > 0 ? resultSetting[0].value : "";
  };

  return (
    <div className={cx("footer")}>
      {/* {!isMobile && !isTablet && (
        <div className={cx("footer-logo")}>
          <div className={cx("logo")}>
            <img src={LogoDark} alt="logo" />
          </div>
          <div className={cx("footer-text")}>
            <div className={cx("text")}>{settingSite("copyright")}</div>
          </div>
        </div>
      )} */}
      <div className={cx("logo")}>
        <img
          src={theme === THEME_MODE.LIGHT ? LogoFooterLight : LogoFooterDark}
          alt="logo"
        />
      </div>
      {/* {isTablet && (
        <div className={cx("footer-logo")}>
          <div className={cx("logo-box")}>
            <div className={cx("logo")}>
              <div className={cx("social-icon-mb")}>
                {social_networks &&
                  social_networks.map((item: any, index: number) => (
                    <a
                      href={item.link}
                      className={`icon-${item.icon_class}`}
                      target="_blank"
                      key={index}
                      rel="noreferrer"
                    >
                      {item.type === "Twitter" && (
                        <img alt="" width={24} src={IconTwitter} />
                      )}
                      {item.type === "Youtube" && (
                        <img alt="" width={24} src={IconYoutube} />
                      )}
                      {item.type === "Facebook" && (
                        <img alt="" width={24} src={IconFacebook} />
                      )}
                      {item.type === "Telegram" && (
                        <img alt="" width={24} src={IconTelegram} />
                      )}
                      {item.type === "Weibo" && (
                        <img alt="" width={24} src={IconWeibo} />
                      )}
                    </a>
                  ))}
              </div>
            </div>
            <div className={cx("footer-text")}>
              <div className={cx("text")}>{settingSite("copyright")}</div>
            </div>
            <div className={cx("line")}></div>
          </div>
        </div>
      )} */}
      <div className={cx("links-all")}>
        <div className={cx("links-group")}>
          <div className={cx("links-title")}>{t(`footer.about_us`)}</div>
          {AboutUs.map(({ label, href, isBlank }) => (
            <div className={cx("links-item")} key={label}>
              {href ? (
                <a
                  href={href}
                  className={cx("link-item-href")}
                  target={isBlank ? "_blank" : "_parent"}
                  rel="noreferrer noopener"
                >
                  {t(`footer.${label}`)}
                </a>
              ) : (
                <div>{t(`footer.${label}`)}</div>
              )}
            </div>
          ))}
        </div>
        <div className={cx("links-group")}>
          <div className={cx("links-title")}>{t(`footer.products`)}</div>
          {Produtcs.map(({ label, href }) => (
            <div className={cx("links-item")} key={label}>
              {href ? (
                <a
                  href={href}
                  className={cx("link-item-href")}
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  {t(`footer.${label}`)}
                </a>
              ) : (
                <div>{t(`footer.${label}`)}</div>
              )}
            </div>
          ))}
        </div>
        <div className={cx("links-group")}>
          <div className={cx("links-title")}>{t(`footer.services`)}</div>
          {Service.map(({ label, href }) => (
            <div className={cx("links-item")} key={label}>
              {href ? (
                <a
                  href={href}
                  className={cx("link-item-href")}
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  {t(`footer.${label}`)}
                </a>
              ) : (
                <div>{t(`footer.${label}`)}</div>
              )}
            </div>
          ))}
        </div>
        <div className={cx("links-group")}>
          <div className={cx("links-title")}>{t(`footer.support`)}</div>
          {Support.map(({ label, href }) => (
            <div className={cx("links-item")} key={label}>
              {href ? (
                <a
                  href={href}
                  className={cx("link-item-href")}
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  {t(`footer.${label}`)}
                </a>
              ) : (
                <div>{t(`footer.${label}`)}</div>
              )}
            </div>
          ))}
        </div>
        <div className={cx("links-group")}>
          <div className={cx("links-title")}>{t(`footer.legal`)}</div>
          {Legal.map(({ label, href }) => (
            <div className={cx("links-item")} key={label}>
              {href ? (
                <a
                  href={href}
                  className={cx("link-item-href")}
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  {t(`footer.${label}`)}
                </a>
              ) : (
                <div>{t(`footer.${label}`)}</div>
              )}
            </div>
          ))}
          <div className={cx("links-title", "cu")}>
            {t(`footer.contact_us`)}
          </div>
          {Contact.map(({ label }) => (
            <div className={cx("links-item")} key={label}>
              <a
                className={cx("link-item-href")}
                target="_parent"
                rel="noreferrer noopener"
              >
                {settingSite("contact_email")}
              </a>
            </div>
          ))}
        </div>
        {/* <div>
          <div className={cx("middle", "group-link")}>
            {footerLinks?.map((item) => (
              <div key={item.label} className={cx("links-group")}>
                <div className={cx("links-title")}>
                  {t(`footer.${item.label}`)}
                </div>

                {item.items.map(({ label, href, isBlank }) => (
                  <div className={cx("links-item")} key={label}>
                    {href ? (
                      <a
                        href={href}
                        className={cx("link-item-href")}
                        target={isBlank ? "_blank" : "_parent"}
                        rel="noreferrer noopener"
                      >
                        {t(`footer.${label}`)}
                      </a>
                    ) : (
                      <div>{t(`footer.${label}`)}</div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <div className={cx("links-group")}>
              <div className={cx("links-title")}> {t("footer.contact_us")}</div>
              <div className={cx("links-item")}>
                <a className={cx("link-item-href")}>
                  {settingSite("contact_email")}
                </a>
              </div>
            </div>
          </div>
          <div className={cx("links-group-2")}>
            <div className={cx("title-contact")}>{t("footer.contact_us")}</div>
            <a className={cx("text-contact")}>{settingSite("contact_email")}</a>
          </div>
        </div> */}
      </div>

      {/* {!isMobile && !isTablet && (
        <div className={cx("social-icon")}>
          {social_networks &&
            social_networks.map((item: any, index: number) => (
              <a
                href={item.link}
                className={`icon-${item.icon_class}`}
                target="_blank"
                key={index}
                rel="noreferrer"
              >
                {item.type === "Twitter" && (
                  <img alt="" width={32} src={IconTwitter} />
                )}
                {item.type === "Youtube" && (
                  <img alt="" width={32} src={IconYoutube} />
                )}
                {item.type === "Facebook" && (
                  <img alt="" width={32} src={IconFacebook} />
                )}
                {item.type === "Telegram" && (
                  <img alt="" width={32} src={IconTelegram} />
                )}
                {item.type === "Weibo" && (
                  <img alt="" width={32} src={IconWeibo} />
                )}
              </a>
            ))}
        </div>
      )} */}
    </div>
  );
};

export default Footer;
