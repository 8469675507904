import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { BsXOctagonFill } from "react-icons/bs";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as S from "./style";
import Box from "@mui/material/Box";
import GeneralInfo from "./GeneralInfo";
import LimitInput from "./LimitInput";
import { SliderRange } from "src/components/SliderRange/SliderRangeMUI";
import { IPositionItem } from "../../PositionRecord";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import BigNumber from "bignumber.js";
import InputNumber from "../../Components/ContentHistoryDashboard/PositionHistory/InputNumber";
import { Instrument } from "src/services/instrument";
import { useEffect, useRef, useState } from "react";
import Loading from "src/components/Loading";

export default function ClosePositionDialogV2({
  openDialog,
  generalData,
  tabActive,
  tradingRulesCache,
  handleChange,
  handleCloseDialog,
  isCoinM,
  handleChangeLimitPrice,
  handleChangePositionSize,
  handleConfirm,
  loading,
}: {
  openDialog: boolean;
  tabActive: number;
  generalData?: IPositionItem;
  tradingRulesCache: ITradingRuleCache[];
  loading: boolean;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  handleCloseDialog: () => void;
  isCoinM: boolean;
  handleChangeLimitPrice: (value: string, position: IPositionItem) => void;
  handleChangePositionSize: (value: string, position: IPositionItem) => void;
  handleConfirm: (type: number, position?: IPositionItem) => void;
}) {
  const [percent, setPercent] = useState(100);
  const initialDataRef = useRef<string | undefined>(undefined);
  console.log("initialDataRef", initialDataRef);
  const theme = useAppSelector((state) => state.theme.themeMode);
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === generalData?.symbol
  );
  const symbol = isCoinM ? "Cont" : currentInstrument?.rootSymbol;
  useEffect(() => {
    if (generalData?.positionSize && !initialDataRef.current) {
      initialDataRef.current = generalData.positionSize;
    }
  }, [generalData?.positionSize]);

  function handleClose() {
    handleChangePositionSize(
      initialDataRef.current ?? "",
      generalData as IPositionItem
    );
    setPercent(100);
    initialDataRef.current = undefined;
    handleCloseDialog();
  }

  return (
    <Dialog open={openDialog}>
      <S.Container>
        <DialogTitle>
          <span className="title-modal">Close</span>
          <IconButton
            size={"small"}
            onClick={() => {
              if (!loading) {
                handleClose();
              }
            }}>
            <BsXOctagonFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingBottom: 20,
          }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabActive}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor:
                    theme === THEME_MODE.LIGHT ? "black" : "lightgray",
                },
              }}>
              <Tab label="Limit close" id="0" sx={{ textTransform: "none" }} />
              <Tab label="Market close" id="1" sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          <GeneralInfo
            generalData={generalData}
            tradingRulesCache={tradingRulesCache}
            isCoinM={isCoinM}
            currentInstrument={currentInstrument}
          />
          {tabActive === 0 && (
            <LimitInput
              row={generalData as any}
              onChange={handleChangeLimitPrice}
            />
          )}
          <div className="container-input">
            <span className="container-title">Close quantity</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputNumber
                defaultValue={generalData?.positionSize ?? ""}
                position={generalData as any}
                type="quantity"
                onChange={(value: string, position: IPositionItem) =>
                  handleChangePositionSize(value, position)
                }
              />
              <span className="container-title">{symbol}</span>
            </div>
          </div>
          <SliderRange
            value={percent}
            handleRange={(value: number) => {
              setPercent(value);
              const newSize = new BigNumber(Number(initialDataRef.current))
                .multipliedBy(value)
                .dividedBy(100)
                .toNumber();
              handleChangePositionSize(
                newSize.toString(),
                generalData as IPositionItem
              );
            }}
            type="size"
          />
          <div style={{ display: "flex", gap: 8 }}>
            <div
              className="button cancel-action"
              onClick={() => {
                if (!loading) {
                  handleClose();
                }
              }}>
              <span>Cancel</span>
            </div>
            <div
              className="button confirm-action"
              onClick={() => {
                if (!loading) {
                  handleConfirm(tabActive, generalData);
                }
              }}>
              {loading ? <Loading /> : <span>Confirm</span>}
            </div>
          </div>
        </DialogContent>
      </S.Container>
    </Dialog>
  );
}
