import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.chart.colorTime};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  .time-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    gap: 4px;
  }

  .time-active {
    border-radius: 16px;
    color: ${({ theme }) => theme.chart.colorTimeActive};
    background: ${({ theme }) => theme.chart.bgTime};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  background: ${({ theme }) => theme.chart.bgContainer};
  color: ${({ theme }) => theme.chart.colorSelectTime};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 264px;
`;
export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${({ theme }) => theme.chart.bgSelectTime};
  color: ${({ theme }) => theme.chart.colorSelectTime};
  font-size: 12px;
  width: 60px;
  height: 26px;
  cursor: pointer;
  &.select-time-active {
    background: ${({ theme }) => theme.chart.bgSelectTimeActive};
  }
`;
