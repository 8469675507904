import { ITradingRuleCache } from "src/services/masterdataFuture";
import { IPosition } from "src/services/position";
import { ITicker } from "src/services/ticker";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import * as S from "../PositionHistory/style";
import { useEffect, useRef, useState } from "react";
import { IOrder } from "src/interfaces/order";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import {
  getOpenOrder,
  getAllOpenOrder,
  cancelAllOrder,
} from "src/services/order";
import Loading from "src/components/Loading";
import TableOpenOrder from "./TableOpenOrder";
import { IAccount } from "src/services/account";
import { Instrument } from "src/services/instrument";
import { Pagination } from "@mui/material";
import { CancelOrderDialog } from "src/features/PositionAndHistory/dialog/CancelOrderDialog";
import { ViewOrderDialog } from "src/features/PositionAndHistory/dialog/ViewOrderDialog";
import NoData from "../NoData";
export default function OpenOrders({
  hideOtherPairs,
  tradingRulesCache,
  account,
}: {
  hideOtherPairs: boolean;
  tickers: ITicker[];
  tradingRulesCache: ITradingRuleCache[];
  account: IAccount[];
  allPosition: IPosition[];
}) {
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.order.orders);
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const allOrders = useAppSelector((state) => state.order.allOrders);
  const currentPage = useAppSelector((state) => state.order.currentPage);
  const totalPage = useAppSelector((state) => state.order.totalPage);
  const changeCheckboxRef = useRef(hideOtherPairs);
  const changeInstrumentRef = useRef(currentInstrument.symbol);
  const [loading, setLoading] = useState(false);
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  const [openCancelAllDialog, setOpenCancelAllDialog] =
    useState<boolean>(false);
  const [orderSelectedToView, setOrderSelectedToView] =
    useState<IOrder | null>();
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IOrder[]>([]);
  const [cancelType, setCancelType] = useState<string>("");
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const fetchData = async (page: number) => {
    const params = { page: page, size: RECORDS_PER_PAGE };
    if (hideOtherPairs) {
      const payload = { symbol: currentInstrument.symbol, contractType };
      dispatch(getOpenOrder({ params, payload }));
    } else {
      const payload = { contractType };
      dispatch(getOpenOrder({ params, payload }));
      dispatch(getAllOpenOrder(contractType));
    }
  };
  useEffect(() => {
    setLoading(loadingInstrument || loadingTradingRules);
  }, [loadingInstrument, loadingTradingRules]);
  useEffect(() => {
    if (currentPage) {
      // if (changeCheckboxRef.current !== hideOtherPairs || changeInstrumentRef.current !== currentInstrument.symbol) {
      //   fetchData(1);
      // }
      if (
        changeCheckboxRef.current === hideOtherPairs &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(currentPage);
      } else {
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [hideOtherPairs, currentInstrument]);
  useEffect(() => {
    if (orders) {
      setRows(orders);
    }
  }, [orders, account[0]?.id]);
  useEffect(() => {
    if (orderSelectedToView) {
      const findOrder = allOrders?.find(
        (item) => Number(item?.id) === Number(orderSelectedToView?.id)
      );
      if (findOrder) {
        const isPartiallyFilled =
          Number(findOrder.remaining) < Number(findOrder.quantity) &&
          Number(findOrder.remaining) > 0;
        if (isPartiallyFilled) {
          setOrderSelectedToView(findOrder);
        }
      } else {
        setOpenViewDialog(false);
      }
    }
  }, [allOrders]);
  const handleCloseCancelAllDialog = () => {
    setOpenCancelAllDialog(false);
  };
  const handleCancelAllOrder = async () => {
    handleCloseCancelAllDialog();
    await dispatch(cancelAllOrder({ type: cancelType, contractType }));
  };
  const handleOpenCancelAllDialog = () => {
    setOpenCancelAllDialog(true);
  };
  const handleChangeCancelType = (value: any) => {
    if (!rows.length) return;
    setCancelType(value);
    handleOpenCancelAllDialog();
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  return (
    <S.Container>
      {loading && <Loading />}
      {!loading && (
        <TableOpenOrder
          hideOtherPairs={hideOtherPairs}
          rows={rows}
          tradingRulesCache={tradingRulesCache}
          loading={loading}
          currentInstrument={currentInstrument}
          isCoinM={isCoinM}
          instruments={instruments}
          handleChangeCancelType={handleChangeCancelType}
        />
      )}
      {!loading && !rows.length && <NoData />}
      {!loading && totalPage > 1 && (
        <div className={"pagination"}>
          <Pagination
            page={currentPage}
            onChange={(e, page) => {
              fetchData(page);
            }}
            count={totalPage}
            variant="outlined"
            shape="rounded"
            className={"pagination-custom"}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "var(--color-value-1) !important",
              },
              "& .Mui-selected": {
                backgroundColor:
                  "var(--color-background-pagination) !important",
                border: "1px solid var(--color-outline-pagination) !important",
                color: "var(--color-text-pagination) !important",
                borderRadius: "5px !important",
              },
              "& .MuiPagination-ul": {
                flexWrap: "nowrap",
              },
            }}
          />
        </div>
      )}
      {orders.length > 0 && (
        <CancelOrderDialog
          cancelType={cancelType}
          handleCloseDialog={handleCloseCancelAllDialog}
          handleConfirm={handleCancelAllOrder}
          openDialog={openCancelAllDialog}
        />
      )}
      {orderSelectedToView && (
        <ViewOrderDialog
          data={orderSelectedToView}
          handleCloseDialog={handleCloseViewDialog}
          openDialog={openViewDialog}
        />
      )}
    </S.Container>
  );
}
