import styled from "@emotion/styled";

export const Container = styled.div`
  .dashboard {
    width: 100%;
    height: 592px;
    max-height: 592px;
    display: flex;
    @media only screen and (max-width: 700px) {
      height: 380px;
    }
  }
`;
