import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { Tree, TreeNode } from 'react-organizational-chart';
// import { iconCloseCommon, iconCloseCommonDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { IOrder, OrderStatus } from 'src/interfaces/order';
import { Instrument } from 'src/services/instrument';
import { useAppSelector } from 'src/store/hooks';
import { formatOrderEnum } from 'src/helpers/numberFormatter';
import { OrderSide, OrderType } from '../../constant';
import axiosInstance from 'src/helpers/config';
import StopPrice from './StopPrice';
import TriggerPrice from './TriggerPrice';
import { noti } from 'src/hooks/useNoti';
import { successMessage } from 'src/messages/successMessages';
import useIsMobile from 'src/hooks/useIsMobile';
import { MOBILE_DIMENSION } from 'src/constants/common.constants';
import { THEME_MODE } from 'src/interfaces/theme';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { formatOrderText } from '../../helper';
import { BsXOctagonFill } from "react-icons/bs";

export enum PriceTypeValue {
  last = 'LAST',
  mark = 'ORACLE',
}

export enum PriceTypeLabel {
  last = 'order.last_price',
  mark = 'order.mark_price',
}

interface ViewOrderProps {
  data: IOrder;
  handleCloseDialog: () => void;
  openDialog: boolean;
}

const cx = classnames.bind(styles);

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthXl': {
      background: 'var(--color-background-modal)',
    },
    '& .MuiDialogActions-root': {
      padding: '20px',
    },
  },
});

export const ViewOrderDialog: React.FC<ViewOrderProps> = ({ data, openDialog, handleCloseDialog }) => {
  const classes = useStyles();
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const { t } = useTranslation('common');

  const SELECT_PRICE_TYPE = [
    { value: PriceTypeValue.last, label: t(PriceTypeLabel.last) },
    { value: PriceTypeValue.mark, label: t(PriceTypeLabel.mark) },
  ];

  const selectPriceTypeOfTakeProfitInit = useRef(SELECT_PRICE_TYPE[0].value);
  const selectPriceTypeOfStopLossInit = useRef(SELECT_PRICE_TYPE[0].value);

  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const instruments: Instrument[] = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = instruments?.find((instrument) => instrument.symbol === data?.symbol);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const curTradingRule = tradingRulesCache?.filter((item) => item?.symbol === data?.symbol);
  const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);

  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(true);

  const [orderA, setOrderA] = useState<any>();
  const [remainingOrders, setRemainingOrders] = useState<IOrder[]>([]);

  const isPartiallyFilled = Number(data.remaining) < Number(data.quantity) && Number(data.remaining) > 0;
  const isViewOnly = Boolean(data.parentOrderId) || isPartiallyFilled;

  const takeProfitPriceInit = useRef('');
  const stopLossPriceInit = useRef('');

  const entryPriceInit = orderA?.price;
  const orderSideInit = orderA?.side;

  useEffect(() => {
    if (!data.id || !openDialog) return;
    (async () => {
      try {
        const ordersResponse = await axiosInstance.get(`/order/tp-sl/${data.parentOrderId ?? data.id}`);
        if (ordersResponse?.data) {
          const orderA = ordersResponse.data?.rootOrder;
          const remainingOrders = [];
          if (ordersResponse?.data?.tpOrder) {
            remainingOrders.push(ordersResponse.data?.tpOrder);
          }
          if (ordersResponse?.data?.slOrder) {
            remainingOrders.push(ordersResponse.data?.slOrder);
          }
          if (remainingOrders.length === 0) return;

          if (remainingOrders.length > 1) {
            takeProfitPriceInit.current = new BigNumber(remainingOrders[0]?.tpSLPrice).toFixed(
              maxFiguresForPrice,
              BigNumber.ROUND_DOWN,
            );
            stopLossPriceInit.current = new BigNumber(remainingOrders[1]?.tpSLPrice).toFixed(
              maxFiguresForPrice,
              BigNumber.ROUND_DOWN,
            );
            selectPriceTypeOfTakeProfitInit.current =
              remainingOrders[0].trigger === PriceTypeValue.last ? PriceTypeValue.last : PriceTypeValue.mark;
            selectPriceTypeOfStopLossInit.current =
              remainingOrders[1].trigger === PriceTypeValue.last ? PriceTypeValue.last : PriceTypeValue.mark;
          } else {
            if (ordersResponse?.data?.tpOrder) {
              takeProfitPriceInit.current = new BigNumber(remainingOrders[0]?.tpSLPrice).toFixed(
                maxFiguresForPrice,
                BigNumber.ROUND_DOWN,
              );
              selectPriceTypeOfTakeProfitInit.current =
                remainingOrders[0].trigger === PriceTypeValue.last ? PriceTypeValue.last : PriceTypeValue.mark;
            } else {
              stopLossPriceInit.current = new BigNumber(remainingOrders[0]?.tpSLPrice).toFixed(
                maxFiguresForPrice,
                BigNumber.ROUND_DOWN,
              );
              selectPriceTypeOfStopLossInit.current =
                remainingOrders[0].trigger === PriceTypeValue.last ? PriceTypeValue.last : PriceTypeValue.mark;
            }
          }

          setOrderA(orderA);
          setRemainingOrders(remainingOrders);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [data, openDialog]);

  const resetState = () => {
    setOrderA(null);
    setRemainingOrders([]);
    setDisableConfirmBtn(true);
    takeProfitPriceInit.current = '';
    stopLossPriceInit.current = '';
    selectPriceTypeOfTakeProfitInit.current = SELECT_PRICE_TYPE[0].value;
    selectPriceTypeOfStopLossInit.current = SELECT_PRICE_TYPE[0].value;
  };

  const renderPrice = (currentPrice: string) => {
    return <TooltipNumber characters={9} decimal={maxFiguresForPrice} defaultValue={currentPrice} />;
  };

  const renderQuantity = (order: IOrder) => {
    return (
      <TooltipNumber
        characters={9}
        decimal={isCoinM ? 0 : DECIMAL_TWO}
        symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
        defaultValue={order?.quantity}
      />
    );
  };

  const validateBtnConfirm = (
    takeProfitPriceValue: string,
    stopLossPriceValue: string,
    selectTriggerTP: PriceTypeValue,
    selectTriggerSL: PriceTypeValue,
  ) => {
    if (remainingOrders.length === 1) {
      if (takeProfitPriceInit.current) {
        if (
          takeProfitPriceValue ===
            new BigNumber(remainingOrders[0].tpSLPrice ?? '0').toFixed(maxFiguresForPrice, BigNumber.ROUND_DOWN) &&
          selectTriggerTP === remainingOrders[0].trigger
        ) {
          setDisableConfirmBtn(true);
        } else {
          setDisableConfirmBtn(false);
        }
      } else {
        if (
          stopLossPriceValue ===
            new BigNumber(remainingOrders[0].tpSLPrice ?? '0').toFixed(maxFiguresForPrice, BigNumber.ROUND_DOWN) &&
          selectTriggerSL === remainingOrders[0].trigger
        ) {
          setDisableConfirmBtn(true);
        } else {
          setDisableConfirmBtn(false);
        }
      }
    } else if (remainingOrders.length === 2) {
      if (
        takeProfitPriceValue ===
          new BigNumber(remainingOrders[0].tpSLPrice ?? '0').toFixed(maxFiguresForPrice, BigNumber.ROUND_DOWN) &&
        stopLossPriceValue ===
          new BigNumber(remainingOrders[1].tpSLPrice ?? '0').toFixed(maxFiguresForPrice, BigNumber.ROUND_DOWN) &&
        selectTriggerTP === remainingOrders[0].trigger &&
        selectTriggerSL === remainingOrders[1].trigger
      ) {
        setDisableConfirmBtn(true);
      } else {
        setDisableConfirmBtn(false);
      }
    }
  };

  const handleUpdatePrice = (takeProfitPriceValue: string, stopLossPriceValue: string) => {
    validateBtnConfirm(
      takeProfitPriceValue,
      stopLossPriceValue,
      selectPriceTypeOfTakeProfitInit.current,
      selectPriceTypeOfStopLossInit.current,
    );
    takeProfitPriceInit.current = takeProfitPriceValue;
    stopLossPriceInit.current = stopLossPriceValue;
  };

  const handleUpdateSelectPriceType = (value: PriceTypeValue, type: string) => {
    let selectTriggerTP = selectPriceTypeOfTakeProfitInit.current;
    let selectTriggerSL = selectPriceTypeOfStopLossInit.current;

    if (type === 'take-profit') {
      selectTriggerTP = value;
      selectPriceTypeOfTakeProfitInit.current = value;
    } else if (type === 'stop-loss') {
      selectTriggerSL = value;
      selectPriceTypeOfStopLossInit.current = value;
    }

    validateBtnConfirm(takeProfitPriceInit.current, stopLossPriceInit.current, selectTriggerTP, selectTriggerSL);
  };

  const handleConfirm = async () => {
    handleCloseDialog();
    if (disableConfirmBtn) return;

    try {
      let payload;

      if (remainingOrders.length === 1) {
        const checkTypeOrder = () => {
          if (takeProfitPriceInit.current) {
            return selectPriceTypeOfTakeProfitInit.current === PriceTypeValue.last
              ? PriceTypeValue.last
              : PriceTypeValue.mark;
          } else {
            return selectPriceTypeOfStopLossInit.current === PriceTypeValue.last
              ? PriceTypeValue.last
              : PriceTypeValue.mark;
          }
        };
        const order = {
          orderId: remainingOrders[0]?.id,
          tpSLPrice: takeProfitPriceInit.current ? takeProfitPriceInit.current : stopLossPriceInit.current,
          trigger: checkTypeOrder(),
        };
        payload = [order];
      }

      if (remainingOrders.length === 2) {
        const takeProfitOrder = {
          orderId: remainingOrders[0]?.id,
          tpSLPrice: takeProfitPriceInit.current,
          trigger:
            selectPriceTypeOfTakeProfitInit.current === PriceTypeValue.last ? PriceTypeValue.last : PriceTypeValue.mark,
        };

        const stopLossOrder = {
          orderId: remainingOrders[1]?.id,
          tpSLPrice: stopLossPriceInit.current,
          trigger:
            selectPriceTypeOfStopLossInit.current === PriceTypeValue.last ? PriceTypeValue.last : PriceTypeValue.mark,
        };
        payload = [takeProfitOrder, stopLossOrder];
      }

      await axiosInstance.put(`/order/tp-sl/${data.id}`, payload);
      noti.success({
        title: t('order.noti.order_submitted'),
      });
      resetState();
    } catch (error) {
      console.log(error);
    }
  };

  const renderTitle = (index: number) => {
    if (remainingOrders.length === 1) {
      return takeProfitPriceInit.current ? t('order.take_profit') : t('order.stop_loss');
    }

    if (remainingOrders.length === 2) {
      return index === 0 ? t('order.take_profit') : t('order.stop_loss');
    }
  };

  const renderTypeOrderA = (orderA: IOrder) => {
    return t(formatOrderText(orderA?.tpSLType || orderA?.type));
  };

  const renderOrderA = () => {
    return (
      <>
        <div className={cx('margin-top-bottom-9', 'text-desc', 'text-order-box')}>
          {remainingOrders?.length > 1 ? t('order.order_c') : t('order.order_a')}
        </div>
        <Box className={cx('box-wrapper')}>
          <Box display="flex" justifyContent="flex-start">
            <Typography component="p" className={cx('text-modal', 'text-bold')}>
              {t('order.order')} A
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
            <Typography component="p" className={cx('text-order-box')}>
              {t('order.type')}
            </Typography>
            <Typography component="p" className={cx('text-modal')}>
              {renderTypeOrderA(orderA)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
            <Typography component="p" className={cx('text-order-box')}>
              {t('order.side')}
            </Typography>
            <Typography
              component="p"
              className={cx(orderA?.side === OrderSide.buy ? 'App-positive-value' : 'App-negative-value')}
            >
              {t(formatOrderText(orderA?.side))}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
            <Typography component="p" className={cx('text-order-box')}>
              {t('order.amount')}
            </Typography>
            <Typography component="p" className={cx('text-modal')}>
              {renderQuantity(orderA)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
            <Typography component="p" className={cx('text-order-box')}>
              {t('order.price')}
            </Typography>
            <Typography component="p" className={cx('text-modal')}>
              {orderA?.type === OrderType.limit ? renderPrice(orderA?.price) : '--'}
            </Typography>
          </Box>
          {orderA?.tpSLType === OrderType.stopMarket || orderA?.tpSLType === OrderType.stopLimit ? (
            <>
              <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                <Typography component="p" className={cx('text-order-box')}>
                  {t('order.stop_price')}
                </Typography>
                <Typography component="p" className={cx('text-modal')}>
                  {renderPrice(orderA?.tpSLPrice)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                <Typography component="p" className={cx('text-order-box')}>
                  {t('order.trigger')}
                </Typography>
                <Typography component="p" className={cx('text-modal')}>
                  {orderA.trigger === PriceTypeValue.last ? t('order.last_price') : t('order.mark_price')}
                </Typography>
              </Box>
            </>
          ) : (
            ''
          )}
          <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
            <Typography component="p" className={cx('text-order-box')}>
              {t('order.reduceOnly')}
            </Typography>
            <Typography component="p" className={cx('text-modal')}>
              {t('order.false')}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const loadRemainingOrders = (remainingOrders: IOrder[]) => {
    return remainingOrders.map((order: IOrder, index: number) => {
      return (
        <TreeNode
          key={index}
          label={
            <>
              {remainingOrders?.length > 1 && (
                <div className={cx('margin-top-bottom-9', 'text-desc', 'text-order-box')}>
                  {t('order.remaining_orders', {
                    firstText: index === 0 ? 'B' : 'C',
                    secondText: index === 0 ? 'C' : 'B',
                  })}
                </div>
              )}
              <Box className={cx('box-wrapper')}>
                <Box display="flex" justifyContent="flex-start">
                  <Typography component="p" className={cx('text-modal', 'text-bold')}>
                    {t('order.order')} {remainingOrders?.length > 1 ? (index === 0 ? 'B' : 'C') : 'B'}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                  <Typography component="p" className={cx('text-order-box')}>
                    {t('order.type')}
                  </Typography>
                  <Typography component="p" className={cx('text-modal')}>
                    {renderTitle(index)}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                  <Typography component="p" className={cx('text-order-box')}>
                    {t('order.side')}
                  </Typography>
                  <Typography
                    component="p"
                    className={cx(order?.side === OrderSide.buy ? 'App-positive-value' : 'App-negative-value')}
                  >
                    {t(formatOrderText(order?.side))}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                  <Typography component="p" className={cx('text-order-box')}>
                    {t('order.amount')}
                  </Typography>
                  <Typography component="p" className={cx('text-modal')}>
                    {renderQuantity(order)}
                  </Typography>
                </Box>

                <StopPrice
                  index={index}
                  isViewOnly={isViewOnly}
                  remainingOrders={remainingOrders}
                  entryPriceInit={entryPriceInit}
                  orderSideInit={orderSideInit}
                  takeProfitPriceInit={takeProfitPriceInit.current}
                  stopLossPriceInit={stopLossPriceInit.current}
                  currentInstrument={currentInstrument}
                  handleUpdatePrice={handleUpdatePrice}
                />

                <TriggerPrice
                  index={index}
                  isViewOnly={isViewOnly}
                  remainingOrders={remainingOrders}
                  takeProfitPriceInit={takeProfitPriceInit.current}
                  selectPriceTypeOfTakeProfitInit={selectPriceTypeOfTakeProfitInit.current}
                  selectPriceTypeOfStopLossInit={selectPriceTypeOfStopLossInit.current}
                  handleUpdateSelectPriceType={handleUpdateSelectPriceType}
                />

                <Box display="flex" justifyContent="space-between" className={cx('margin-top-bottom-9')}>
                  <Typography component="p" className={cx('text-order-box')}>
                    {t('order.reduceOnly')}
                  </Typography>
                  <Typography component="p" className={cx('text-modal')}>
                    {t('order.true')}
                  </Typography>
                </Box>
              </Box>
            </>
          }
        />
      );
    });
  };

  return (
    <Dialog
      maxWidth="xl"
      open={openDialog}
      className={classes.root}
      onClose={() => {
        handleCloseDialog();
        resetState();
      }}
    >
      <DialogTitle className={cx("wrapper-title")}>
        <Box display="flex" justifyContent="end">
          <Typography component={"div"}>
            <IconButton
              size={"small"}
              onClick={() => {
                handleCloseDialog();
                resetState();
              }}
            >
              {/* <img src={theme === THEME_MODE.LIGHT ? Icon : Icon} alt="close" /> */}
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              />
            </IconButton>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography component="p" className={cx("dialog-title")}>
            {t("order.tpsl_order")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={cx("wrapper-content")}>
        {!isMobile ? (
          <Tree
            lineWidth={"2px"}
            lineColor={theme === THEME_MODE.LIGHT ? "#DEDEDE" : "#424754"}
            label={renderOrderA()}
          >
            {loadRemainingOrders(remainingOrders)}
          </Tree>
        ) : (
          <>
            {renderOrderA()}
            {loadRemainingOrders(remainingOrders)}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="center"
          className={cx("button-wrapper")}
        >
          <Button className={cx("confirm-close-btn")} onClick={handleConfirm}>
            {t("order.confirm")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
