import styled from "@emotion/styled";

export const Container = styled.div`
  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.chart.colorTime};
  }
`;

export const CandleItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  cursor: pointer;
  padding: 3px 14px;
  font-size: 14px;
  overflow: hidden;
  color: ${({ theme }) => theme.chart.colorTime};

  .icon-candle {
    width: 20px;
    flex-shrink: 0;
    margin-right: 9px;
  }

  .iconLine {
    path {
      fill: #888888;
      stroke: #888888;
    }
  }

  &:hover {
    color: #00ddb3;
    transition: 0.1s;

    span {
      color: #00ddb3;
    }

    .icon {
      path {
        fill: #00ddb3;
        stroke: #00ddb3;
        /* transition: fill 0.2s ease-in-out; */
      }
    }

    .iconCandlesSingle {
      path {
        stroke: var(--color-primary-1);
        &:nth-child(4) {
          fill: var(--color-primary-1);
        }
      }
    }

    .iconCandles {
      path {
        stroke: #00ddb3;
      }
    }

    .iconLine {
      path {
        fill: #00ddb3;
        stroke: #00ddb3;
      }
    }
  }
`;
export const IconOption = styled.div`
  width: 20px;
  flex-shrink: 0;
  margin-right: 9px;
`;

/* ${(props) =>
    props.isChooseOption !== 0
      ? css`
          color: #00ddb3;
        `
      : css``} */
