import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { IChartingLibraryWidget } from "src/charting_library/charting_library.min";
import {
  ExitIcon,
  ExpandIcon,
  ExportIcon,
  SettingIcon,
} from "src/components/svg";
import HighChart from "../../../HighChart";
import TradingWidget from "../../../TradingView/TradingWidget";
import { DEPTH_CHART, TRADING_VIEW_CHART } from "../../constant";
import CandleType from "../CandleType";
import TabsAction from "../TabsAction";
import TimeSelector from "../TimeSelector";
import * as S from "./style";
import useScreen from "src/hooks/useScreen";

// Define the shape of the context
interface ChartContextProps {
  chart: IChartingLibraryWidget | null;
  setChart: React.Dispatch<React.SetStateAction<IChartingLibraryWidget | null>>;
}

// Create context with default values
export const ChartContext =
  createContext<ChartContextProps | undefined>(undefined);
// Custom hook to use the ChartContext
// export const useChart = () => {
//   const context = useContext(ChartContext);
//   if (!context) {
//     throw new Error("useChart must be used within a ChartProvider");
//   }
//   return context;
// };

const ChartIndex = () => {
  const [chart, setChart] = useState<IChartingLibraryWidget | null>(null);

  const { isMobile } = useScreen();

  const [chartActive, setChartActive] = useState(TRADING_VIEW_CHART);
  const [time, setTime] = useState<string>("1");
  // state handle full screen
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const handleClickFullscreen = useCallback(() => {
    fullScreenRef?.current?.requestFullscreen();
  }, [fullScreenRef?.current]);

  const onExitFullscreen = useCallback(
    (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        document?.exitFullscreen();
      }
    },
    [document]
  );

  const onClose = () => {
    // setIsShowFavoredListMinute(false);
    // setIsShowFavoredListHours(false);
    // setIsShowFavoredList(false);
  };

  // console.log("chart", chart);
  return (
    <ChartContext.Provider value={{ chart, setChart }}>
      <S.Container ref={fullScreenRef}>
        <S.Wrap>
          <S.WrapLeft>
            <TimeSelector time={time} setTime={setTime} />
            {!isMobile && <S.bgLine />}
            <div className="left-action">
              <div
                className="icon"
                onClick={() => {
                  onClose();
                  chart?.chart().executeActionById("insertIndicator");
                }}
              >
                <ExportIcon />
              </div>
              <div
                className="icon"
                onClick={() => {
                  onClose();
                  chart?.chart().executeActionById("chartProperties");
                }}
              >
                <SettingIcon />
              </div>

              {/* <CandleIcon /> */}
              <CandleType />
              {/* <ArrowDownUpIcon /> */}
            </div>
          </S.WrapLeft>

          {!isMobile && (
            <S.WrapRight>
              <TabsAction tab={chartActive} setTab={setChartActive} />

              {isFullscreen ? (
                <span
                  className="full-screen"
                  onClick={() => {
                    setIsFullscreen(!isFullscreen);
                    document.exitFullscreen();
                  }}
                  onKeyDown={onExitFullscreen}
                >
                  <ExitIcon />
                </span>
              ) : (
                <span
                  className="full-screen"
                  onClick={() => {
                    setIsFullscreen(!isFullscreen);
                    onClose();
                    handleClickFullscreen();
                  }}
                  onKeyDown={onExitFullscreen}
                >
                  <ExpandIcon />
                </span>
              )}
            </S.WrapRight>
          )}
        </S.Wrap>

        <S.ChartContent>
          {chartActive === TRADING_VIEW_CHART && (
            <TradingWidget
              valueInterval={time}
              containerId={"trading-view-chart"}
              clientId="vcc"
            />
          )}

          {chartActive === DEPTH_CHART && <HighChart />}
        </S.ChartContent>
      </S.Container>
    </ChartContext.Provider>
  );
};

export default ChartIndex;
