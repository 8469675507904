import { Popover } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CandleIcon } from "src/components/svg";
import * as S from "./style";
import { useChart } from "src/hooks/useChart";

const CandleType = () => {
  const { chart } = useChart();
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [selectedValue, setSelectedValue] = React.useState<number | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (value: number) => {
    setSelectedValue(value);
    handleClose();
    chart?.chart().setChartType(value);
  };

  const candleList = [
    {
      icon: <span className="icon icon-bar" />,
      label: t("chart_bars"),
      value: 1,
    },
    {
      icon: <span className="icon icon-heikin-ashi2" />,
      label: t("chart_candles"),
      value: 2,
    },
    {
      icon: <span className="icon icon-heikin-ashi3" />,
      label: t("chart_hollow"),
      value: 3,
    },
    {
      icon: <span className="icon icon-heikin-ashi" />,
      label: t("chart_ashi"),
      value: 4,
    },
    {
      icon: <span className="icon icon-line2" />,
      label: t("chart_line"),
      value: 5,
    },
    {
      icon: <span className="icon icon-line3" />,
      label: t("chart_area"),
      value: 6,
    },
  ];

  return (
    <S.Container>
      <div className="icon" aria-hidden onClick={handleClick}>
        {selectedValue !== null ? (
          candleList.find((item) => item.value === selectedValue)?.icon
        ) : (
          <CandleIcon />
        )}
      </div>

      <Popover
        id="candle-type"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {candleList.map((item) => {
          return (
            <S.CandleItem
              key={item.value}
              onClick={(e) => {
                e.preventDefault();
                handleClickItem(item.value);
              }}
            >
              <span className="icon-candle">{item.icon}</span>
              <span className="label">{item.label}</span>
            </S.CandleItem>
          );
        })}
      </Popover>
    </S.Container>
  );
};

export default CandleType;
