import { formatTime } from "src/helpers/stringFormatter";
import ItemInfo from "../PositionHistory/ItemInfo";
import { IFill } from "src/interfaces/fill";
import { TradeSide } from "src/constants/common.constants";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import RealizedProfit from "./RealizedProfit";
import { Instrument } from "src/services/instrument";
import { useTranslation } from "react-i18next";
import Quantity from "./Quantity";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import Price from "./Price";
import Fee from "./Fee";

export default function ItemMobile({
  row,
  instruments,
  isCoinM,
  tradingRulesCache,
}: {
  row: IFill;
  instruments: Instrument[];
  isCoinM: boolean;
  tradingRulesCache: ITradingRuleCache[];
}) {
  const { t } = useTranslation("common");
  return (
    <div className="container-item">
      <div
        style={{
          display: "flex",
          gap: 10,
        }}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span
            className={`${
              row?.tradeSide === TradeSide.BUY ? "color-buy" : "color-sell"
            }`}
            style={{ fontSize: 14, fontWeight: "400" }}>
            {getSymbolName(row.symbol ?? "")}
          </span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            nodeTitle={
              <RealizedProfit
                fill={row}
                instruments={instruments}
                isCoinM={isCoinM}
                customText="text-left"
              />
            }
            description={`Position PnL`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            title={formatTime(row.createdAt)}
            description={`Time`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            title={getSymbolName(row.symbol ?? "")}
            description={`Symbol`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            title={t(formatOrderText(row.tradeSide))}
            description={`Side`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            nodeTitle={
              <Price
                fill={row}
                currentPrice={row.price ?? "0"}
                tradingRulesCache={tradingRulesCache}
              />
            }
            description={`Price`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            nodeTitle={
              <Quantity
                fill={row}
                instruments={instruments}
                isCoinM={isCoinM}
                tradingRulesCache={tradingRulesCache}
              />
            }
            description={`Quantity`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <ItemInfo
            nodeTitle={
              <Fee fill={row} instruments={instruments} isCoinM={isCoinM} />
            }
            description={`Fee`}
            textStyle={{ textAlign: "left" }}
          />
          <ItemInfo
            title={t(formatOrderText(row.liquidity))}
            description={`Role`}
            textStyle={{ textAlign: "right" }}
          />
        </div>
      </div>
    </div>
  );
}
