const ExportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7921 3.58659C9.11977 3.58659 8.57477 4.13163 8.57477 4.80397V7.49977H10.6983C11.1585 7.49977 11.5316 7.87286 11.5316 8.33311C11.5316 8.79333 11.1585 9.16641 10.6983 9.16641H8.57477V14.1514C8.57477 15.7442 7.28351 17.0354 5.69069 17.0354C4.09787 17.0354 2.80664 15.7442 2.80664 14.1514C2.80664 13.6912 3.17974 13.3181 3.63997 13.3181C4.10022 13.3181 4.47331 13.6912 4.47331 14.1514C4.47331 14.8237 5.01835 15.3687 5.69069 15.3687C6.36303 15.3687 6.90807 14.8237 6.90807 14.1514V9.16641H4.78456C4.32432 9.16641 3.95122 8.79333 3.95122 8.33311C3.95122 7.87286 4.32432 7.49977 4.78456 7.49977H6.90807V4.80397C6.90807 3.21116 8.1993 1.91992 9.7921 1.91992C11.3849 1.91992 12.6762 3.21116 12.6762 4.80397C12.6762 5.26421 12.3031 5.63731 11.8428 5.63731C11.3826 5.63731 11.0095 5.26421 11.0095 4.80397C11.0095 4.13163 10.4644 3.58659 9.7921 3.58659Z"
        fill="#8E8E92"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2882 11.0962C11.6136 10.7707 12.1412 10.7707 12.4667 11.0962L14.0852 12.7147L15.7037 11.0962C16.0292 10.7707 16.5567 10.7707 16.8822 11.0962C17.2077 11.4216 17.2077 11.9492 16.8822 12.2747L15.2637 13.8932L16.9497 15.5791C17.2751 15.9045 17.2751 16.4322 16.9497 16.7576C16.6242 17.083 16.0966 17.083 15.7712 16.7576L14.0852 15.0717L12.3992 16.7576C12.0738 17.083 11.5462 17.083 11.2207 16.7576C10.8953 16.4322 10.8953 15.9045 11.2207 15.5791L12.9067 13.8932L11.2882 12.2747C10.9627 11.9492 10.9627 11.4216 11.2882 11.0962Z"
        fill="#8E8E92"
      />
    </svg>
  );
};

export default ExportIcon;
