import { useTranslation } from "react-i18next";
import * as S from "./style";
import { CloseAllPosition } from "src/features/PositionAndHistory/dialog/ClosePositionDialog";
import { useEffect, useRef, useState } from "react";
import ItemMobile from "./ItemMobile";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getPosition } from "src/services/position";
import { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import BigNumber from "bignumber.js";
import { isNumber } from "src/helpers/numberFormatter";
import ClosePositionDialogV2 from "src/features/PositionAndHistory/dialog/ClosePositionDialogV2";
import EntierPositionDialog from "src/features/PositionAndHistory/dialog/EntierPositionDialog";

export default function MobilePositionHistory({
  hideAssetChecked,
  hideOtherPairsChecked,
  tab,
  handleChange,
  handleChangeLimitPrice,
  handleCloseWithMarketPrice,
  handleCloseWithLimitPrice,
}: {
  hideAssetChecked: boolean;
  hideOtherPairsChecked: boolean;
  tab: number;
  handleChange: (event: any) => void;
  handleChangeLimitPrice: (value: string, position: IPositionItem) => void;
  handleCloseWithMarketPrice: (position: IPositionItem) => void;
  handleCloseWithLimitPrice: (position: IPositionItem) => void;
}) {
  const [currentPosition, setCurrentPosition] =
    useState<IPositionItem | undefined>(undefined);
  const [tabActive, setTabActive] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [positionSelected, setPositionSelected] =
    useState<number | string | null>(null);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const account = useAppSelector((state) => state.account.account);
  const [rows, setRows] = useState<IPositionItem[]>([]);
  const [page, setPage] = useState(1);
  const currentPage = useAppSelector((state) => state.position.currentPage);
  const listPosition = useAppSelector((state) => state.position.positions);
  const observerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const listPositionRef = useRef<any>([]);
  const allPosition = useAppSelector((state) => state.position.allPosition);

  const allOrders = useAppSelector((state) => state.order.allOrders);
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const tickers = useAppSelector((state) => state.ticker.tickers);
  const changeCheckboxRef = useRef(hideOtherPairsChecked);
  const changeInstrumentRef = useRef(currentInstrument.symbol);
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const [openCloseAllPositionDialog, setOpenCloseAllPositionDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTicker = useAppSelector((state) => state.ticker.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  const [openEntierPositionDialog, setOpenEntierPositionDialog] =
    useState<boolean>(false);
  const { t } = useTranslation("common");
  useEffect(() => {
    setLoading(loadingInstrument || loadingTicker || loadingTradingRules);
  }, [loadingInstrument, loadingTicker, loadingTradingRules]);
  const handleOpenAllPositonDialog = () => {
    setOpenCloseAllPositionDialog(true);
  };
  const handleCloseAllPositionDialog = () => {
    setOpenCloseAllPositionDialog(false);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTabActive(0);
  };
  const fetchData = async (page: number) => {
    const payload: any = { page: page, size: 8, contractType };
    if (hideOtherPairsChecked) {
      payload.symbol = currentInstrument.symbol;
    }
    await dispatch(getPosition(payload));
    // await dispatch(getAllPosition(contractType));
  };
  useEffect(() => {
    if (currentPage) {
      if (
        changeCheckboxRef.current === hideOtherPairsChecked &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(currentPage);
      } else {
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairsChecked;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [hideOtherPairsChecked, currentInstrument]);
  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page]);
  useEffect(() => {
    if (listPosition) {
      const newListPosition = listPosition.map((position: any) => {
        const curTradingRule = tradingRulesCache?.filter(
          (item) => item?.symbol === position?.symbol
        );
        const decimalPrice =
          Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
        const decimalAmount =
          Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

        const currentTicker = tickers?.find(
          (ticker) => ticker.symbol === position.symbol
        );
        const findPosition = listPositionRef.current?.find(
          (item: IPositionItem) => Number(item.id) === Number(position.id)
        );
        const updatePosition = { ...position };

        const isFindCurTradingRule = curTradingRule.length > 0 ? true : false;

        const newLimitPrice =
          isFindCurTradingRule && currentTicker?.oraclePrice
            ? new BigNumber(currentTicker?.oraclePrice).toFixed(
                decimalPrice,
                BigNumber.ROUND_DOWN
              )
            : "NaN";

        if (findPosition && !isNumber(findPosition.limitPrice)) {
          updatePosition.limitPrice =
            findPosition.limitPrice !== "" ? newLimitPrice : "";
        } else if (findPosition && isNumber(findPosition.limitPrice)) {
          updatePosition.limitPrice = findPosition.limitPrice;
        } else {
          updatePosition.limitPrice = newLimitPrice;
        }

        const newPositionSize = isCoinM
          ? new BigNumber(position.currentQty)
              .abs()
              .toFixed(0, BigNumber.ROUND_DOWN)
          : isFindCurTradingRule
          ? new BigNumber(position.currentQty)
              .abs()
              .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
          : "NaN";

        if (findPosition && !isNumber(findPosition.positionSize)) {
          updatePosition.positionSize =
            findPosition.positionSize !== "" ? newPositionSize : "";
        } else if (findPosition && isNumber(findPosition.positionSize)) {
          const currentPositionSize = isCoinM
            ? new BigNumber(findPosition.currentQty)
                .abs()
                .toFixed(0, BigNumber.ROUND_DOWN)
            : isFindCurTradingRule
            ? new BigNumber(findPosition.currentQty)
                .abs()
                .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
            : "NaN";
          updatePosition.positionSize =
            Number(currentPositionSize) === Number(newPositionSize)
              ? findPosition.positionSize
              : newPositionSize;
        } else {
          updatePosition.positionSize = newPositionSize;
        }

        updatePosition.orders = [];
        const findTakeProfitOrder = allOrders?.find(
          (order) =>
            Number(order.id) === Number(updatePosition?.takeProfitOrderId)
        );
        const findStopLossOrder = allOrders?.find(
          (order) =>
            Number(order.id) === Number(updatePosition?.stopLossOrderId)
        );
        if (findTakeProfitOrder) {
          updatePosition.orders.push(findTakeProfitOrder);
        }
        if (findStopLossOrder) {
          updatePosition.orders.push(findStopLossOrder);
        }

        return updatePosition;
      });
      setRows(newListPosition);
      listPositionRef.current = newListPosition;
    }
  }, [listPosition, account[0]?.id, allOrders, tickers, tradingRulesCache]);

  const handleChangePositionSize = (value: string, position: IPositionItem) => {
    const updatePosition = { ...position };
    updatePosition.positionSize = value;
    const newListPosition = rows.map((row) => {
      return Number(row?.id) === Number(position?.id) ? updatePosition : row;
    });
    setRows(newListPosition);
    listPositionRef.current = newListPosition;
  };
  const handleCloseEntierDialog = () => {
    setOpenEntierPositionDialog(false);
  };
  const handleOpenEntierDialog = (position: IPositionItem) => {
    setOpenEntierPositionDialog(true);
    setCurrentPosition(position);
  };
  return (
    <S.MobileStyle>
      <div className="container-header">
        <div className={"hidden-btn"}>
          <label className={"container"}>
            <input
              type="checkbox"
              checked={tab === 5 ? hideAssetChecked : hideOtherPairsChecked}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
          <span className={"hidden-text"}>
            {tab === 5 ? t("order.hide_asset") : t("order.hide_other_pair")}
          </span>
        </div>
        <div className="action-close-all" onClick={handleOpenAllPositonDialog}>
          {t("order.close_all_position")}
        </div>
      </div>
      <div className="container-list">
        {rows.map((item) => (
          <ItemMobile
            key={item?.id}
            tradingRulesCache={tradingRulesCache}
            row={item}
            isCoinM={isCoinM}
            tickers={tickers}
            account={account}
            allPosition={allPosition}
            handleClose={(id) => {
              setPositionSelected(id);
              setOpenDialog(true);
            }}
            handleOpenTPSL={handleOpenEntierDialog}
          />
        ))}
        <div ref={observerRef} />
      </div>
      <CloseAllPosition
        handleCloseDialog={handleCloseAllPositionDialog}
        openDialog={openCloseAllPositionDialog}
      />
      {openDialog && (
        <ClosePositionDialogV2
          loading={loadingCancel}
          generalData={rows.find((row) => row.id === positionSelected)}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          tabActive={tabActive}
          handleChange={(e, newValue) => {
            setTabActive(newValue);
          }}
          tradingRulesCache={tradingRulesCache}
          isCoinM={isCoinM}
          handleChangeLimitPrice={handleChangeLimitPrice}
          handleChangePositionSize={handleChangePositionSize}
          handleConfirm={async (type, position) => {
            setLoadingCancel(true);
            if (type === 1) {
              await handleCloseWithMarketPrice(position as IPositionItem);
            } else {
              await handleCloseWithLimitPrice(position as IPositionItem);
            }
            setLoadingCancel(false);
            handleCloseDialog();
          }}
        />
      )}
      {openEntierPositionDialog && (
        <EntierPositionDialog
          data={currentPosition}
          handleCloseDialog={handleCloseEntierDialog}
          openDialog={openEntierPositionDialog}
          listPosition={rows}
        />
      )}
    </S.MobileStyle>
  );
}
