import BigNumber from 'bignumber.js';
import { IFill } from 'src/interfaces/fill';
import { IOrder, OrderStatus } from 'src/interfaces/order';
import { IPosition, updatePosition, updateAllPosition } from 'src/services/position';
import { ITrade } from 'src/interfaces/trade';
import { getOpenOrder, updateOrders, updateAllOrders } from 'src/services/order';
import { addTrades } from 'src/services/trade';
import store, { RootStore } from 'src/store/store';
import { RECORDS_PER_PAGE, TypeTrade } from 'src/constants/common.constants';
import { getPosition } from 'src/services/position';
import { noti } from 'src/hooks/useNoti';
import { OrderSide, OrderType } from './constant';
import i18next from 'i18next';
import i18n from 'src/i18n';

export const getContractType = () => {
  if (!location.pathname.includes('orders')) {
    return location.pathname.includes(TypeTrade.COIN_M) ? TypeTrade.COIN_M : TypeTrade.USD_M;
  } else {
    const contractType = history.state === TypeTrade.COIN_M ? TypeTrade.COIN_M : TypeTrade.USD_M;
    return contractType;
  }
};

export const getSymbolName = (symbol: string) => {
  const instruments = store.getState().instrument.instrument;
  const currentInstrument = instruments?.find((instrument) => instrument.symbol === symbol);
  return currentInstrument?.name || symbol;
};

export function getPositionDirection(position: IPosition | undefined): string {
  const quantity = Number(position?.currentQty || '0');
  if (quantity > 0) {
    return 'Long';
  } else if (quantity === 0) {
    return '-';
  } else {
    return 'Short';
  }
}

export const formatOrderText = (value: string | undefined) => {
  if (!value) return;
  return `order.${value?.toLowerCase()}`;
};

export const updateActiveOrders = async (store: RootStore, newOrders: IOrder[]): Promise<void> => {
  let orders = store.getState().order.orders.concat([]);
  const totalPage = store.getState().order.totalPage;
  const currentPage = store.getState().order.currentPage;
  const currentSymbol = store.getState().order.currentSymbol;
  const removingStatuses = [
    OrderStatus.CANCELED.toString(),
    OrderStatus.FILLED.toString(),
    OrderStatus.PENDING.toString(),
    OrderStatus.UNTRIGGERED.toString(),
  ];
  const addingStatuses = [OrderStatus.ACTIVE.toString(), OrderStatus.UNTRIGGERED.toString()];
  const contractType = getContractType();
  const filterOrders = newOrders?.filter((order) => order.contractType === contractType);

  let isTpSlTriggered = false;
  const findRootOrder = filterOrders?.find((item) => !item.parentOrderId && item.isTpSlTriggered === true);
  if (findRootOrder) {
    const isTakeProfitOrderTriggered = filterOrders?.some(
      (item) => Number(item.id) === Number(findRootOrder?.takeProfitOrderId),
    );
    const isStopLossOrderTriggered = filterOrders?.some(
      (item) => Number(item.id) === Number(findRootOrder?.stopLossOrderId),
    );
    isTpSlTriggered = isTakeProfitOrderTriggered || isStopLossOrderTriggered;
  }

  let removingOrders = filterOrders?.filter((order) => removingStatuses.includes(order.status || ''));
  let addingOrders = filterOrders?.filter((order) => addingStatuses.includes(order.status || ''));

  removingOrders = removingOrders?.filter(
    (order) => order.status !== OrderStatus.UNTRIGGERED.toString() || order.isHidden === true,
  );

  addingOrders = addingOrders?.filter(
    (order) => order.status !== OrderStatus.UNTRIGGERED.toString() || order.isHidden === false,
  );

  const resources = i18next.getResource(i18n.language, 'common', 'order');

  for (const order of removingOrders) {
    orders = orders?.filter((item) => Number(item.id) !== order.id);
    if (order.status === OrderStatus.CANCELED.toString()) {
      if (order.isTriggered === true) {
        // Take profit triggered
        if (order.tpSLType === OrderType.takeProfitMarket) {
          noti.success({
            title: resources.noti.take_profit_triggered,
          });
        }
        // Stop loss triggered
        if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
          noti.success({
            title: resources.noti.stop_loss_triggered,
          });
        }
        // Stop market triggered, trailing stop triggered
        if (
          (order.tpSLType === OrderType.stopMarket && !order.isTpSlOrder && isTpSlTriggered === false) ||
          order.tpSLType === OrderType.trailingStop
        ) {
          noti.success({
            title: resources.noti.stop_order_triggered,
          });
        }
      }

      // if (
      //   order.type === OrderType.market &&
      //   Number(order.remaining) > 0 &&
      //   Number(order.remaining) < Number(order.quantity)
      // ) {
      //   noti.success({
      //     title:
      //       order.side === OrderSide.buy
      //         ? resources.noti.buy_order_partially_fill
      //         : resources.noti.sell_order_partially_fill,
      //   });
      // }

      // if (order.isHidden === false) {
      //   if (order.parentOrderId || (!order.parentOrderId && isTpSlTriggered === false)) {
      //     noti.info({
      //       title: order.side === OrderSide.buy ? resources.noti.order_buy_cancel : resources.noti.order_sell_cancel,
      //     });
      //   }
      // }
    }

    if (order.status === OrderStatus.FILLED.toString()) {
      if (order.isTriggered === true) {
        // Take profit triggered
        if (order.tpSLType === OrderType.takeProfitMarket) {
          noti.success({
            title: resources.noti.take_profit_triggered,
          });
        }
        // Stop loss triggered
        if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
          noti.success({
            title: resources.noti.stop_loss_triggered,
          });
        }
        // Stop market triggered, trailing stop triggered
        if (
          (order.tpSLType === OrderType.stopMarket && !order.isTpSlOrder && isTpSlTriggered === false) ||
          order.tpSLType === OrderType.trailingStop
        ) {
          noti.success({
            title: resources.noti.stop_order_triggered,
          });
        }
      }

      if (order.parentOrderId || (!order.parentOrderId && isTpSlTriggered === false)) {
        noti.success({
          title: order.side === OrderSide.buy ? resources.noti.buy_order_fill : resources.noti.sell_order_fill,
        });
      }
    }
  }

  for (const order of addingOrders) {
    const index = orders.findIndex((item) => Number(item.id) === order.id);
    if (index >= 0) {
      orders[index] = order;
    } else {
      if (currentPage === 1) {
        orders.unshift(order);
      }
    }

    if (order.status === OrderStatus.ACTIVE.toString()) {
      if (order.isTriggered) {
        // Stop limit triggered
        if (
          isTpSlTriggered === false &&
          order.isShowToast === true &&
          order.tpSLType === OrderType.stopLimit &&
          Number(order.remaining) === Number(order.quantity)
        ) {
          noti.success({
            title: resources.noti.stop_order_triggered,
          });
        }
      }

      if (
        isTpSlTriggered === false &&
        order.isShowToast === true &&
        order.type === OrderType.limit &&
        Number(order.remaining) > 0 &&
        Number(order.remaining) < Number(order.quantity)
      ) {
        noti.success({
          title:
            order.side === OrderSide.buy
              ? resources.noti.buy_order_partially_fill
              : resources.noti.sell_order_partially_fill,
        });
      }
    }
  }

  // update all orders
  let orderList = store.getState().order.allOrders.concat([]);
  for (const order of removingOrders) {
    orderList = orderList?.filter((item) => Number(item.id) !== order.id);
  }
  for (const order of addingOrders) {
    const index = orderList.findIndex((item) => Number(item.id) === order.id);
    if (index >= 0) {
      orderList[index] = order;
    } else {
      orderList.unshift(order);
    }
  }
  const newTotalItem = orderList.length;
  const newTotalPage = Math.ceil(newTotalItem / RECORDS_PER_PAGE);
  store.dispatch(updateAllOrders(orderList));

  const newTotalItemOfCurrentSymbol = orderList?.filter((order) => order.symbol === currentSymbol).length;
  const newTotalPageOfCurrentSymbol = Math.ceil(newTotalItemOfCurrentSymbol / RECORDS_PER_PAGE);

  // update order
  if (currentSymbol) {
    orders = orders?.filter((order) => order.symbol === currentSymbol);
    if (orders.length >= RECORDS_PER_PAGE) {
      orders = orders.slice(0, RECORDS_PER_PAGE);
      store.dispatch(updateOrders({ orders, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }));
    } else if (orders.length < RECORDS_PER_PAGE && orders.length > 0) {
      if (currentPage < totalPage) {
        const params = { page: currentPage, size: RECORDS_PER_PAGE };
        const payload = { symbol: currentSymbol, contractType };
        store.dispatch(getOpenOrder({ params, payload }));
      } else {
        store.dispatch(updateOrders({ orders, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }));
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(updateOrders({ orders, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }));
        return;
      }
      const params: any = { page: currentPage > 1 ? currentPage - 1 : 1, size: RECORDS_PER_PAGE };
      const payload = { symbol: currentSymbol, contractType };
      store.dispatch(getOpenOrder({ params, payload }));
    }
  } else {
    if (orders.length >= RECORDS_PER_PAGE) {
      orders = orders.slice(0, RECORDS_PER_PAGE);
      store.dispatch(updateOrders({ orders, newTotalItem, newTotalPage }));
    } else if (orders.length < RECORDS_PER_PAGE && orders.length > 0) {
      if (currentPage < totalPage) {
        const params = { page: currentPage, size: RECORDS_PER_PAGE };
        const payload = { contractType };
        store.dispatch(getOpenOrder({ params, payload }));
      } else {
        store.dispatch(updateOrders({ orders, newTotalItem, newTotalPage }));
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(updateOrders({ orders, newTotalItem, newTotalPage }));
        return;
      }
      const params: any = { page: currentPage > 1 ? currentPage - 1 : 1, size: RECORDS_PER_PAGE };
      const payload = { contractType };
      store.dispatch(getOpenOrder({ params, payload }));
    }
  }
};

export const updateActivePosition = async (store: RootStore, updatedPosition: IPosition): Promise<void> => {
  let positions = store.getState().position.positions.concat([]);
  const totalPage = store.getState().position.totalPage;
  const currentPage = store.getState().position.currentPage;
  const currentSymbol = store.getState().position.currentSymbol;
  const index = positions.findIndex((position) => position.symbol === updatedPosition.symbol);
  const contractType = getContractType();
  const contractTypeOfPosition = updatedPosition.contractType;
  if (contractTypeOfPosition !== contractType) return;

  if (index >= 0) {
    positions[index] = updatedPosition;
  } else {
    if (currentPage === 1) {
      positions.unshift(updatedPosition);
    }
  }

  positions = positions?.filter((position) => Number(position.currentQty) !== 0);

  // update all positions
  let positionList = store.getState().position.allPosition.concat([]);
  const indexPosition = positionList.findIndex((position) => position.symbol === updatedPosition.symbol);
  if (indexPosition < 0) {
    positionList.push(updatedPosition);
  } else {
    positionList[indexPosition] = updatedPosition;
  }
  positionList = positionList?.filter((position) => Number(position.currentQty) !== 0);
  const newTotalItem = positionList.length;
  const newTotalPage = Math.ceil(newTotalItem / RECORDS_PER_PAGE);
  store.dispatch(updateAllPosition(positionList));

  const newTotalItemOfCurrentSymbol = positionList?.filter((position) => position.symbol === currentSymbol).length;
  const newTotalPageOfCurrentSymbol = Math.ceil(newTotalItemOfCurrentSymbol / RECORDS_PER_PAGE);

  // update position
  if (currentSymbol) {
    positions = positions?.filter((position) => position.symbol === currentSymbol);
    if (positions.length >= RECORDS_PER_PAGE) {
      positions = positions.slice(0, RECORDS_PER_PAGE);
      store.dispatch(updatePosition({ positions, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }));
    } else if (positions.length < RECORDS_PER_PAGE && positions.length > 0) {
      if (currentPage < totalPage) {
        const payload: any = { page: currentPage, size: RECORDS_PER_PAGE, contractType };
        payload.symbol = currentSymbol;
        console.log("contractType 111", contractType);
        // store.dispatch(getPosition(payload));
      } else {
        store.dispatch(updatePosition({ positions, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }));
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(updatePosition({ positions, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }));
        return;
      }
      const payload: any = { page: currentPage > 1 ? currentPage - 1 : 1, size: RECORDS_PER_PAGE, contractType };
      payload.symbol = currentSymbol;
        // console.log("contractType 111", contractType);
      store.dispatch(getPosition(payload));
    }
  } else {
    if (positions.length >= RECORDS_PER_PAGE) {
      positions = positions.slice(0, RECORDS_PER_PAGE);
      store.dispatch(updatePosition({ positions, newTotalItem, newTotalPage }));
    } else if (positions.length < RECORDS_PER_PAGE && positions.length > 0) {
      if (currentPage < totalPage) {
        const payload: any = { page: currentPage, size: RECORDS_PER_PAGE, contractType };
        // console.log("contractType 2222", contractType);
        store.dispatch(getPosition(payload));
      } else {
        store.dispatch(updatePosition({ positions, newTotalItem, newTotalPage }));
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(updatePosition({ positions, newTotalItem, newTotalPage }));
        return;
      }
      const payload: any = { page: currentPage > 1 ? currentPage - 1 : 1, size: RECORDS_PER_PAGE, contractType };
      store.dispatch(getPosition(payload));
        // console.log("contractType 3333", contractType);
    }
  }
};

export const addNewTrades = (store: RootStore, trades: ITrade[]): void => {
  const fills: IFill[] = [];
  const currentAccountId = Number(store.getState().account.account[0]?.id);
  const userTrades = trades?.filter(
    (trade) => trade.buyAccountId === currentAccountId || trade.sellAccountId === currentAccountId,
  );
  for (const trade of userTrades) {
    const isBuyer = trade.buyAccountId === currentAccountId;
    const accountId = isBuyer ? trade.buyAccountId : trade.sellAccountId;
    const orderId = isBuyer ? trade.buyOrderId : trade.sellOrderId;
    const fee = isBuyer ? trade.buyFee : trade.sellFee;
    const liquidity = isBuyer ? (trade.buyerIsTaker ? 'Taker' : 'Maker') : trade.buyerIsTaker ? 'Maker' : 'Taker';
    const orderType = isBuyer ? trade.buyOrder?.type || '' : trade.sellOrder?.type || '';
    const stopType = isBuyer ? trade.buyOrder?.stopType || '' : trade.sellOrder?.stopType || '';
    const tradeSide = isBuyer ? 'BUY' : 'SELL';

    fills.push({
      id: trade.id,
      orderId,
      accountId,
      fee,
      quantity: trade.quantity,
      price: trade.price,
      liquidity,
      orderType,
      stopType,
      tradeSide,
      createdAt: trade.createdAt || '',
    });
    if (trade.buyAccountId === trade.sellAccountId) {
      fills.push({
        id: trade.id,
        orderId: trade.sellOrderId,
        accountId: trade.sellAccountId,
        fee: trade.sellFee,
        quantity: trade.quantity,
        price: trade.price,
        liquidity: trade.buyerIsTaker ? 'Maker' : 'Taker',
        orderType: trade.sellOrder?.type || '',
        stopType: trade.sellOrder?.stopType || '',
        tradeSide: 'SELL',
        createdAt: trade.createdAt || '',
      });
    }
  }

  store.dispatch(addTrades(fills.reverse()));
};
