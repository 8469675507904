import TextNumber from "src/components/cores/TextNumber";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";

export default function Margin({
  position,
  currentMargin,
}: {
  position: IPositionItem;
  currentMargin: string;
}) {
  return (
    <TextNumber
      defaultValue={Number(currentMargin).toFixed(2)}
      symbol={position?.asset}
      isCoinM={false}
    />
  );
}
