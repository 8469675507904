import { Popover } from "@material-ui/core";
import React from "react";
import { ArrowDownIcon } from "src/components/svg";
import { TIME_LIST, TIME_WEBVIEW } from "../../constant";
import * as S from "./style";
import useScreen from "src/hooks/useScreen";

interface TimeSelectorProps {
  time: string;
  setTime: (newTime: string) => void;
}

const TimeSelector: React.FC<TimeSelectorProps> = ({ time, setTime }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isMobile } = useScreen();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isTimeInWebView = TIME_WEBVIEW.some((item) => item.value === time);

  const renderTime = () => {
    if (!isMobile) {
      return (
        <S.Flex>
          <div>Time</div>
          {TIME_WEBVIEW.map((item) => {
            return (
              <div
                key={item.value}
                className={`time-item ${
                  item.value === time ? "time-active" : ""
                }`}
                onClick={() => setTime(item.value)}
              >
                {item.label}
              </div>
            );
          })}
          <div
            className={`time-item ${!isTimeInWebView ? "time-active" : ""}`}
            onClick={handleClick}
          >
            {!isTimeInWebView && (
              <div>
                {
                  TIME_LIST.find((timeOptional) => timeOptional.value === time)
                    ?.label
                }
              </div>
            )}
            <ArrowDownIcon />
          </div>
        </S.Flex>
      );
    } else {
      return (
        <S.Flex>
          <div>Time</div>
          <div className={`time-item time-active`} onClick={handleClick}>
            {
              TIME_LIST.find((timeOptional) => timeOptional.value === time)
                ?.label
            }
            <ArrowDownIcon />
          </div>
        </S.Flex>
      );
    }
  };

  return (
    <>
      {/* <S.Flex>
        <div>Time</div>
        {TIME_WEBVIEW.map((item) => {
          return (
            <div
              key={item.value}
              className={`time-item ${
                item.value === time ? "time-active" : ""
              }`}
              onClick={() => setTime(item.value)}
            >
              {item.label}
            </div>
          );
        })}
        <div
          className={`time-item ${!isTimeInWebView ? "time-active" : ""}`}
          onClick={handleClick}
        >
          {!isTimeInWebView && (
            <div>
              {
                TIME_LIST.find((timeOptional) => timeOptional.value === time)
                  ?.label
              }
            </div>
          )}
          <ArrowDownIcon />
        </div>
      </S.Flex> */}
      {renderTime()}

      <Popover
        id="time-selector"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <S.Container>
          <p className="select-time">Select time</p>
          <S.List>
            <S.Item>Time</S.Item>
            {TIME_LIST.map((item) => (
              <S.Item
                key={item.value}
                className={`${item.value === time ? "select-time-active" : ""}`}
                onClick={() => {
                  setTime(item.value);
                  handleClose();
                }}
              >
                {item.label}
              </S.Item>
            ))}
          </S.List>
        </S.Container>
      </Popover>
    </>
  );
};

export default TimeSelector;
