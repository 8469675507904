import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import classnames from "classnames/bind";
import useOnClickOutside from "src/hooks/useClickOutside";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import filterLight from "src/assets/icon/orderFuture/filterLight.png";
import filterDark from "src/assets/icon/orderFuture/filterDark.png";
import closeLight from "src/assets/icon/navbar/closeLight.png";
import closeDark from "src/assets/icon/navbar/closeDark.png";
import { AnyMxRecord } from "dns";
import * as S from "./style";

export type TOption = {
  id?: number;
  label: string;
  value?: string | number;
  icon?: string;
  link?: string;
  onAction?: () => void;
};

interface Props {
  title?: string;
  iconTitle?: string;
  children: any;
  onReset?: () => void;
  onSearch?: () => void;
}

const FilterMobile: React.FC<Props> = (props) => {
  const { title, iconTitle, children, onReset, onSearch } = props;
  const rfTitle = useRef<HTMLDivElement>(null);
  const rfUnderPanel = useRef<HTMLUListElement>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [openSearch, setOpenSearch] = useState(false);
  const el = useRef(null);

  useOnClickOutside(el, () => {
    if (openSearch) setOpenSearch(false);
  });

  return (
    <S.Container>
      <img
        src={
          iconTitle
            ? iconTitle
            : theme === THEME_MODE.LIGHT
            ? filterLight
            : filterDark
        }
        onClick={() => setOpenSearch(!openSearch)}
      />
      {openSearch && (
        <Dialog className="search-box" maxWidth={"md"} open={openSearch}>
          <div className="search-content" ref={el}>
            <div className="search-title">
              <div className="search-name">{title ? title : "Filter"}</div>
              <img
                src={theme === THEME_MODE.LIGHT ? closeLight : closeDark}
                onClick={() => setOpenSearch(!openSearch)}
              />
            </div>

            <div className="search-children">{children}</div>
            <div className="search-action">
              <div className="search-reset" onClick={onReset}>
                Reset
              </div>
              <div className="search-search" onClick={onSearch}>
                <div
                  className="search-text"
                  onClick={() => setOpenSearch(false)}
                >
                  Search
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </S.Container>
  );
};

export default FilterMobile;
