import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  HistoryCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
  ThemeName,
  TradingTerminalWidgetOptions,
  widget,
} from "src/charting_library/charting_library.min";
import eventBus from "src/event/event-bus";
import { getOrderbook } from "src/features/OrderbookTrade/redux/Orderbook.slice";
import { SYMBOL_TYPE } from "src/features/TradingViewChart/Constant";
import {
  addTradeToLastCandle,
  createEmptyCandleIfNeeded,
  getClientTimezone,
  getInterval,
  getIntervalString,
  round,
} from "src/features/TradingViewChart/helpers";
import { makeApiRequest } from "src/features/TradingViewChart/helpers/makeApiRequest";
import { Candle } from "src/features/TradingViewChart/interfaces";
import { useChart } from "src/hooks/useChart";
import i18n from "src/i18n";
import { THEME_MODE } from "src/interfaces/theme";
import { ITrade } from "src/interfaces/trade";
import { Instrument, setCurrentInstrument } from "src/services/instrument";
import { setTheme } from "src/services/theme";
import { BaseSocket } from "src/socket/BaseSocket";
import { SocketEvent } from "src/socket/SocketEvent";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

interface obj {
  [key: string]: boolean | number | string;
}

interface Props {
  containerId: string;
  valueInterval: string;
  libraryPath?: string;
  chartsStorageUrl?: string;
  chartsStorageApiVersion?: "1.0" | "1.1";
  clientId?: string;
  userId?: string;
  fullscreen?: boolean;
  autosize?: boolean;
  studiesOverrides?: obj;
  className?: string;
  setFullScreen?: (flag: boolean) => void;
  height?: number;
}

const configurationData = {
  supports_search: true,
  supports_marks: true,
  intraday_multipliers: [
    "1",
    "3",
    "5",
    "15",
    "30",
    "60",
    "120",
    "240",
    "360",
    "480",
    "720",
  ],
  supported_resolutions: [
    "1",
    "3",
    "5",
    "15",
    "30",
    "60",
    "120",
    "240",
    "360",
    "480",
    "720",
    "1D",
    "3D",
    "1W",
  ],
};

export const generalColor = {
  greenColor: "#01BC8D",
  redColor: "#F1493F",
  blueColor: "#3A7DFF",
  lightColor: "#FFF",
  darkColor: "#151717",
  textColor: "#9EA5B3",
  darkGridColor: "#2D303B",
  lightGridColor: "#D9DCE1",
};

const TradingWidget: React.FC<Props> = (props) => {
  const { setChart } = useChart();

  const listDisable = [
    "use_localstorage_for_settings",
    "context_menus",
    "display_market_status",
    "timeframes_toolbar",
    "header_compare",
    "header_undo_redo",
    "header_saveload",
    "header_screenshot",
    "header_interval_dialog_button",
    "volume_force_overlay",
    "control_bar",
    "border_around_the_chart",
    "header_widget",
    "header_symbol_search",
    "chart_property_page_scales",
  ];

  const theme = useAppSelector((state) => state.theme.themeMode);
  const mode = theme === THEME_MODE.LIGHT ? "light" : "dark";
  const tradingViewTheme = (mode.charAt(0).toUpperCase() +
    mode.slice(1)) as ThemeName;

  const libraryPath =
    window.location.protocol +
    "//" +
    window.location.host +
    "/charting_library/";

  // const minTick = { default: 'default', type: String };

  const instrument: Instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const interval = getInterval(props.valueInterval);

  const intervalInMillisecondsRef = useRef<number>(
    getInterval(props.valueInterval) * 60 * 1000
  );
  const lastCandleRef = useRef<Candle>({} as Candle);
  const chartRealtimeCallback = useRef<(candle: Candle) => void>(() => {});

  const dispatch = useAppDispatch();
  const params = useParams<{ theme: string; symbol: string; lang: string }>();

  const onReady = (callback: any) => {
    setTimeout(() => callback(configurationData));
  };

  useEffect(() => {
    if (location?.pathname.includes("webview") && instruments && params.theme) {
      const themeWebview = params.theme;
      const { symbol, lang } = params;
      const theme =
        themeWebview === "light" ? THEME_MODE.LIGHT : THEME_MODE.DARK;
      const instru = instruments?.find((e) => e.symbol === symbol);
      i18n.changeLanguage(lang);
      if (instru) {
        BaseSocket.getInstance().listenInstrumentEvents(instru);
        BaseSocket.getInstance().listen24Ticker();
        dispatch(setCurrentInstrument(instru));
        dispatch(getOrderbook(symbol));
      }
      dispatch(setTheme(theme));
    }
  }, [location?.pathname, instruments]);

  const getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    from: number,
    to: number,
    onResult: HistoryCallback
  ) => {
    const intervalInSeconds = getInterval(props.valueInterval) * 60;
    const startTime = round(from, intervalInSeconds) * 1000;
    const endTime = round(to, intervalInSeconds) * 1000;
    const symbol = params.symbol;
    try {
      const params = {
        from: startTime,
        to: endTime,
        resolution: props.valueInterval,
      };
      const candleUrl = `candle/${
        location?.pathname.includes("webview") ? symbol : instrument.symbol
      }/candles`;
      const data = await makeApiRequest(candleUrl, params);
      if (data?.length === 0) {
        onResult([], {
          noData: true,
        });
        return;
      } else {
        if (data[data?.length - 1].time > Date.now()) {
          data.pop();
        }
      }
      const bars: any = data.map((bar: any) => ({
        time: bar.time,
        close: bar.close,
        open: bar.open,
        high: bar.high,
        low: bar.low,
        volume: bar.volume,
      }));
      // lastCandleRef.current = bars[bars?.length - 1];
      lastCandleRef.current = bars.pop();
      onResult(bars, { noData: false });
    } catch (error) {
      onResult([], { noData: true });
    }
  };

  const resolveSymbol = async (
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback
  ) => {
    const symbol = instrument.symbol;
    // [bug] Price: Precision of 5 and MinMove: 0.00001 create error of unexpected base
    // https://github.com/tradingview/lightweight-charts/issues/55
    const tickerSize =
      Number(instrument.tickSize) === 0.00001
        ? 0.0001
        : Number(instrument.tickSize);

    const symbolInfo: LibrarySymbolInfo = {
      ticker: symbol,
      name: symbol,
      description: symbol,
      pricescale: 1 / tickerSize,
      volume_precision: -Math.ceil(
        Math.log10(Number(instrument.contractSize) * Number(instrument.lotSize))
      ),
      minmov: 1,
      exchange: "",
      full_name: "",
      listed_exchange: "",
      session: "24x7",
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: false,
      intraday_multipliers: configurationData.intraday_multipliers,
      timezone: getClientTimezone(),
      type: SYMBOL_TYPE.bitcoin,
      supported_resolutions: configurationData.supported_resolutions,
    };
    onSymbolResolvedCallback(symbolInfo);
  };

  const subscribeBars = (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback
  ) => {
    chartRealtimeCallback.current = onRealtimeCallback;
    eventBus.remove(SocketEvent.TradesCreated);
    eventBus.on(SocketEvent.TradesCreated, (trades: ITrade[]) => {
      const intervalInMilliseconds =
        getInterval(props.valueInterval) * 60 * 1000;
      trades.forEach((trade: any) => {
        lastCandleRef.current = addTradeToLastCandle(
          trade,
          lastCandleRef.current,
          intervalInMilliseconds,
          chartRealtimeCallback.current
        );
      });
    });
  };

  const datafeed: IBasicDataFeed = {
    onReady,
    searchSymbols: () => {},
    resolveSymbol,
    getBars,
    subscribeBars,
    unsubscribeBars: () => {},
  };

  useEffect(() => {
    const interval = window.setInterval(() => {
      const lastCandle = lastCandleRef.current;
      const intervalInMilliseconds =
        getInterval(props.valueInterval) * 60 * 1000;
      lastCandleRef.current = createEmptyCandleIfNeeded(
        lastCandle,
        intervalInMilliseconds,
        chartRealtimeCallback.current
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const language: any = i18n.language;

  useEffect(() => {
    const widgetOptions: TradingTerminalWidgetOptions = {
      interval: props.valueInterval,
      locale: language !== "id" ? language : "en",
      container_id: props.containerId,
      datafeed: datafeed,
      theme: tradingViewTheme,
      autosize: true,
      drawings_access: { type: "black", tools: [{ name: "Regression Trend" }] },
      disabled_features: listDisable,
      // indicators_file_name: '/js/indexes.js',
      custom_css_url:
        theme === THEME_MODE.LIGHT ? "tradingview.css" : "tradingview-dark.css",
      enabled_features: [
        "dont_show_boolean_study_arguments",
        "hide_last_na_study_output",
      ],
      // withdateranges: false,
      overrides: {
        "scalesProperties.scaleSeriesOnly": true,
        "paneProperties.topMargin": 15,
        "scalesProperties.showStudyLastValue": true,
        "paneProperties.background":
          theme === THEME_MODE.LIGHT
            ? generalColor.lightColor
            : generalColor.darkColor,
        "mainSeriesProperties.ma.upColor": generalColor.greenColor,
        "mainSeriesProperties.ba.upColor": generalColor.greenColor,
        "mainSeriesProperties.candleStyle.upColor": generalColor.greenColor,
        "mainSeriesProperties.candleStyle.downColor": generalColor.redColor,
        "mainSeriesProperties.candleStyle.drawWick": true,
        "mainSeriesProperties.candleStyle.drawBorder": true,
        "mainSeriesProperties.candleStyle.borderColor": generalColor.greenColor,
        "mainSeriesProperties.candleStyle.borderUpColor":
          generalColor.greenColor,
        "mainSeriesProperties.candleStyle.borderDownColor":
          generalColor.redColor,
        "mainSeriesProperties.candleStyle.wickUpColor": generalColor.greenColor,
        "mainSeriesProperties.candleStyle.wickDownColor": generalColor.redColor,
        "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
        "paneProperties.vertGridProperties.color":
          theme === THEME_MODE.LIGHT
            ? generalColor.lightGridColor
            : generalColor.darkGridColor,
        "paneProperties.horzGridProperties.color":
          theme === THEME_MODE.LIGHT
            ? generalColor.lightGridColor
            : generalColor.darkGridColor,
        "scalesProperties.lineColor":
          theme === THEME_MODE.LIGHT
            ? generalColor.lightGridColor
            : generalColor.darkGridColor,
        "scalesProperties.textColor": generalColor.textColor,
      },
      symbol: instrument.symbol,
      timezone: getClientTimezone(),
      library_path: libraryPath,
      charts_storage_api_version: props.chartsStorageApiVersion,
      charts_storage_url: props.chartsStorageUrl,
      user_id: props.userId,
      client_id: props.clientId,
      studies_overrides: {
        "volume.volume.color.0": generalColor.redColor,
        "volume.volume.color.1": generalColor.greenColor,
        "volume.volume ma.color": generalColor.redColor,
        "volume.volume ma.transparency": 0.5,
        "volume.volume ma.linewidth": 1,
        "volume.volume ma.plottype": "line",
        "volume.show ma": true,
      },
    };
    const chart = new widget(widgetOptions);

    chart.onChartReady(() => {
      chart.chart().setResolution(getIntervalString(interval), () => {});
      chart.applyOverrides({ "paneProperties.topMargin": 15 });
      chart
        .chart()
        .onIntervalChanged()
        .subscribe(null, function (interval) {
          intervalInMillisecondsRef.current = getInterval(interval) * 60 * 1000;
        });
      chart
        .chart()
        .createStudy("Moving Average", false, false, [20], () => {}, {
          "Plot.linewidth": 1.3,
          "Plot.color": "#0090eb",
        });
    });
    setChart(chart);
  }, [instrument.symbol, props.valueInterval, theme, i18n.language]);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          height: "100%",
        }}
        id={props.containerId}
      />
    </div>
  );
};

TradingWidget.defaultProps = {
  containerId: "trading-view-chart",
  libraryPath: "/charting_library/",
  chartsStorageApiVersion: "1.1",
  fullscreen: true,
  height: 500,
  autosize: true,
  studiesOverrides: {
    "volume.volume.color.0": "#F1493F",
    "volume.volume.color.1": "#01BC8D",
    "volume.volume.transparency": 0,
    "volume.volume ma.color": "#F1493F",
    "volume.volume ma.transparency": 0,
    "volume.volume ma.linewidth": 1,
    "volume.volume ma.plottype": "line",
    "volume.show ma": true,
  },
};
export default TradingWidget;
