
const CandleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3337 1.6665C13.7939 1.6665 14.167 2.0396 14.167 2.49984V4.99984H15.0003C15.4606 4.99984 15.8337 5.37294 15.8337 5.83317V14.1665C15.8337 14.6268 15.4606 14.9998 15.0003 14.9998H14.167V17.4998C14.167 17.9601 13.7939 18.3332 13.3337 18.3332C12.8734 18.3332 12.5003 17.9601 12.5003 17.4998V14.9998H11.667C11.2067 14.9998 10.8337 14.6268 10.8337 14.1665V5.83317C10.8337 5.37294 11.2067 4.99984 11.667 4.99984H12.5003V2.49984C12.5003 2.0396 12.8734 1.6665 13.3337 1.6665ZM6.66699 3.74984C6.20676 3.74984 5.83366 4.12294 5.83366 4.58317V7.08317H5.00033C4.54009 7.08317 4.16699 7.45627 4.16699 7.9165V12.0832C4.16699 12.5434 4.54009 12.9165 5.00033 12.9165H5.83366V15.4165C5.83366 15.8768 6.20676 16.2498 6.66699 16.2498C7.12723 16.2498 7.50033 15.8768 7.50033 15.4165V12.9165H8.33366C8.79391 12.9165 9.16699 12.5434 9.16699 12.0832V7.9165C9.16699 7.45627 8.79391 7.08317 8.33366 7.08317H7.50033V4.58317C7.50033 4.12294 7.12723 3.74984 6.66699 3.74984Z"
        fill="#8E8E92"
      />
    </svg>
  );
};

export default CandleIcon;
