import { TypeTrade } from 'src/constants/common.constants';
import routeConstants from 'src/routes/route.constants';

const DOMAIN_SPOT = process.env.REACT_APP_DOMAIN_SPOT;
const DOMAIN_FRESHDESK = process.env.REACT_APP_DOMAIN_FRESHDESK;

export const AboutUs = [
  {
    icon: '',
    label: 'about_us',
    href: `${DOMAIN_SPOT}/about`,
  },
  {
    icon: '',
    label: 'announcements',
    href: `${DOMAIN_FRESHDESK}/support/home`,
    isBlank: true,
  },
];
export const Produtcs = [
  {
    icon: "",
    label: "spot_trade",
    href: `${DOMAIN_SPOT + "/spot-exchange/basic"}`,
  },
  {
    icon: "",
    label: "convert",
    href: `${DOMAIN_SPOT + "/convert"}`,
  },
  {
    icon: "",
    label: "future_usdm",
    href: `${routeConstants.TRADING}/${TypeTrade.USD_M}`,
  },
  {
    icon: "",
    label: "future_coinm",
    href: `${routeConstants.TRADING}/${TypeTrade.COIN_M}`,
  },
];

export const Service = [
  {
    icon: "",
    label: "reward_center",
    href: `${DOMAIN_SPOT}/account/referral`,
  },
  {
    icon: "",
    label: "reward_program",
    href: "#",
  },
];

export const Support = [
  {
    icon: "",
    label: "faq",
    href: `${DOMAIN_FRESHDESK}/support/solutions`,
    isBlank: true,
  },
  {
    icon: "",
    label: "sup_center",
    href: `${DOMAIN_FRESHDESK}/support/tickets/new`,
    isBlank: true,
  },
  {
    icon: "",
    label: "withdrawal_fees",
    // eslint-disable-next-line max-len
    href: `${DOMAIN_FRESHDESK}/support/solutions/articles/151000028512-withdrawal-fees-on-monas`,
    isBlank: true,
  },
  {
    icon: "",
    label: "api_doc",
    href: "http://13.212.59.73:4000/api/documentation",
    isBlank: true,
  },
];

export const Legal = [
  {
    icon: "",
    label: "term_of_use",
    href: `${DOMAIN_SPOT}/terms`,
  },
  {
    icon: "",
    label: "privacy_policy",
    href: `${DOMAIN_SPOT}/policy`,
  },
];
export const Contact = [
  {
    icon: "",
    label: "term_of_use",
  },
];

export const footerLinks = [
  {
    label: 'about_us',
    items: [
      {
        icon: '',
        label: 'about_us',
        href: `${DOMAIN_SPOT}/about`,
      },
      {
        icon: '',
        label: 'announcements',
        href: `${DOMAIN_FRESHDESK}/support/home`,
        isBlank: true,
      },
    ],
  },
  {
    label: 'products',
    items: [
      {
        icon: '',
        label: 'spot_trade',
        href: `${DOMAIN_SPOT + '/spot-exchange/basic'}`,
      },
      {
        icon: '',
        label: 'convert',
        href: `${DOMAIN_SPOT + '/convert'}`,
      },
      {
        icon: '',
        label: 'future_usdm',
        href: `${routeConstants.TRADING}/${TypeTrade.USD_M}`,
      },
      {
        icon: '',
        label: 'future_coinm',
        href: `${routeConstants.TRADING}/${TypeTrade.COIN_M}`,
      },
      {
        icon: '',
        label: 'earn',
        href: '#',
      },
    ],
  },
  {
    label: 'services',
    items: [
      {
        icon: '',
        label: 'reward_center',
        href: `${DOMAIN_SPOT}/account/referral`,
      },
      {
        icon: '',
        label: 'reward_program',
        href: '#',
      },
    ],
  },
  {
    label: 'support',
    items: [
      {
        icon: '',
        label: 'faq',
        href: `${DOMAIN_FRESHDESK}/support/solutions`,
        isBlank: true,
      },
      {
        icon: '',
        label: 'sup_center',
        href: `${DOMAIN_FRESHDESK}/support/tickets/new`,
        isBlank: true,
      },
      {
        icon: '',
        label: 'withdrawal_fees',
        // eslint-disable-next-line max-len
        href: `${DOMAIN_FRESHDESK}/support/solutions/articles/151000028512-withdrawal-fees-on-monas`,
        isBlank: true,
      },
      {
        icon: '',
        label: 'api_doc',
        href: 'http://13.212.59.73:4000/api/documentation',
        isBlank: true,
      },
    ],
  },
  {
    label: 'legal',
    items: [
      {
        icon: '',
        label: 'term_of_use',
        href: `${DOMAIN_SPOT}/terms`,
      },
      {
        icon: '',
        label: 'privacy_policy',
        href: `${DOMAIN_SPOT}/policy`,
      },
    ],
  }
];
