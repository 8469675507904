import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { IFill } from "src/interfaces/fill";
import { IOrder } from "src/interfaces/order";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function Price({
  fill,
  tradingRulesCache,
  currentPrice,
}: {
  fill: IFill;
  tradingRulesCache: ITradingRuleCache[];
  currentPrice: string;
}) {
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === fill?.symbol
  );
  const maxFiguresForPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

  return (
    <TooltipNumber
      characters={11}
      decimal={maxFiguresForPrice}
      defaultValue={currentPrice}
    />
  );
}
