import moment from "moment";

export default function Time({ time }: { time?: string }) {
  if (!time) {
    return <span>--</span>;
  }
  return (
    <div className="container-render-time">
      <span>{moment(time).format("YYYY-MM-DD")}</span>
      <span>{moment(time).format("HH:mm:ss")}</span>
    </div>
  );
}
