import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "src/components/Loading";
import axiosInstance from "src/helpers/config";
import { IOrder } from "src/interfaces/order";
import { getAllOpenOrder } from "src/services/order";
import { useAppDispatch } from "src/store/hooks";

export default function ActionCancel({
  row,
  contractType,
  fetchData,
}: {
  row: IOrder;
  contractType: string;
  fetchData: (page: number) => void;
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const handleCancelOrder = async (orderSelectedToCancel: IOrder) => {
    setLoading(true);
    // await dispatch(cancelOrder(orderSelectedToCancel?.id));
    const res: any = await axiosInstance.delete(
      `/order/${orderSelectedToCancel?.id}`
    );
    if (res.code === 200) {
      fetchData(1);
      dispatch(getAllOpenOrder(contractType));
    }
    setLoading(false);
  };
  return (
    <td>
      <button
        className={"text-normal button-reverse"}
        style={{ width: 92, height: 24 }}
        onClick={(e) => {
          handleCancelOrder(row);
          e.stopPropagation();
        }}>
        {loading ? <Loading /> : <span>{t("order.cancel")}</span>}
      </button>
    </td>
  );
}
