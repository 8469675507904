import React from 'react';
import classNames from 'classnames/bind';
import styles from 'src/features/Market/components/TradingRule/TradingRule.module.scss';
import { useAppSelector } from 'src/store/hooks';
import { formatIntBalance, shortenString2 } from 'src/helpers/numberFormatter';
import TooltipText from 'src/components/cores/TooltipText';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

const cx = classNames.bind(styles);

export const LeverageMargin = (props: any) => {
  const { t } = useTranslation('common');
  const leverageMarginValue = useAppSelector((state) => state.general.leverageMargin);
  const { quoteCurrency, rootSymbol } = props.pairSelect;
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  return (
    <div className={cx('wrapper-leverage')}>
      {leverageMarginValue.map((item, index) => (
        <div key={index} className={cx('leverage-content')}>
          <div className={cx('tier-title')}>
            {t('tradingRule.tier')} {index + 1}
          </div>
          <div className={cx('leverage-form')}>
            <div className={cx('tooltip-position')}>
              <TooltipText text={t('tradingRule.position_bracket_tooltip')}>
                <div className={cx('leverage-label')}>
                  {t('tradingRule.position_bracket')} ({t('tradingRule.noti_value_quote')}{' '}
                  {isCoinM ? rootSymbol : quoteCurrency}) {t('orderBook.price')}
                </div>
              </TooltipText>
            </div>

            <div className={cx('leverage-value')}>
              {formatIntBalance(item.min)} - {formatIntBalance(item.max)}
            </div>
          </div>
          <div className={cx('leverage-form')}>
            <div className={cx('tooltip-position')}>
              <TooltipText text={t('tradingRule.max_leverage_tooltip')}>
                <div className={cx('leverage-label')}>{t('tradingRule.max_leverage')}</div>
              </TooltipText>
            </div>
            <div className={cx('leverage-value')}>{item.maxLeverage}x</div>
          </div>
          <div className={cx('leverage-form')}>
            <div className={cx('tooltip-position')}>
              <TooltipText text={t('tradingRule.margin_rate_tooltip')}>
                <div className={cx('leverage-label')}>{t('tradingRule.maintenance_margin_rate')}</div>
              </TooltipText>
            </div>
            <div className={cx('leverage-value')}>{shortenString2(String(item.maintenanceMarginRate), 4)}%</div>
          </div>
          <div className={cx('leverage-form')}>
            <div className={cx('tooltip-position')}>
              <TooltipText text={t('tradingRule.amount_tooltip')}>
                <div className={cx('leverage-label')}>
                  {t('tradingRule.maintenance_amount')} ({isCoinM ? rootSymbol : quoteCurrency})
                </div>
              </TooltipText>
            </div>
            <div className={cx('leverage-value')}>{formatIntBalance(item.maintenanceAmount)}</div>
          </div>

          <div className={cx('empty-trading')}></div>
        </div>
      ))}
    </div>
  );
};
