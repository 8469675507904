import { useTranslation } from "react-i18next";
import * as S from "../PositionHistory/style";
import ThHead from "../PositionHistory/thHead";
import { useState } from "react";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import DropdownMenu from "src/components/cores/Dropdown/Dropdown";
import { CANCEL_ACTION } from "src/features/PositionAndHistory/OpenOrder";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { IOrder } from "src/interfaces/order";
import RenderTime from "./RenderTime";
import { Instrument } from "src/services/instrument";
import {
  formatOrderText,
  getContractType,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import RenderTypeOrder from "./RenderTypeOrder";
import { OrderSide } from "src/features/PositionAndHistory/constant";
import Price from "./Price";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import RenderAmount from "./RenderAmount";
import FilledQuantity from "./FilledQuantity";
import RenderTriggerCondition from "./RenderTriggerCondition";
import RenderReduceOnly from "./RenderReduceOnly";
import axiosInstance from "src/helpers/config";
import { RECORDS_PER_PAGE } from "src/constants/common.constants";
import { getAllOpenOrder, getOpenOrder } from "src/services/order";
import ActionCancel from "./ActionCancel";
import { ViewOrderDialog } from "src/features/PositionAndHistory/dialog/ViewOrderDialog";

export default function TableOpenOrder({
  hideOtherPairs,
  rows,
  currentInstrument,
  loading,
  isCoinM,
  tradingRulesCache,
  instruments,
  handleChangeCancelType,
}: {
  hideOtherPairs: boolean;
  rows: IOrder[];
  currentInstrument: Instrument;
  loading: boolean;
  tradingRulesCache: ITradingRuleCache[];
  instruments: Instrument[];
  isCoinM: boolean;
  handleChangeCancelType: (value: any) => void;
}) {
  const dispatch = useAppDispatch();
  const contractType = getContractType();
  const [orderSelectedToView, setOrderSelectedToView] =
    useState<IOrder | null>();
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation("common");
  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const fetchData = async (page: number) => {
    const params = { page: page, size: RECORDS_PER_PAGE };
    if (hideOtherPairs) {
      const payload = { symbol: currentInstrument.symbol, contractType };
      dispatch(getOpenOrder({ params, payload }));
    } else {
      const payload = { contractType };
      dispatch(getOpenOrder({ params, payload }));
      dispatch(getAllOpenOrder(contractType));
    }
  };

  const handleViewOrder = (order: IOrder) => {
    let isShowBtnView = false;
    if (!order.parentOrderId) {
      if (order.isTpSlTriggered === false) {
        if (order.takeProfitOrderId || order.stopLossOrderId)
          isShowBtnView = true;
      }
    } else {
      isShowBtnView = true;
    }
    return isShowBtnView;
  };
  const handleClickDetail = (data: IOrder) => {
    setOrderSelectedToView(data);
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };
  return (
    <S.TableStyle>
      <table className={"dex_table text-table-xs"}>
        <thead>
          <tr className={"table-head"}>
            <ThHead text={t("order.time")} />
            <ThHead text={t("order.symbol")} />
            <ThHead text={t("order.type")} />
            <ThHead text={t("order.side")} />
            <ThHead text={t("order.price")} />
            <ThHead text={t("order.amount")} />
            <ThHead text={t("order.filled")} />
            <ThHead text={t("order.trigger_condition")} />
            <ThHead text={t("order.tpsl")} />
            <ThHead text={t("order.reduceOnly")} />
            <ThHead text={t("order.postOnly")} />
            <th
              style={{
                minWidth: "90px",
                width: "7%",
                paddingRight: "0px",
              }}>
              <div
                className={"type-cancel-order"}
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  setRefEml(event.currentTarget);
                }}>
                <span>{t("order.cancel_all")}</span>
                {/* {Boolean(refElm) ? (
                  <BsArrowDownCircle
                    fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                  />
                ) : (
                  <BsArrowUpCircle
                    fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                  />
                )} */}
              </div>
              <DropdownMenu
                open={Boolean(refElm)}
                options={CANCEL_ACTION}
                handleClose={() => setRefEml(null)}
                refElm={refElm}
                handleChooseOption={handleChangeCancelType}
              />
            </th>
          </tr>
        </thead>
        <tbody
          className={"dex_table__scrollbar overflow-tbody"}
          style={{ maxHeight: 210, verticalAlign: "top", marginTop: 16 }}>
          {loading && <div style={{ height: 230 }}></div>}
          {!loading && !rows.length && <div style={{ height: 230 }}></div>}
          {!loading && !!rows.length && (
            <>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className={"text-normal"}>
                    <RenderTime order={row} />
                  </td>
                  <td
                    className={"text-normal"}
                    onClick={() => {
                      if (currentInstrument.symbol === row.symbol) return;
                      window.location.replace(
                        `/futures/${row?.contractType}/${row.symbol}`
                      );
                    }}>
                    <div className={"text-normal"}>
                      {getSymbolName(row.symbol ?? "")}
                    </div>
                    <div className={"text-normal"}>{t("order.perpetual")}</div>
                  </td>
                  <td className={"text-normal"}>
                    <RenderTypeOrder order={row} />
                  </td>
                  <td
                    className={`${
                      row.side === OrderSide.buy ? "color-buy" : "color-sell"
                    }`}>
                    {t(formatOrderText(row.side))}
                  </td>
                  <td className={"text-normal"}>
                    <Price
                      position={row}
                      tradingRulesCache={tradingRulesCache}
                      currentPrice={row?.price ?? "0"}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <RenderAmount
                      order={row}
                      instruments={instruments}
                      tradingRulesCache={tradingRulesCache}
                      isCoinM={isCoinM}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <FilledQuantity
                      order={row}
                      instruments={instruments}
                      tradingRulesCache={tradingRulesCache}
                      isCoinM={isCoinM}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <RenderTriggerCondition
                      order={row}
                      instruments={instruments}
                    />
                  </td>
                  <td className="text-normal">
                    {handleViewOrder(row) ? (
                      <span
                        className={"btn-view"}
                        onClick={(e) => {
                          handleClickDetail(row);
                          e.stopPropagation();
                        }}>
                        {t("order.view")}
                      </span>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td className="text-normal">
                    <RenderReduceOnly order={row} />
                  </td>
                  <td className={"text-normal"}>
                    {row?.isPostOnly ? t("order.yes") : t("order.no")}
                  </td>
                  <ActionCancel
                    row={row}
                    fetchData={fetchData}
                    contractType={contractType}
                  />
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {orderSelectedToView && (
        <ViewOrderDialog
          data={orderSelectedToView}
          handleCloseDialog={handleCloseViewDialog}
          openDialog={openViewDialog}
        />
      )}
    </S.TableStyle>
  );
}
