import * as S from "./style";
import ItemMobile from "./ItemMobile";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useCallback, useEffect, useState } from "react";
import { ITransactionHistory } from ".";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { getTransactionHistory } from "src/services/transaction-history";
import Loading from "src/components/Loading";
export default function MobileTransactionHistory() {
  const dispatch = useAppDispatch();

  const { loading, transactionHistory } = useAppSelector(
    (state) => state.transactionHistory
  );
  const account = useAppSelector((state) => state.account.account);
  const contractType = getContractType();
  const [rows, setRows] = useState<ITransactionHistory[]>([]);
  const handleFormatTime = useCallback(() => {
    const endTime = new Date();
    const startTime = new Date();
    startTime.setMonth(startTime.getMonth() - 3);
    return {
      startTime: startTime.getTime(),
      endTime: endTime.getTime(),
    };
  }, []);
  const fetchData = async (page: number) => {
    const { startTime, endTime } = handleFormatTime();

    const payload = Object.assign(
      { page: page, size: 20, contractType },
      { startTime, endTime }
    );

    await dispatch(getTransactionHistory(payload));
  };
  useEffect(() => {
    if (transactionHistory) {
      setRows(transactionHistory.data.list);
    }
  }, [transactionHistory]);

  useEffect(() => {
    fetchData(1);
  }, [account[0]?.id]);
  return (
    <S.MobileStyle>
      <div className="container-des">
        <span className="text-des">Only the last 20 displayed</span>
      </div>
      <div className="container-list">
        {loading ? (
          <div
            style={{
              width: "100%",
              height: 30,
              display: "flex",
              justifyContent: "center",
            }}>
            <Loading />
          </div>
        ) : (
          rows.map((item, index) => (
            <ItemMobile key={`${item.id}-${index}`} transaction={item} />
          ))
        )}
      </div>
    </S.MobileStyle>
  );
}
