import { ClickAwayListener, Tooltip, Zoom } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from '../Tooltip';
import { useWindowSize } from 'src/hooks/useScreen';

interface Props {
  text: any;
  children?: any;
  className?: string;
}

const TooltipText: React.FC<Props> = ({ text, children, className }) => {
  const classes = useStyles();
  const size = useWindowSize();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {size[0] <= 970 ? (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Tooltip
            className={className}
            classes={{
              tooltip: classes.customTooltip,
              touch: classes.popper,
            }}
            TransitionComponent={Zoom}
            placement="bottom"
            title={text}
            open={isOpen}
          >
            <div onClick={() => setIsOpen(!isOpen)}>{children}</div>
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip
          // arrow={true}
          className={className}
          classes={{
            tooltip: classes.customTooltip,
            touch: classes.popper,
          }}
          TransitionComponent={Zoom}
          placement="bottom"
          title={text}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <div>{children}</div>
        </Tooltip>
      )}
    </>
  );
};

export default TooltipText;
