import TextNumber from "src/components/cores/TextNumber";
import { TradeSide } from "src/constants/common.constants";
import { IFill } from "src/interfaces/fill";
import { Instrument } from "src/services/instrument";

export default function RealizedProfit({
  instruments,
  fill,
  isCoinM,
  customText,
}: {
  instruments: Instrument[];
  fill: IFill;
  isCoinM: boolean;
  customText?: string;
}) {
  const currentInstrument = instruments?.find(
    (instrument) => instrument?.symbol === fill?.symbol
  );

  if (fill?.tradeSide === TradeSide.BUY) {
    if (Number(fill?.realizedPnlOrderBuy)) {
      // return (
      //   <TooltipNumber
      //     defaultValue={fill.realizedPnlOrderBuy}
      //     characters={20}
      //     decimal={8}
      //     symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
      //   />
      // );

      return (
        <TextNumber
          defaultValue={fill.realizedPnlOrderBuy ?? "0"}
          symbol={
            isCoinM
              ? currentInstrument?.rootSymbol
              : currentInstrument?.quoteCurrency
          }
          isCoinM={false}
        />
      );
    } else {
      return <span className={customText}>--</span>;
    }
  } else {
    if (Number(fill?.realizedPnlOrderSell)) {
      // return (
      //   <TooltipNumber
      //     defaultValue={fill.realizedPnlOrderSell}
      //     characters={20}
      //     decimal={8}
      //     symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
      //   />
      // );

      return (
        <TextNumber
          defaultValue={fill.realizedPnlOrderSell ?? "0"}
          symbol={
            isCoinM
              ? currentInstrument?.rootSymbol
              : currentInstrument?.quoteCurrency
          }
          isCoinM={false}
        />
      );
    } else {
      return <span className={customText}>--</span>;
    }
  }
}
