import React, { useEffect, useState } from "react";
import {
  DesktopDateRangePicker,
  DateRange,
  LocalizationProvider,
} from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import classnames from "classnames/bind";
import styles from "./DatePicker.module.scss";
import { makeStyles } from "@material-ui/core";
// import { pickerIcon, calendarDark } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import useIsMobile from "src/hooks/useIsMobile";
import { MOBILE_DIMENSION } from "src/constants/common.constants";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { vi, ko, enUS } from "date-fns/locale";
import i18n from "src/i18n";
import { BsCalendar2Date } from "react-icons/bs";

interface IDatePicker {
  handleChangeDate: (date: DateRange<Date>) => void;
  handleOpenDate?: () => void;
  handleCloseDate?: () => void;
  customInput?: any;
  isChangeSelectDatePicker?: boolean;
}

export const handleLanguage = () => {
  return i18n.language === "vi" ? vi : i18n.language === "ko" ? ko : enUS;
};
const weekDays = new DateFnsAdapter({ locale: handleLanguage() }).getWeekdays();
const constructDayObject = (day: string): any => ({
  charAt: function () {
    return { toUpperCase: () => day };
  },
});
let customWeekDays = weekDays.map((day) => constructDayObject(day));

export const updateWeekDays = () => {
  const newWeekDays = new DateFnsAdapter({
    locale: handleLanguage(),
  }).getWeekdays();
  customWeekDays = newWeekDays.map((day) => constructDayObject(day));
};

export class DateAdapter extends DateFnsAdapter {
  getWeekdays = (): string[] => customWeekDays;
}

const cx = classnames.bind(styles);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    backgroundColor: "var(--color-date-modal)",
    borderRadius: "8px",
    "& .MuiPickersDesktopDateRangeCalendar-calendar": {
      minHeight: "250px",
      minWidth: "310px",
      overflowY: "hidden",
    },
    "& .MuiPickersDay-root.Mui-selected": {
      backgroundColor: "var(--color-primary-1)",
      color: "black",
    },
    "& .MuiPickersDateRangeDay-rangeIntervalDayHighlight": {
      backgroundColor: "var(--color-date-range)",
      color: "white !important",
    },
    "& .MuiPickersDateRangeDay-notSelectedDate": {
      color: "var(--color-not-selected-date)",
    },
    "& .MuiTypography-subtitle1": {
      color: "var(--color-date-value)",
      fontFamily: "inter-regular",
    },
    "& .MuiPickersCalendar-weekDayLabel": {
      width: "37px",
      fontSize: "16px",
      color: "var(--color-week-day-label)",
      fontFamily: "inter-regular",
      "@media only screen and (max-width: 768px)": {
        fontSize: "14px",
      },
    },
    "& .MuiPickersArrowSwitcher-iconButton": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      fill: "var(--color-date-icon)",
    },
    "& .MuiPickersDateRangeDay-day": {
      fontSize: "16px",
      color: "var(--color-date-range-day)",
      fontFamily: "inter-regular",
      "@media only screen and (max-width: 768px)": {
        fontSize: "14px",
      },
    },
    "& .Mui-disabled": {
      color: "var(--color-disable-date)",
    },
    "& .MuiPickersDay-dayOutsideMonth": {
      border: "none !important",
      color: "var(--color-disable-date) !important",
      backgroundColor: "transparent !important",
    },
    "& .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child)":
      {
        borderRight: "none",
        marginRight: "20px",
      },
  },
  paper: {
    "& .MuiPaper-elevation8": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
}));

const DatePicker = (props: IDatePicker) => {
  const {
    handleChangeDate,
    handleOpenDate,
    handleCloseDate,
    customInput,
    isChangeSelectDatePicker,
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation("common");

  useEffect(() => {
    handleChangeDate(selectedDate);
  }, [handleChangeDate, selectedDate]);

  useEffect(() => {
    if (!isChangeSelectDatePicker) {
      setSelectedDate([null, null]);
    }
  }, [isChangeSelectDatePicker]);

  const handleOnchangeDate = (date: any) => {
    setSelectedDate(date);
  };

  return (
    <LocalizationProvider
      dateAdapter={DateAdapter}
      dateFormats={{ monthAndYear: "MMM yyyy" }}
      locale={handleLanguage()}>
      <DesktopDateRangePicker
        calendars={isMobile ? 1 : 2}
        className={classes.root}
        PopperProps={{
          placement: "bottom-start",
          className: classes.paper,
        }}
        value={selectedDate}
        maxDate={new Date()}
        minDate={
          new Date(
            moment().subtract(3, "months").startOf("day").toDate().getTime()
          )
        }
        showDaysOutsideCurrentMonth={true}
        disableHighlightToday={true}
        onChange={(date) => handleOnchangeDate(date)}
        onOpen={handleOpenDate}
        onClose={() => {
          handleCloseDate && handleCloseDate();
          setIsOpen(false);
        }}
        open={isOpen}
        readOnly={true}
        inputFormat="yyyy-MM-dd"
        mask="____-__-__"
        renderInput={(startProps, endProps) => (
          <div className={cx("wrapper-input", customInput)}>
            <div className={cx("calendar-icon")}>
              <input
                className={cx("input-ghost")}
                onClick={() => setIsOpen(!isOpen)}
                readOnly={true}
              />
              <div>
                <BsCalendar2Date
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                className={cx("input-time")}
                style={{ textAlign: "center" }}
                ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                {...startProps.inputProps}
                onClick={() => setIsOpen(!isOpen)}
              />
              <span style={{ margin: "0 5px", fontSize: 12 }}>-</span>
              <input
                className={cx("input-time")}
                ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                style={{ textAlign: "center" }}
                {...endProps.inputProps}
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
