import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstanceSpot from 'src/helpers/configAPISpot';
import { setLocalStorage } from 'src/helpers/storage';

export interface IMasterdata {
  coins: [];
  social_networks: [];
}

const initialState: { loading: boolean; error: boolean; masterdata: IMasterdata } = {
  loading: false,
  error: false,
  masterdata: { coins: [], social_networks: [] },
};

export const getMasterData = createAsyncThunk('masterdata', async () => {
  const res = await axiosInstanceSpot.get('/masterdata');
  return res;
});

export const masterDataSlice = createSlice({
  name: 'masterdata',
  initialState,
  reducers: {},
  extraReducers: {
    [getMasterData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getMasterData.rejected.toString()]: (state, action: any) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getMasterData.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.masterdata = action.payload.data || [];
      setLocalStorage('masterdata', action.payload.data);
    },
  },
});

const { reducer: masterdataReducer } = masterDataSlice;

export default masterdataReducer;
