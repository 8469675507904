import axiosInstance from "src/helpers/config";

export interface MakeApiRequest {
  from: number;
  to: number;
  resolution: string;
}

export async function makeApiRequest(
  path: string,
  params: MakeApiRequest
): Promise<any> {
  // console.log("params>>>>", params);
  try {
    const response = await axiosInstance.get(`${path}`, { params });
    return response.data;
  } catch (e) {
    return e;
  }
}
