import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { Instrument } from "src/services/instrument";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function Quantity({
  position,
  tradingRulesCache,
  instruments,
  isCoinM,
}: {
  position: IPositionItem;
  tradingRulesCache: ITradingRuleCache[];
  instruments: Instrument[];
  isCoinM: boolean;
}) {
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === position?.symbol
  );
  const maxFiguresForSize =
    Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === position.symbol
  );
  return (
    <>
      <TooltipNumber
        defaultValue={Math.abs(Number(position?.currentQty))}
        characters={7}
        decimal={isCoinM ? 0 : maxFiguresForSize}
        symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
      />
    </>
  );
}
