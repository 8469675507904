import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { IOrder } from "src/interfaces/order";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function Price({
  position,
  tradingRulesCache,
  currentPrice,
  customText,
}: {
  position: IOrder;
  tradingRulesCache: ITradingRuleCache[];
  currentPrice: string;
  customText?: string;
}) {
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === position?.symbol
  );
  const maxFiguresForPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

  return (
    <TooltipNumber
      characters={11}
      decimal={maxFiguresForPrice}
      defaultValue={currentPrice}
      customText={customText}
    />
  );
}
